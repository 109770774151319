import { BehaviorSubject } from 'rxjs';

const isBundleOfferSnackBarOpenSubject = new BehaviorSubject(false);

export const BundleOfferSnackBarOpen = {
  update: value => {
    isBundleOfferSnackBarOpenSubject.next(value);
  },
  subscribe: setState => isBundleOfferSnackBarOpenSubject.subscribe(setState),
  getValue: () => isBundleOfferSnackBarOpenSubject.value,
};

export default { BundleOfferSnackBarOpen };
