const CUSTOM_STYLES = {
  overlay: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    padding: 0,
    right: 'unset',
    bottom: 'unset',
    maxHeight: '100%',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    borderRadius: '1rem',
  },
};

export default CUSTOM_STYLES;
