import axios from 'axios';

const CMS_API_URL = process.env.NEXT_PUBLIC_CMS_API_URL;
const CMS_API_CHANNEL = process.env.NEXT_PUBLIC_CMS_CHANNEL;

const cms = axios.create({
  baseURL: CMS_API_URL,
  headers: {
    'Lucent-Channel': CMS_API_CHANNEL,
    // Authorization: process.env.NEXT_PUBLIC_CMS_TOKEN,
    Authorization: `Bearer ${process.env.NEXT_PUBLIC_CMS_TOKEN}`,

    'Accept-Language': 'el-GR',
    // 'content-type': 'application/json',
  },
});
export const getCMSDataCsv = async URL => {
  try {
    const response = await axios.get(URL, {});
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching CMS data:', error);
    throw error; // Throw the error to handle it in the calling code
  }
};

cms.interceptors.request.use(config => {
  return {
    ...config,
    params: {
      ...config.params,
    },
  };
});

// Add a response interceptor
cms.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default cms;
