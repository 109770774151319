import { BehaviorSubject } from 'rxjs';

const isListLoginModalOpen = new BehaviorSubject(false);
const isOrderLoginModalOpen = new BehaviorSubject(false);

export const ListLoginModalStore = {
  update: value => {
    isListLoginModalOpen.next(value);
  },
  subscribe: setState => isListLoginModalOpen.subscribe(setState),
  getValue: () => isListLoginModalOpen.value,
  open: () => {
    isListLoginModalOpen.next(true);
  },
  close: () => {
    isListLoginModalOpen.next(false);
  },
};

export const OrderLoginModalStore = {
  update: value => {
    isOrderLoginModalOpen.next(value);
  },
  subscribe: setState => isOrderLoginModalOpen.subscribe(setState),
  getValue: () => isOrderLoginModalOpen.value,
  open: () => {
    isOrderLoginModalOpen.next(true);
  },
  close: () => {
    isOrderLoginModalOpen.next(false);
  },
};
