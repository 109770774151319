import { DEFAULT_LOCALE } from '@/constants/locale.js';
import axios from 'axios';
import get from 'lodash/get';

import { wave } from './config';

export async function getProductsWithDetails() {
  try {
    const response = await wave.get('/v2/products', {
      params: {
        collection_eq: 'static-web',
      },
    });

    const products = get(response, 'data.payload.products');
    if (!products) {
      // TODO: handle the error - showing a modal
      console.error(response);
      return {
        products: [],
        error: { userMessage: 'Σφάλμα ανάκτησης δεδομένων' },
      };
    }

    return { products };
  } catch (error) {
    return { products: [], error };
  }
}

export async function getAvailableProducts(url, params) {
  try {
    const response = await axios.get(url, { params, headers: { appId: process.env.appId } });
    return { products: response?.data?.payload?.products };
  } catch (error) {
    return { products: [], error };
  }
}

//This is exactly the same as getV31Products - consider replacing/removing
export async function getV31AvailableProducts(
  products,
  collectionTypes = [process.env.defaultCollectionType],
  locale = DEFAULT_LOCALE
) {
  try {
    const response = await wave.get('/v3.1/products', {
      params: {
        available: true,
        collectionTypes: collectionTypes,
        pageNumber: 0,
        pageSize: 100,
        categorySearchType: 'ALL_LEVELS',
        skus: products,
      },
      headers: { 'Accept-Language': locale },
    });

    return response.data;
  } catch (error) {
    return { error };
  }
}

/*
Pass parameters to filter tags=[]&attributes=[]&brands=[]
 */
export async function getV31Products(resource, locale = DEFAULT_LOCALE, privateApiKey) {
  const response = await wave.get('/v3.1/products', {
    headers: {
      'x-api-key': privateApiKey || undefined,
      'Accept-Language': locale,
    },
    params: {
      available: true,
      collectionTypes: [process.env.defaultCollectionType],
      pageNumber: 0,
      pageSize: 15,
      categorySearchType: 'ALL_LEVELS',
      ...resource,
    },
  });

  return response.data;
}

export async function getProductInfoV3(productId, locale = DEFAULT_LOCALE, privateApiKey) {
  const { data } = await wave.get(`/v3/products/${productId}`, {
    headers: {
      'x-api-key': privateApiKey || undefined,
      'Accept-Language': locale,
    },
  });
  return data;
}

export async function getProductDetailsV3(productId, locale = DEFAULT_LOCALE, privateApiKey) {
  const { data } = await wave.get(`/v3/products/${productId}/details`, {
    headers: {
      'x-api-key': privateApiKey || undefined,
      'Accept-Language': locale,
    },
  });
  return data;
}

export async function getProductTags(
  categoryId,
  categorySearchType = 'ALL_LEVELS',
  locale = DEFAULT_LOCALE,
  privateApiKey,
  offerFiltering
) {
  const response = await wave.get('/v3/products/tags', {
    headers: {
      'x-api-key': privateApiKey || undefined,
      'Accept-Language': locale,
    },
    params: { categoryId, categorySearchType, ...(offerFiltering && { [offerFiltering]: true }) },
  });

  return response.data;
}

export async function getProductBrands(
  categoryId,
  categorySearchType = 'ALL_LEVELS',
  locale = DEFAULT_LOCALE,
  privateApiKey,
  offerFiltering
) {
  const response = await wave.get('/v3/products/brands', {
    headers: {
      'x-api-key': privateApiKey || undefined,
      'Accept-Language': locale,
    },
    params: {
      categoryId,
      categorySearchType,
      ...(offerFiltering && { [offerFiltering]: true }),
    },
  });

  return response.data;
}

export async function getProductPriceHistory(productId, dateFrom, dateTo) {
  return await wave.get(`/v3/products/${productId}/date-prices`, { params: { dateFrom, dateTo } });
}

export async function getProductAttributes(
  categoryId,
  categorySearchType = 'ALL_LEVELS',
  locale = DEFAULT_LOCALE,
  privateApiKey,
  offerFiltering
) {
  const response = await wave.get('/v3/products/attributes', {
    headers: {
      'x-api-key': privateApiKey || undefined,
      'Accept-Language': locale,
    },
    params: {
      categoryId,
      categorySearchType,
      ...(offerFiltering && { [offerFiltering]: true }),
    },
  });

  return response.data;
}

export async function getProductCategoryKinds(
  categoryId,
  categorySearchType = 'ALL_LEVELS',
  locale = DEFAULT_LOCALE,
  privateApiKey,
  offerFiltering
) {
  const response = await wave.get('/v3/products/category-kinds', {
    headers: {
      'x-api-key': privateApiKey || undefined,
      'Accept-Language': locale,
    },
    params: {
      categoryId,
      categorySearchType,
      ...(offerFiltering && { [offerFiltering]: true }),
    },
  });

  return response.data;
}
