import { BehaviorSubject } from 'rxjs';

const listSelectedProductSubject = new BehaviorSubject({});

export const ListSelectedProductStore = {
  update: value => {
    listSelectedProductSubject.next(value);
  },
  subscribe: setState => listSelectedProductSubject.subscribe(setState),
  getValue: () => listSelectedProductSubject.value,
};

export default { ListSelectedProductStore };
