import { useRouter } from 'next/router';
import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar';
import { useNProgressConfig } from '@/hooks/useNProgressConfig';
import Script from 'next/script';
import { cartPrice, isEditStarted, productsForOrder, productsQuantities, productsToKeep } from '../store/cart';
import jsonStorage from '../utils/json-storage';
import { Loader } from '@/components/Loader/Loader';
import { useEffect, useState, useContext } from 'react';
import { ModalProvider } from '@/contexts/ModalProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NextIntlClientProvider } from 'next-intl';
import { initGlobalValues as setupContactPigeon } from '../utils/contactPigeon';
import TagManager from 'react-gtm-module';
import Cookie from '../store/cookies';
import { defaultConsent, updateConsent } from '../utils/analytics';
import { ToastProvider } from '@/contexts/ToastContext';
import Facebook from '@/tracking/Facebook';
import 'sdk-css';
import '@/styles/globals.css';
import '@/styles/css/foundations.stylingVars.css';
import { DistanceProvider } from '@/contexts/DistanceUomContext';
import { CurrencyProvider } from '@/contexts/CurrencyProvider';
import { DateFormattingProvider } from '@/contexts/DateFormattingProvider';
import { setDayJsLocale } from '@/utils/dates/dates';
import { useStoreConfiguration, WaveCxmContextProvider } from '@desquared/wave-cxm-react';
import Cookies from 'universal-cookie';
import User from '@/store/user';
import { configureApi } from '@/api/wave/questionnaire';
import QuestionnaireSuccess from '../../public/assets/wave-grocery/QuestionnaireSuccess.png';
import { Image, ImageSizeEnum } from '@desquared/wave-components-library';

import { ConfigurationProvider } from '@/contexts/ConfigContext';
import { IngredientsDecisionModal } from '@/components/IngredientsDecisionModal/IngredientsDecisionModal';
import { useSessionId } from '@/hooks/useSessionId';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // After how long to refetch data
      staleTime: 15 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

export default function App({ Component, pageProps }) {
  const router = useRouter();
  const { config, progressColor, height } = useNProgressConfig();
  const [hasPixel, setHasPixel] = useState(false);
  const [acceptedAd, setAcceptedAd] = useState(false);
  const { updateCustomerId } = useStoreConfiguration();
  const headers = process.env.NEXT_PUBLIC_APPID.split('-');

  const [isHydrated, setIsHydrated] = useState(false);

  useEffect(() => {
    setIsHydrated(true);
  }, []);

  useEffect(() => {
    if (!isHydrated) return;
    const sub = User.subscribe(user => {
      if (user) {
        const cookies = new Cookies();
        const authorization = cookies.get('token');
        configureApi(headers, authorization, router.locale);
        updateCustomerId(user._id);
      }
    });

    return () => {
      sub.unsubscribe();
    };
  }, [isHydrated, router.locale]);

  const sessionId = useSessionId();

  useEffect(() => {
    const cookies$ = Cookie.subscribe(accepted => {
      if (!accepted) return;
      const { ad, performance } = accepted;
      setAcceptedAd(ad);
      if (!!process.env.hasAnalytics && !!process.env.gtmKey) {
        TagManager.initialize({ gtmId: process.env.gtmKey });
      }
      defaultConsent();

      updateConsent(!!performance, !!ad);

      if (!ad) {
        Cookie.deleteAd();
      }
      if (!performance) Cookie.deletePerformance();
    });

    return () => {
      cookies$.unsubscribe();
    };
  }, []);

  useEffect(() => {
    // process.env.cpaAccount ? setupContactPigeon(process.env.cpaAccount) : null;
    process.env.hasFacebookPixel && process.env.facebookPixelId.length > 0 ? setHasPixel(true) : setHasPixel(false);
    if (typeof window !== 'undefined') {
      const storage = window.localStorage;
      if (!jsonStorage.get('productForOrder', { storage })) {
        productsQuantities.update({});
      }
    }
  }, []);

  useEffect(() => {
    setDayJsLocale(router.locale);
  }, [router.locale]);

  useEffect(() => {
    if (isEditStarted.getValue()) {
      productsToKeep.release();
      isEditStarted.update(false);
    }

    if (cartPrice.getValue() !== 0) return;

    const totalPrice = productsForOrder.getValue().reduce((total, obj) => {
      if (obj.hasOwnProperty('price')) {
        const price = parseFloat(obj.price);
        if (!isNaN(price)) {
          total += price;
        }
      } else if (obj.hasOwnProperty('finalPrice')) {
        const price = parseFloat(obj.finalPrice);
        if (!isNaN(price)) {
          total += price / 100;
        }
      }
      return total;
    }, 0);
    cartPrice.update(totalPrice.toFixed(2));
  }, [router]);

  const getLayout = Component.getLayout || (page => page);

  return (
    <>
      <NextIntlClientProvider
        locale={router.locale}
        // TODO: Change this depending on user timezone
        timeZone="Europe/Athens"
        messages={pageProps.messages}
      >
        <WaveCxmContextProvider
          language={router.locale}
          theme={{
            questionnaire: {
              contactWithUsIcon: (
                <Image
                  size={ImageSizeEnum.WaveNewsLetter}
                  imgSrc={QuestionnaireSuccess.src}
                  imgTitle={'Questionnaire-Success'}
                  alt={'Questionnaire-Success'}
                  style={{ marginBottom: '4rem' }}
                />
              ),
              rateUsIcon: (
                <Image
                  size={ImageSizeEnum.WaveNewsLetter}
                  imgSrc={QuestionnaireSuccess.src}
                  imgTitle={'Questionnaire-Success'}
                  alt={'Questionnaire-Success'}
                  style={{ marginBottom: '4rem' }}
                />
              ),
            },
          }}
        >
          <ToastProvider>
            <QueryClientProvider client={queryClient}>
              <Loader />

              <ModalProvider>
                <ConfigurationProvider>
                  <CurrencyProvider>
                    <DateFormattingProvider>
                      <DistanceProvider>{getLayout(<Component {...pageProps} />)}</DistanceProvider>
                    </DateFormattingProvider>
                    <IngredientsDecisionModal />
                  </CurrencyProvider>
                </ConfigurationProvider>
              </ModalProvider>
            </QueryClientProvider>
          </ToastProvider>
        </WaveCxmContextProvider>
      </NextIntlClientProvider>
      {hasPixel && acceptedAd && <Facebook />}
      {process.env.hasContactPigeon && acceptedAd && (
        <>
          <Script
            id="cpa-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
            var cpaAccount = "${process.env.cpaAccount}";
            var cpaE = ''; // dynamic field
            var cpa_async = {
              ctag: '',
              utmipn: '',
              utmipc: '',
              utmtid: '',
              utmtto: '',
              cutmcn: '',
              cutmcc: ''
            };
          `,
            }}
          />
          <Script
            type="text/javascript"
            strategy="afterInteractive"
            // src="//ping.contactpigeon.com/bi/js/cpagent142645.js?cid=thanopoulos&v=001"
            src={`//ping.contactpigeon.com/bi/js/${process.env.cpagent}.js?cid=${process.env.contactid}`}
          />
        </>
      )}
      <ProgressBar height={height} color={progressColor} options={config} shallowRouting />
    </>
  );
}
