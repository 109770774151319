// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"269aea77a9dd9e1eeeeaf60fb1bf2db8caee9f46"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from '@sentry/nextjs';
import { config } from 'prebuild-data/config.js';

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,

  environment: `${config.pathNameTenant}-${config.pathNameEnv}`,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.05,
  sampleRate: 0.2,
  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  replaysOnErrorSampleRate: 0.1,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  beforeSend(event) {
    const partialMatch = 'Suspense';
    const singularPartialMatch = 'suspense';
    // Check if the event's error matches the one to ignore
    if (
      event.exception &&
      event.exception.values &&
      event.exception.values.some(
        exception =>
          exception.type === 'Error' &&
          exception.value?.includes('This Suspense boundary received an update before it finished hydrating.')
      )
    ) {
      // Return null to drop the event
      return null;
    }
    if (
      event.message &&
      (event.message.includes(partialMatch) ||
        event.message.includes(singularPartialMatch) ||
        event.message.match(/Suspense/i))
    ) {
      return null;
    }
    return event;
  },

  ignoreErrors: [
    'Error: Request aborted',
    'Error: Worker was terminated',
    /.*ServiceWorker.*/,
    /.*UnknownError.*/,
    /.*NetworkError.*/,
    /.*Network Error.*/,
    /.*QuotaExceededError.*/,
    /.*Hydration Error.*/,
    'Rejected',
    'Hydration Error',
  ],

  release: process.env.NEXT_PUBLIC_APP_VERSION || undefined,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    Sentry.replayIntegration({
      // This is for not showing sensitive data in session replays
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
});
