import { getV3Configuration } from '../api/configuration';
import User from '@/store/user';
import ConfigurationStore from '../store/configuration';

export function getOrFetchConfiguration() {
  if (ConfigurationStore.isFetched()) {
    return ConfigurationStore.getValue();
  }
  return updateConfiguration();
}

export async function updateConfiguration() {
  const config = await getV3Configuration();
  ConfigurationStore.update(config);
  return config;
}

export function createPaymentMethods(config) {
  const methods = config?.order?.payment?.methods.map(item => {
    const { title, enabled, extraFeeDescription, ...rest } = item;
    return { ...rest, name: title || '', unavailable: !enabled, subTitle: extraFeeDescription };
  });
  const sortedPaymentMethods = methods.sort((a, b) => b.type.localeCompare(a.type));

  return sortedPaymentMethods;
}

export function getPaymentMethods(saveText) {
  if (ConfigurationStore.isFetched) {
    const methods = ConfigurationStore.getValue()?.order?.payment?.methods.map(item => {
      const { title, enabled, extraFeeDescription, ...rest } = item;
      return { ...rest, name: title || '', unavailable: !enabled, subTitle: extraFeeDescription };
    });
    const cards = User.getUserCards();
    if (methods) {
      const item = methods.find(el => el.type === 'card');
      if (cards) {
        // If cards exist, append each card with its masked number
        cards.forEach(el => {
          const { name, ...rest } = item;
          methods.push({
            ...rest,
            name: name + ' ****' + el.maskedNumber,
          });
        });
      }
      // Now check for card methods that do not have a masked IBAN and add checkbox: true
      methods.forEach(method => {
        if (method.type === 'card' && !method.name.includes('****')) {
          if (ConfigurationStore.getValue()?.order?.payment?.creditCard?.save) {
            method.checkbox = true;
            method.subTitle = saveText;
          }
        }
      });
      const sortedPaymentMethods = methods.sort((a, b) => b.type.localeCompare(a.type));
      return sortedPaymentMethods;
    }

    return methods;
  } else {
    return [];
  }
}

export function getBankAccounts() {
  if (ConfigurationStore.isFetched) {
    const accounts = ConfigurationStore.getValue()?.order?.payment?.methods.find(item => {
      item.type === 'bankTransfer';
    });

    return accounts?.bankAccountsConfig?.accounts;
  } else {
    return [];
  }
}

export function getPaymentConfigurations() {
  if (ConfigurationStore.isFetched) {
    return ConfigurationStore.getValue()?.order?.checkout?.paymentOptions;
  } else {
    return undefined;
  }
}

export function getBagsConfigurations() {
  if (ConfigurationStore.isFetched) {
    return ConfigurationStore.getValue()?.order?.bagsCalculation?.bagsRequired;
  } else {
    return true;
  }
}

export default {
  getOrFetchConfiguration,
  updateConfiguration,
  getPaymentMethods,
  getPaymentConfigurations,
  getBankAccounts,
  getBagsConfigurations,
};
