import { getCategoryDetailsFromId } from '@/utils/category.js';
import { chain, drop, get, trim } from 'lodash';
import TagManager from 'react-gtm-module';

import CategoryStore from '../store/categories';

import jsonStorage from './json-storage';

let categories = [];

CategoryStore.subscribe(val => {
  categories = val;
});

export function clearEcommerce() {
  Analytics.push({
    ecommerce: null,
  });
}

export function viewItem(product, overrides) {
  clearEcommerce();

  Analytics.push({
    event: 'view_item',
    ecommerce: { currency: 'EUR', value: product.price, items: [{ ...product, ...overrides }] },
  });
}

export function viewPromotion(promo) {
  clearEcommerce();
  Analytics.push({
    event: 'view_promotion',
    ecommerce: { ...promo },
  });
}

export function selectPromotion(promo) {
  clearEcommerce();
  Analytics.push({
    event: 'select_promotion',
    ecommerce: { ...promo },
  });
}

export function viewItemList(products, overrides) {
  clearEcommerce();
  Analytics.push({
    event: 'view_item_list',
    ecommerce: { items: products, ...overrides },
  });
}

export function selectItem(product, overrides) {
  clearEcommerce();
  Analytics.push({
    event: 'select_item',
    ecommerce: {
      item_list_id: overrides?.item_list_id || '',
      item_list_name: overrides?.item_list_name || '',
      items: [{ ...product, ...overrides }],
    },
  });
}

export function viewCart(products, total) {
  clearEcommerce();

  Analytics.push({
    event: 'view_cart',
    ecommerce: {
      currency: 'EUR',
      value: total,
      items: products,
    },
  });
}

export function addToCart(product, overrides) {
  clearEcommerce();
  Analytics.push({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'EUR',
      value: product?.price,
      items: [{ ...product, ...overrides }],
    },
  });
}

export function beginCheckout(products) {
  clearEcommerce();

  const totalValue = products.reduce?.(
    (previousValue, currentValue) => previousValue + (currentValue?.quantity || 1) * (currentValue?.price || 0),
    0
  );

  Analytics.push({
    event: 'begin_checkout',

    ecommerce: {
      currency: 'EUR',
      value: Math.round(totalValue * 100) / 100,
      items: products,
    },
  });
}

export function selectShippingMethod(products, shippingTier) {
  clearEcommerce();

  const totalValue = products.reduce?.(
    (previousValue, currentValue) => previousValue + (currentValue?.quantity || 1) * (currentValue?.price || 0),
    0
  );

  Analytics.push({
    event: 'add_shipping_info',
    ecommerce: {
      currency: 'EUR',
      value: Math.round(totalValue * 100) / 100,
      shipping_tier: shippingTier || '',
      items: products,
    },
  });
}

export function addPaymentMethod(products, totalPrice, paymentType) {
  clearEcommerce();

  Analytics.push({
    event: 'add_payment_info',

    ecommerce: {
      currency: 'EUR',
      value: totalPrice,
      payment_type: paymentType || '',
      items: products,
    },
  });
}

export function purchase(products, totalValue, shipping, coupon, transactionId, userData) {
  clearEcommerce();
  Analytics.push({
    event: 'purchase',

    ecommerce: {
      transaction_id: transactionId,
      currency: 'EUR',
      value: totalValue,
      shipping: shipping || 0,
      items: products,
      ...userData,
    },
  });
}

export function removeFromCart(product, overrides) {
  clearEcommerce();
  if (!product) return;

  Analytics.push({
    event: 'remove_from_cart',

    ecommerce: {
      currency: 'EUR',
      value: product.price,
      items: [{ ...product, ...overrides }],
    },
  });
}

export function viewSearchResults(searchTerm) {
  clearEcommerce();

  Analytics.push({
    event: 'view_search_results',
    ecommerce: {
      search_term: searchTerm,
    },
  });
}

export function changeSorting(sort) {
  clearEcommerce();
  Analytics.push({
    event: 'sorting_method',

    ecommerce: {
      key: sort,
    },
  });
}

export const bannersAnalyticsFunction = (banner, type) => {
  if ((type = 'view')) {
    viewPromotion({
      creative_name: banner?.altText ?? 'homepage_carousel',
      creative_slot: banner?.altText ?? 'homepage_carousel',
      promotion_id: banner?.altText ?? 'homepage_carousel',
      promotion_name: banner?.altText ?? 'homepage_carousel',
    });
  } else if ((type = 'click')) {
    selectPromotion({
      creative_name: banner?.altText ?? 'homepage_carousel',
      creative_slot: banner?.altText ?? 'homepage_carousel',
      promotion_id: banner?.altText ?? 'homepage_carousel',
      promotion_name: banner?.altText ?? 'homepage_carousel',
    });
  }
};

export const aislesViewAnalyticsFunction = (products, list, sessionΙd) => {
  viewItemList(
    products.map((i, idx) => getProductInfo(i, { index: idx + 1 }, i.slug)),
    {
      item_list_id: list,
      item_list_name: list,
      session_id: sessionΙd,
    }
  );
};

export function defaultConsent() {
  // Define dataLayer and the gtag function.
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    // eslint-disable-next-line
    dataLayer.push(arguments);
  }

  const permissions = {
    analytics_storage: 'denied',
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
  };

  gtag('consent', 'default', permissions);
}
export function updateConsent(performance, ads) {
  // Define dataLayer and the gtag function.
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    // eslint-disable-next-line
    dataLayer.push(arguments);
  }

  const permissions = {
    analytics_storage: performance ? 'granted' : 'denied',
    ad_storage: ads ? 'granted' : 'denied',
    ad_user_data: ads ? 'granted' : 'denied',
    ad_personalization: ads ? 'granted' : 'denied',
  };

  gtag('consent', 'update', permissions);
}

export function getProductInfo(product, overrides, slug) {
  const selectedCategory = product.categoryIds?.[0]
    ? getCategoryDetailsFromId(product.categoryIds[0])
    : product.categories?.[0]
    ? getCategoryDetailsFromId(product.categories[0])
    : undefined;

  const cat1 = selectedCategory?.parents?.[0] ? selectedCategory.parents[0].name : '';
  const cat2 = selectedCategory?.parents?.[1] ? selectedCategory.parents[1].name : '';
  const cat3 = selectedCategory?.parents?.[2] ? selectedCategory.parents[2].name : '';

  return {
    item_id: product.sku,
    sku: product.sku,
    item_name: product.displayName || product.name,
    affiliation: 'Online Store',
    discount: product.isWeighed
      ? Math.round((product.beginPrice - product.finalPrice) * product.stepToUom) / 100
      : Math.round(product.beginPrice - product.finalPrice) / 100,
    index: 0,
    item_brand: product?.brand || '',
    item_category: cat1,
    item_category2: cat2,
    item_category3: cat3,
    item_variant: product.quantity || '',
    price: product.isWeighed
      ? Math.round(product.finalPrice * product.stepToUom) / 100
      : Math.round(product.finalPrice) / 100,
    unit: product.unitOfMeasurement,
    unitQuantity: product.stepToUom,
    slug: slug,
    ...overrides,
  };
}
export function getCartProductInfo(product, overrides) {
  if (!product) return;
  const selectedCategory = product?.categoryIds?.[0]
    ? getCategoryDetailsFromId(product?.categoryIds[0])
    : product?.categories?.[0]
    ? getCategoryDetailsFromId(product.categories[0])
    : undefined;

  const cat1 = selectedCategory?.parents?.[0] ? selectedCategory.parents[0].name : '';
  const cat2 = selectedCategory?.parents?.[1] ? selectedCategory.parents[1].name : '';
  const cat3 = selectedCategory?.parents?.[2] ? selectedCategory.parents[2].name : '';

  return {
    item_id: product.sku,
    sku: product.sku,
    item_name: product.displayName || product.name,
    affiliation: 'Online Store',
    discount: product.isWeighed
      ? Math.round((product.beginPrice - product.finalPrice) * product.stepToUom) / 100
      : Math.round(product.beginPrice - product.finalPrice) / 100,
    index: 0,
    item_brand: product?.brand || '',
    item_category: cat1,
    item_category2: cat2,
    item_category3: cat3,
    item_variant: product.quantity || '',
    price: product.price,
    quantity: 1,
    unit:
      product.unitOfMeasurement === 'γρ.' || product.unitOfMeasurement === 'γρ'
        ? 'κιλ'
        : trim(product.unitOfMeasurement),
    unitQuantity: product.stepToUom,
    cut_type:
      product?.options?.length > 0 && product?.optionsGroups?.length > 0
        ? product.optionsGroups[0]?.options?.find?.(i => i.id === product?.options[0])?.label || ''
        : '',
    ...overrides,
  };
}

export function getProductsCartInfo(products, quantities) {
  if (!products) return [];
  // eslint-disable-next-line array-callback-return
  return products.map(product => {
    return { ...getProductInfo(product), quantity: quantities ? quantities[product._id] : product.quantity || '1' };
  });
}

export function objectToPromoEventTransformer(name, icon, creativeId = 'undefined', index = 0) {
  const iconPathnameKebab = icon.split('/').slice(3).join('_');
  const id = `${name}__${iconPathnameKebab}`;

  return {
    promotion_id: id,
    promotion_name: name,
    creative_slot: creativeId,
    creative_name: creativeId,
    items: [{ index: String(index + 1) }],
  };
}

function productToProductEventTransformer({ list = '', position = 0 }, product = {}) {
  const category = product.category?.name || '';
  const name = product.displayName || '';
  const rest = chain(product)
    .pick(product, ['price', 'brand', 'variant'])
    .mapValues(v => v || '')
    .value();

  return {
    ...rest,
    category,
    id: product.sku,
    list,
    name,
    position,
  };
}

const MAX_IMPRESSIONS_TO_SEND = 15;
let productImpressionsToSend = [];

const Analytics = {
  pushProductImpression(product, overrideFields) {
    const productEvent = productToProductEventTransformer(overrideFields, product) || [];
    productImpressionsToSend = [...productImpressionsToSend, ...[productEvent]];
    if (productImpressionsToSend.length === MAX_IMPRESSIONS_TO_SEND) {
      const eventObject = {
        event: 'updateImpressions',
        ecommerce: {
          impressions: productImpressionsToSend,
        },
      };
      productImpressionsToSend = drop(productImpressionsToSend, MAX_IMPRESSIONS_TO_SEND);
      Analytics.push(eventObject);
    }
  },

  /**
   * @param {Object} event GTM event
   * @returns {Object}
   * {} after a succesuful push,
   * { error } after trying to push but encountered an error
   */
  push(event) {
    if (typeof window !== 'undefined') {
      const storage = window.localStorage;
      const cookiesPerformance = jsonStorage.get(`${process.env.appName}:privacy`, { storage });
      const hasPermission = get(cookiesPerformance, 'performance');

      if (!hasPermission) {
        const error = 'no cookies permission';
        console.debug('Analytics', 'push', error);
        return { error };
      }

      if (!window?.dataLayer?.push) {
        TagManager.initialize({ gtmId: process.env.gtmKey });
        const error = 'no window.dataLayer.push';
        console.error('Analytics', 'push', error);
        return { error };
      }

      try {
        window.dataLayer.push(event);
        return {};
      } catch (error) {
        console.error('Analytics', 'push', 'catch', error);
        return { error: `couldn't complete window.dataLayer.push` };
      }
    }
  },
};

export default Analytics;
