import findLastIndex from 'lodash/findLastIndex';
import cloneDeep from 'lodash/cloneDeep';

import CartStore, { productsForOrder, ProductsRemovedFromCart } from '../store/cart';
import { HasShownMaxQuantityStore } from '../store/maxQuantity';

import { getProductInfo, addToCart, removeFromCart, getCartProductInfo } from './analytics';
import { cartPrice } from '../store/cart';
import {
  findGiftProducts,
  transformToFullPageCartItems,
  transformProducts,
  mergeEntriesWithProductQuantity,
} from '@/utils/products/products';
import { arraysEqual } from './helpers';

export const MAX_CART_VOLUME = 600;

export function onAddProductsUpdate(productQuantity, productAdded) {
  const currentProducts = productsForOrder.getValue();
  const orderProducts = currentProducts || [];
  if (productQuantity === 0) {
    productsForOrder.update([productAdded, ...orderProducts]);
  } else {
    productsForOrder.update([...orderProducts, productAdded]);
  }
}

export function addRegularProduct(product, isProductMYO, configDefaultOption, quantity = 0, productLimit = 6) {
  if (product?.stepToUom * quantity <= productLimit || !productLimit) {
    // if (!isProductMYO) {
    onAddProductsUpdate(quantity, product);

    // }
    // const productWithOptions = product.options ? product : { ...product, options: [configDefaultOption] };
    // onAddProductsUpdate(quantity, productWithOptions);
  }
}

export function onClickAddToOrder(
  product,
  productLimit,
  configDefaultOption,
  quantity = 0,
  isProductMYO = false,
  analyticsOverrides
) {
  addRegularProduct(product, isProductMYO, configDefaultOption, quantity, product?.quantityLimit);
  let newPrice = 0;
  if (product?.price) {
    newPrice = (parseFloat(cartPrice.getValue()) + parseFloat(product?.price)).toFixed(2).toString();

    if (product.ingredients && product.ingredientsGroups) {
      let additionalPrice = 0;

      for (const ingredient of product.ingredients) {
        for (const group of product.ingredientsGroups) {
          if (group.priceCalculationType === 'ADDITIONAL_TO_THE_PRICE') {
            const option = group.options.find(o => o.id === ingredient);

            if (!option) continue;

            additionalPrice += option.additionalPrice.value;
          }
        }
      }

      newPrice = (Number(newPrice) + additionalPrice / 100).toFixed(2);
    }
  } else {
    newPrice = (parseFloat(cartPrice.getValue()) + parseFloat(product?.finalPrice / 100)).toFixed(2).toString();
  }
  addToCart(getCartProductInfo(product, analyticsOverrides));
  cartPrice.update(newPrice);
}

export function onClickRemoveItem(product) {
  const currProducts = productsForOrder.getValue() || [];
  const tempProducts = currProducts.filter(p => p._id !== product._id);
  productsForOrder.update(tempProducts);
}

export function onClickRemoveFromOrder(product, analyticsOverrides) {
  const productMYO = !!product?.myoId;
  const tempProducts = productsForOrder.getValue();
  const productWithIngredients = !!product?.ingredients;
  let id;
  let lastIndex;

  if (productMYO) {
    id = product.myoId;
    lastIndex = findLastIndex(tempProducts, p => p.myoId === id);
  } else if (productWithIngredients) {
    id = product.id;
    lastIndex = findLastIndex(tempProducts, p => p.id === id && arraysEqual(p.ingredients || [], product.ingredients));
  } else {
    id = product?.id ? product.id : product?._id;
    lastIndex = findLastIndex(tempProducts, p => p.id === id);
  }

  const removedProducts = tempProducts?.filter((p, i) => i !== lastIndex);
  productsForOrder.update(removedProducts);
  let newPrice = 0;
  if (product?.price) {
    newPrice = (parseFloat(cartPrice.getValue()) - parseFloat(product?.price)).toFixed(2).toString();

    if (product.ingredients && product.ingredientsGroups) {
      let additionalPrice = 0;

      for (const ingredient of product.ingredients) {
        for (const group of product.ingredientsGroups) {
          if (group.priceCalculationType === 'ADDITIONAL_TO_THE_PRICE') {
            const option = group.options.find(o => o.id === ingredient);

            if (!option) continue;

            additionalPrice += option.additionalPrice.value;
          }
        }
      }

      newPrice = (Number(newPrice) - additionalPrice / 100).toFixed(2);
    }
  } else {
    newPrice = (parseFloat(cartPrice.getValue()) - parseFloat(product?.finalPrice / 100)).toFixed(2).toString();
  }
  if (newPrice < 0) {
    const totalPrice = productsForOrder.getValue().reduce((total, obj) => {
      if (obj.hasOwnProperty('price')) {
        const price = parseFloat(obj.price);
        if (!isNaN(price)) {
          total += price;
        }
      }
      return total;
    }, 0);
    cartPrice.update(totalPrice.toFixed(2));
  } else {
    cartPrice.update(newPrice);
  }

  removeFromCart(getCartProductInfo(product, analyticsOverrides));
}

export function clearCart() {
  productsForOrder.update([]);
  ProductsRemovedFromCart.update([]);
  // CartStore.setIsVisible(false);
  HasShownMaxQuantityStore.clear();
  cartPrice.update('0.00');
}

export function addGiftsToFullPageCart(order, allProducts, bundleOffersSummary, noCommentToken) {
  let giftQuantitiess,
    newAllProducts = null;
  if (order.order?.freeProducts?.length > 0) {
    const gifts = order.order.freeProducts;
    const { filteredGifts, giftQuantities, inCatalogueGifts, inCatalogueGiftsQuantities } = findGiftProducts(
      order.products,
      order.order.freeProducts
    );
    giftQuantitiess = inCatalogueGiftsQuantities;
    const freeproducts = transformToFullPageCartItems(
      filteredGifts,
      order.order,
      true,
      true,
      giftQuantities,
      1,
      noCommentToken
    );
    let mockOrderCheckResponse = cloneDeep(order);
    mockOrderCheckResponse.order.products = inCatalogueGifts;
    const inCatGifts = transformProducts(mockOrderCheckResponse, bundleOffersSummary, true, undefined, noCommentToken);
    newAllProducts = [...inCatGifts, ...allProducts];
    newAllProducts = mergeEntriesWithProductQuantity(newAllProducts, true);
    newAllProducts = [...freeproducts, ...newAllProducts];
  }
  return { giftQuantitiess, newAllProducts };
}

export function mergeProductArrays(array1, array2) {
  const mergedMap = new Map();

  function generateKey(product) {
    return product.productId + (product.options.length > 0 ? `_${product.options[0]}` : '');
  }

  function addToMap(product) {
    const key = generateKey(product);
    if (mergedMap.has(key)) {
      mergedMap.get(key).quantity += product.quantity;
    } else {
      mergedMap.set(key, { ...product });
    }
  }

  array1.forEach(addToMap);
  array2.forEach(addToMap);

  return Array.from(mergedMap.values());
}

export default {
  onClickAddToOrder,
  onClickRemoveFromOrder,
  onClickRemoveItem,
  clearCart,
  MAX_CART_VOLUME,
  addRegularProduct,
  onAddProductsUpdate,
  addGiftsToFullPageCart,
  mergeProductArrays,
};
