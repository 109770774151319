import { BehaviorSubject } from 'rxjs';

const bundleOffers = new BehaviorSubject([]);
const subjectIsFetched = new BehaviorSubject(false);

const IsFetched = {
  update: fetched => {
    subjectIsFetched.next(fetched);
  },
};

const BundeOffersStore = {
  update: offers => {
    const offersDictionary =
      offers.length > 0
        ? offers.reduce((result, offer) => {
            result[offer._id] = offer;
            return result;
          }, {})
        : {};
    bundleOffers.next(offersDictionary);
    IsFetched.update(true);
  },

  subscribe: setState => bundleOffers.subscribe(setState),
  getValue: () => bundleOffers.value,
  isFetched: () => subjectIsFetched.value,
};

export default BundeOffersStore;
