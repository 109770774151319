import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidCatch(error, _) {
    // We can use sentry here for logging uncaught clientside errors
    this.props.onError();
  }
  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
