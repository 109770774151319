import { useQuery } from '@tanstack/react-query';
import { getProductDetailsV3, getProductInfoV3 } from '@/api/Products';

export function useProductDetailResources(id, locale) {
  const {
    data: productInfo,
    isLoading: isLoadingProductInfo,
    error: productInfoError,
    refetch: productInfoRefetch,
  } = useQuery({
    queryKey: ['product', id, locale],
    queryFn: () => getProductInfoV3(id, locale, process.env.PRIVATE_API_KEY),
    enabled: !!id,
  });

  const {
    data: productDetails,
    isLoading: isLoadingProductDetails,
    error: productDetailsError,
    refetch: productDetailsRefetch,
  } = useQuery({
    queryKey: ['product-details', id, locale],
    queryFn: () => getProductDetailsV3(id, locale, process.env.PRIVATE_API_KEY),
    enabled: !!id,
  });

  return {
    productInfo,
    isLoadingProductInfo,
    productInfoError,
    productInfoRefetch,
    productDetails,
    isLoadingProductDetails,
    productDetailsError,
    productDetailsRefetch,
  };
}
