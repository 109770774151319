import { useMemo } from 'react';

export function useNProgressConfig() {
  const progressColor = useMemo(() => {
    let colorVar;

    switch (process.env.topHeaderBackgroundColor) {
      case 0:
        colorVar = 'var(--layers-primaryBase)';
        break;
      case 1:
        colorVar = 'var(--layers-secondaryBase)';
        break;
      case 2:
        colorVar = 'var(--layers-primaryBase)';
        break;
      case 3:
        colorVar = 'var(--layers-secondaryBase)';
        break;
      default:
        colorVar = 'var(--layers-primaryBase)';
        break;
    }

    return colorVar;
  }, []);

  const config = useMemo(() => {
    return { showSpinner: false, trickle: true };
  }, []);

  return { progressColor, config, height: '4px' };
}
