import { BehaviorSubject } from 'rxjs';

import jsonStorage from '../utils/json-storage';
import { emitOnAttrChange } from '../utils/store';

const couponSubject = new BehaviorSubject(0);
const INITIAL_SUBJECT_VALUE = undefined;

const STORAGE_NAME_ORDER = 'order';
const STORAGE_NAME_ORDER_HISTORY = 'prevOrder';
const meta = {
  cached: false,
  fetched: false,
  updatedAt: new Date(),
};

const metaOrderFromHistory = {
  cached: false,
  fetched: false,
  updatedAt: new Date(),
};

export const Order = {
  subject: new BehaviorSubject(null),

  lazyInit() {
    if (Order.subject.value !== null) return Order.subject;

    const storage = window.localStorage;
    const order = jsonStorage.get(STORAGE_NAME_ORDER, { storage });
    Object.assign(meta, {
      cached: true,
      fetched: false,
      updatedAt: new Date(),
    });
    Order.subject = new BehaviorSubject(order);
    return Order.subject;
  },

  update(order) {
    const order$ = Order.lazyInit();
    const storage = window.localStorage;
    jsonStorage.set(STORAGE_NAME_ORDER, order, { storage });
    order$.next(order);
    Object.assign(meta, {
      fetched: true,
      updatedAt: new Date(),
    });
  },

  subscribe(setState) {
    const order = Order.lazyInit();
    return order.subscribe(setState);
  },

  getValue() {
    const order = Order.lazyInit();
    return order.value;
  },

  getService$() {
    const order = Order.lazyInit();
    return emitOnAttrChange(order, 'selectedService');
  },

  setSelectedAddress(addressObject) {
    const order = Order.getValue();
    Order.update({
      ...order,
      addressObject,
    });
  },

  setTimeslot(slot) {
    const order = Order.getValue();
    Order.update({
      ...order,
      timeSlotEnd: slot.timeSlotEnd,
      timeSlotStart: slot.timeSlotStart,
      timeslotId: slot._id ? slot._id : null,
    });
  },

  getTimeSlotStart() {
    const order = Order.getValue();
    return order.timeSlotStart;
  },

  setChangesAllowed(option) {
    const order = Order.getValue();
    Order.update({
      ...order,
      changesAllowed: option,
    });
  },

  setPointsUse(usePoints) {
    const order = Order.getValue();
    Order.update({
      ...order,
      redeemPoints: usePoints,
    });
  },

  setPlasticBags(wantPlasticBags) {
    const order = Order.getValue();
    Order.update({
      ...order,
      wantPlasticBags,
    });
  },

  setInvoiceInfo(invoiceInfo) {
    const order = Order.getValue();
    Order.update({
      ...order,
      invoice: {
        companyAddress: invoiceInfo.companyAddress,
        companyName: invoiceInfo.companyName,
        companyPhone: invoiceInfo.companyPhone,
        profession: invoiceInfo.profession,
        taxId: invoiceInfo.AFM,
        taxOffice: invoiceInfo.DOY,
      },
    });
  },

  setCouponCode(code) {
    const order = Order.getValue();
    Order.update({
      ...order,
      couponCode: code,
    });
  },

  setPaymentType(paymentType, datacashReference) {
    const order = Order.getValue();
    Order.update({
      ...order,
      paymentType,
      datacashReference,
    });
  },

  setProducts(products) {
    const order = Order.getValue();
    Order.update({
      ...order,
      products,
    });
  },

  setComments(comments) {
    const order = Order.getValue();
    Order.update({
      ...order,
      comments,
    });
  },

  setOffers(offers) {
    const order = Order.getValue();
    Order.update({
      ...order,
      offers,
    });
  },

  setShopId(shop) {
    const order = Order.getValue();
    Order.update({
      ...order,
      shop,
    });
  },

  setPenalty(penaltyFee, minimumPrice) {
    const order = Order.getValue();
    Order.update({
      ...order,
      penaltyFee,
      minimumPrice,
    });
  },

  setDiscountCampaignsApplied(discountCampaignsApplied) {
    const order = Order.getValue();
    Order.update({
      ...order,
      discountCampaignsApplied,
    });
  },

  setTotalPrice(totalPrice) {
    const order = Order.getValue();
    Order.update({
      ...order,
      totalPrice,
    });
  },

  reset() {
    Order.update(undefined);
  },

  deleteProperty(properties) {
    const order = Order.getValue();
    const newOrder = properties.reduce((acc, property) => {
      return {
        ...acc,
        [property]: null,
      };
    }, order);
    Order.update(newOrder);
  },
};

export const CouponDiscount = {
  update: code => {
    couponSubject.next(code);
  },
  subscribe: setState => couponSubject.subscribe(setState),
  getValue: () => couponSubject.value,
};

export const OrderFromHistory = {
  subject: INITIAL_SUBJECT_VALUE,

  lazyInit() {
    if (OrderFromHistory.subject) return OrderFromHistory.subject;

    const storage = window.localStorage;
    const orderFromHistory = jsonStorage.get(STORAGE_NAME_ORDER_HISTORY, {
      storage,
    });
    Object.assign(metaOrderFromHistory, {
      cached: true,
      fetched: false,
      updatedAt: new Date(),
    });
    OrderFromHistory.subject = new BehaviorSubject(orderFromHistory);
    return OrderFromHistory.subject;
  },

  subscribe(setState) {
    const orderFromHistory = OrderFromHistory.lazyInit();
    return orderFromHistory.subscribe(setState);
  },

  update(order) {
    const orderFromHistory$ = OrderFromHistory.lazyInit();
    orderFromHistory$.next(order);

    Object.assign(metaOrderFromHistory, {
      fetched: true,
      updatedAt: new Date(),
    });

    const storage = window.localStorage;
    jsonStorage.set(STORAGE_NAME_ORDER_HISTORY, order, { storage });
  },

  getValue() {
    const orderFromHistory = OrderFromHistory.lazyInit();
    return orderFromHistory.value;
  },
};

export default { Order, CouponDiscount, OrderFromHistory };
