import axios from 'axios';
import get from 'lodash/get';
import { waveV3 } from './config/wave';
import User from '@/store/user';

const baseURL = `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api/users`;
const baseURLV3 = `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api/v3/users`;

export async function deleteCreditCard(authorization, creditCardId) {
  try {
    const response = await waveV3.delete(`${baseURLV3}/me/creditCards/${creditCardId}`, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });
    User._lazyUserObjectRefresh();
    return response;
  } catch (error) {
    return {};
  }
}

export async function updateUser(authorization, user) {
  try {
    const response = await axios.post(`${baseURL}/update`, user, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });
    const res = get(response, 'data.payload.users');
    const { users, verification } = response.data.payload;
    if (users) {
      return { ...res, token: authorization };
    }
    if (verification) {
      return verification;
    }
    return 'Παρακαλώ προσπαθήστε αργότερα';
  } catch (error) {
    return error?.response?.data;
  }
}

export async function getUserV3(authorization) {
  try {
    const response = await waveV3.get(`${baseURLV3}/me`, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });

    const userFetched = response?.data;
    if (!userFetched) return 'Παρακαλώ προσπαθήστε αργότερα';

    // if (process.env.NODE_ENV === 'production' && process.env.appName === 'kritikos') {
    //   updateUserInContactPigeon(userReduce.email);
    // }

    return { ...userFetched, token: authorization };
  } catch (error) {
    return {};
  }
}

export async function createUserAddress(authorization, resource) {
  try {
    const response = await waveV3.post(`${baseURLV3}/me/addresses`, resource, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });
    User._lazyUserObjectRefresh();
    return response;
  } catch (error) {
    return error;
  }
}

export async function deleteUserAddress(authorization, addressId) {
  try {
    const response = await waveV3.delete(`${baseURLV3}/me/addresses/${addressId}`, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });
    User._lazyUserObjectRefresh();
    return response;
  } catch (error) {
    return {};
  }
}

export async function updateUserAddress(authorization, resource, addressId) {
  try {
    const response = await waveV3.put(`${baseURLV3}/me/addresses/${addressId}`, resource, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });
    User._lazyUserObjectRefresh();
    return response;
  } catch (error) {
    return error;
  }
}

export async function checkUserUpdate(authorization, resource) {
  const response = await waveV3.put(`${baseURLV3}/me/check`, resource, {
    headers: {
      appId: process.env.appId,
      authorization: `Bearer ${authorization}`,
    },
  });
  return response.data;
}

export async function updateUserV3(authorization, resource) {
  const response = await waveV3.put(`${baseURLV3}/me`, resource, {
    headers: {
      appId: process.env.appId,
      authorization: `Bearer ${authorization}`,
    },
  });
  return { response };
}

export async function deleteUser(authorization) {
  try {
    return await waveV3.delete(`${baseURLV3}/me`, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });
  } catch (error) {
    return {};
  }
}

export default {
  updateUser,
};
