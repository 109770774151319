import { useEffect, useState, useContext, useMemo } from 'react';
import ReactModal from 'react-modal';
import { IngredientsDecision, Icon, Close } from '@desquared/wave-components-library';
import {
  IngredientsModalStore,
  IngredientsStore,
  IngredientsModalConfigurationStore,
  IngredientsEditNavigationStore,
} from '@/store/prefferenceModal';
import { useProductDetailResources } from '@/hooks/useProductDetailResources';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { CurrencyContext } from '@/contexts/CurrencyProvider';
import { addProduct, removeProduct } from '@/utils/products';
import { isEditStarted, productsForOrder } from '@/store/cart';
import { arraysEqual } from '@/utils/helpers';
import { useConfiguration } from '@/contexts/ConfigContext';
import useWindowUnderThreshold from '@/hooks/useWindowUnderThreshold';
import styles from './IngredientsDecision.module.css';

export function IngredientsDecisionModal() {
  const t = useTranslations();
  const router = useRouter();
  const isMobile = useWindowUnderThreshold(768);
  const { formatter } = useContext(CurrencyContext);
  const [showModal, setShowModal] = useState(false);
  const [product, setProduct] = useState();
  const [productsInCart, setProductsInCart] = useState([]);
  const [modalConfigration, setModalConfiguration] = useState('large');
  const [comment, setComment] = useState('');
  const { config: configuration } = useConfiguration();
  const commentConfig = useMemo(
    () => ({
      maxLength: configuration?.fields?.orderItems?.comments?.maxLength,
      title: t('common.ingredients.modal.comment_title'),
      placeholder: t('common.ingredients.modal.comment_placeholder'),
    }),
    [t, configuration]
  );

  useEffect(() => {
    const subscriptions = [
      IngredientsModalStore.subscribe(shouldDisplayModal => {
        if (!shouldDisplayModal) resetModal();

        setShowModal(shouldDisplayModal);
      }),
      IngredientsStore.subscribe(setProduct),
      productsForOrder.subscribe(setProductsInCart),
      IngredientsModalConfigurationStore.subscribe(setModalConfiguration),
    ];

    return () => {
      for (const sub of subscriptions) sub.unsubscribe();
    };
  }, [setShowModal, setProduct, setProductsInCart]);

  const productsInCartFiltered = useMemo(() => {
    const mappedProducts = productsInCart
      .filter(p => p.id === product?.id)
      .map(p => {
        let ingredientsDescription = '';
        let additionalPrice = 0;

        for (const group of p?.ingredientsGroups) {
          for (const option of group.options) {
            const ingredients = typeof p.ingredients[0] === 'string' ? p.ingredients : p.ingredients.map(i => i.id);
            if (ingredients.includes(option.id)) {
              ingredientsDescription += `${option.label}, `;

              if (group.priceCalculationType === 'ADDITIONAL_TO_THE_PRICE') {
                additionalPrice += option.additionalPrice.value;
              }
            }
          }
        }

        if (ingredientsDescription.length) ingredientsDescription = ingredientsDescription.slice(0, -2);

        return {
          id: p.id,
          name: product?.displayName ?? product.name,
          ingredientsDescription,
          price: Number(product.price ?? product.beginPrice / 100) + additionalPrice / 100,
          ingredients: typeof p.ingredients?.[0] === 'string' ? p.ingredients : p.ingredients.map(i => i.id),
          onProductEdit: () => {
            if (isEditStarted.getValue() && !!IngredientsEditNavigationStore.getValue()) {
              const handleLoadProduct = IngredientsEditNavigationStore.getValue();
              handleLoadProduct(
                p.id,
                p.finalPrice,
                p.beginPrice,
                typeof p.ingredients?.[0] === 'string' ? p.ingredients : p.ingredients.map(i => i.id)
              );
            } else {
              router.push({
                pathname: product.slug,
                search: `?preSelectedIngredients=${encodeURIComponent(JSON.stringify(p.ingredients))}`,
              });
            }

            IngredientsModalStore.update(false);
          },
          originalValues: {
            QsReadonly: false,
            QsShape: 0,
            canIncrease: true,
            isAvailable: p.available,
            quantityLimit: p.quantityLimit,
            size: p.size,
            volume: p.stepToUom,
            canDecrease: true,
            volumeType: p.volume || p.unitOfMeasurement,
          },
        };
      });

    const uniqueProducts = mappedProducts.reduce((acc, current) => {
      const existing = acc.find(item => item.id === current.id && arraysEqual(item.ingredients, current.ingredients));

      if (existing) {
        existing.originalValues.volume += current.originalValues.volume;
      } else {
        acc.push({ ...current });
      }

      return acc;
    }, []);

    return uniqueProducts;
  }, [productsInCart, product]);

  useEffect(() => {
    if (
      IngredientsModalStore.getValue() &&
      !productsInCartFiltered.length &&
      IngredientsModalConfigurationStore.getValue() !== 'large'
    ) {
      IngredientsModalStore.update(false);
      IngredientsModalConfigurationStore.update('large');
    }
  }, [productsInCartFiltered]);

  const initialValues = useMemo(() => {
    let initial = {};

    if (product?.ingredients && product?.ingredientsGroups) {
      for (const group of product.ingredientsGroups) {
        for (const option of group.options) {
          if (product.ingredients.includes(option.id) && option.available) {
            if (initial[group.id]) initial[group.id].push(option.id);
            else initial[group.id] = [option.id];
          }
        }
      }
    }

    return initial;
  }, [product]);

  const { productDetails } = useProductDetailResources(product?.id, router.locale);

  const handleAddToCart = ingredients => {
    const ingredientIds = [];
    if (Array.isArray(ingredients)) {
      for (const ingredient of ingredients) ingredientIds.push(ingredient);
    } else {
      for (const key in ingredients) {
        for (const id of ingredients[key]) ingredientIds.push(id);
      }
    }

    const productWithIngredients = {
      ...product,
      ingredients: ingredientIds,
      ...(comment && comment.length ? { comments: comment } : null),
    };
    addProduct(productWithIngredients, false, undefined, false, t('full_page_cart.no_comment'));
  };

  const handleRemoveFromCart = ingredients => {
    const productWithIngredients = { ...product, ingredients: ingredients };

    removeProduct(productWithIngredients, undefined, t('full_page_cart.no_comment'));
  };

  const generateErrorMessage = (key, options) => {
    return t(`common.ingredients.modal.errors.${key}`, options);
  };

  const resetModal = () => {
    IngredientsModalConfigurationStore.update('large');
    setComment('');
  };

  return (
    <ReactModal
      isOpen={showModal}
      onRequestClose={() => {
        IngredientsModalStore.update(false);
        resetModal();
      }}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
        },
        content: {
          position: 'absolute',
          top: isMobile ? 0 : '50%',
          left: isMobile ? 0 : '50%',
          transform: isMobile ? 'none' : 'translate3d(-50%, -50%, 0)',
          padding: 0,
          right: isMobile ? 0 : 'unset',
          bottom: isMobile ? 0 : 'unset',
          maxHeight: '100%',
          overflowY: 'scroll',
          scrollbarWidth: 'none',
          borderRadius: isMobile ? 0 : '1rem',
          height: isMobile ? '100vh' : '90vh',
          width: isMobile ? '100vw' : 'fit-content',
        },
      }}
    >
      <button
        className={styles.closeIcon}
        onClick={() => {
          IngredientsModalStore.update(false);
        }}
      >
        <Icon size={0} IconComponent={Close} style={{ marginLeft: 'auto' }} iconColor={0}></Icon>
      </button>
      {product ? (
        <IngredientsDecision
          ingredientGroups={product?.ingredientsGroups}
          productsInCart={productsInCartFiltered}
          product={{
            title: product.displayName ?? product.name,
            description: productDetails?.description || product.description,
            img: product.image,
            price: Number(product.price ?? product.beginPrice / 100) * 100,
          }}
          ingredientsTitle={t('common.ingredients.modal.add_ingredients')}
          selectorSubmitText={t('common.ingredients.modal.selector_submit')}
          currencyFormatter={formatter}
          addToCart={handleAddToCart}
          removeFromCart={handleRemoveFromCart}
          defaultIngredientValues={initialValues}
          addWithNewOptionsWording={t('common.ingredients.modal.add_with_new_options')}
          onAfterSubmit={() => {
            IngredientsModalStore.update(false);
          }}
          shouldDisplayFullSize={modalConfigration === 'large'}
          alreadyInCartWording={t('common.ingredients.modal.already_in_cart')}
          getErrorMessage={generateErrorMessage}
          commentConfig={{
            value: comment,
            setValue: setComment,
            ...commentConfig,
          }}
        ></IngredientsDecision>
      ) : null}
    </ReactModal>
  );
}
