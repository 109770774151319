import { BehaviorSubject } from 'rxjs';

import jsonStorage from '../utils/json-storage';
import { getPaymentMethods } from '@/utils/getConfiguration';

const isSideSheetOpenSubject = new BehaviorSubject(false);
const isPointsSideSheetOpenSubject = new BehaviorSubject(false);
const isGiftsSideSheetOpenSubject = new BehaviorSubject(false);
const isResponsiveMenuSubject = new BehaviorSubject(false);

const DeliverySubject = new BehaviorSubject(0);
const TakeAwaySubject = new BehaviorSubject(0);
const isServiceAddressSideSheetStoreOpenSubject = new BehaviorSubject(false);
const isBundleOfferSideSheetStoreOpenSubject = new BehaviorSubject(false);

const isCountryCodeSideSheetOpenSubject = new BehaviorSubject(false);
const isProductsSideSheetOpenSubject = new BehaviorSubject(false);
const isPostalCodeSideSheetOpenSubject = new BehaviorSubject(false);
const isReplacementSheetOpenSubject = new BehaviorSubject(false);
const isReplacementOptionSubject = new BehaviorSubject({ option: 'phone', title: 'Τηλεφωνική επικοινωνία' });
const isPaymentOptionSubject = new BehaviorSubject('cash');

const isTimesSlotsSideSheetStore = new BehaviorSubject(false);
const isPrebookSideSheetStore = new BehaviorSubject(false);
const isCompanyAsideSideSheetOpenSubject = new BehaviorSubject(false);
const isListAdditionSheetOpenSubject = new BehaviorSubject(false);
const isPassVerificationSheetOpenSubject = new BehaviorSubject(false);
const isOTPVerificationSideSheetOpenSubject = new BehaviorSubject(false);

const AddressMenuSideSheetOpenShown = {
  subject: undefined,
  lazyInit() {
    if (AddressMenuSideSheetOpenShown.subject !== undefined) return AddressMenuSideSheetOpenShown.subject;
    const storage = window.localStorage;
    const addressSeen = jsonStorage.get('hasSeenAddressSide', { storage });
    AddressMenuSideSheetOpenShown.subject = new BehaviorSubject(addressSeen);
    return AddressMenuSideSheetOpenShown.subject;
  },
  getValue() {
    const addressSeen = AddressMenuSideSheetOpenShown.lazyInit();
    return addressSeen.value;
  },
  update(shown) {
    const seen = AddressMenuSideSheetOpenShown.lazyInit();
    seen.next(shown);
    const storage = window.localStorage;
    jsonStorage.set('hasSeenAddressSide', shown, { storage });
  },
};

const isAddressMenuSideSheetOpenFromProfile = new BehaviorSubject(false);

const AddressMenuSideSheetOpenFromProfile = {
  update: shown => {
    isAddressMenuSideSheetOpenFromProfile.next(shown);
  },
};

const isAddressViewOpenSubject = new BehaviorSubject(false);

export const InitPickupMethodStore = {
  subject: new BehaviorSubject(null),

  _lazyInit() {
    if (InitPickupMethodStore.subject.getValue() !== null) return InitPickupMethodStore.subject;

    if (typeof window !== 'undefined' && window?.localStorage) {
      const storage = window.localStorage;
      const pickupMethod =
        storage && jsonStorage.get('pickupMethod', { storage }) !== null
          ? jsonStorage.get('pickupMethod', { storage })
          : 0;
      InitPickupMethodStore.subject.next(pickupMethod);
      return InitPickupMethodStore.subject;
    }
  },
  update: value => {
    const pickupMethod = InitPickupMethodStore._lazyInit();
    pickupMethod.next(value);
    const storage = window.localStorage;
    jsonStorage.set('pickupMethod', value, { storage });
  },
  subscribe: setState => {
    const pickupMethod = InitPickupMethodStore._lazyInit();
    return pickupMethod.subscribe(setState);
  },
  getValue: () => {
    const pickupMethod = InitPickupMethodStore._lazyInit();
    return pickupMethod?.value;
  },
  clear: () => {
    InitPickupMethodStore.update(null);
    window.localStorage.removeItem('pickupMethod');
  },
};

const isPickupMethodSubject = new BehaviorSubject(InitPickupMethodStore.getValue());
export const SideSheetStore = {
  update: value => {
    isSideSheetOpenSubject.next(value);
  },
  subscribe: setState => isSideSheetOpenSubject.subscribe(setState),
  getValue: () => isSideSheetOpenSubject.value,
  open: () => {
    isSideSheetOpenSubject.next(true);
  },
  close: () => {
    isSideSheetOpenSubject.next(false);
  },
};
export const ResponsiveMenuStore = {
  update: value => {
    isResponsiveMenuSubject.next(value);
  },
  subscribe: setState => isResponsiveMenuSubject.subscribe(setState),
  getValue: () => isResponsiveMenuSubject.value,
  open: () => {
    isResponsiveMenuSubject.next(true);
  },
  close: () => {
    isResponsiveMenuSubject.next(false);
  },
};
export const PickupMethodStore = {
  update: () => {
    isPickupMethodSubject.next(!isPickupMethodSubject.value);
    InitPickupMethodStore.update(!isPickupMethodSubject.value);
  },
  subscribe: setState => isPickupMethodSubject.subscribe(setState),
  getValue: () => isPickupMethodSubject.value,
};
export const DeliveryStore = {
  _lazyInit() {
    if (typeof window !== 'undefined' && window?.localStorage) {
      const storage = window.localStorage;
      const selectedUserAddressIndex =
        storage && jsonStorage.get('selectedUserAddressIndex', { storage }) !== null
          ? jsonStorage.get('selectedUserAddressIndex', { storage })
          : undefined;
      DeliveryStore.subject = new BehaviorSubject(selectedUserAddressIndex);
      return DeliveryStore.subject;
    }
  },
  update: value => {
    DeliverySubject.next(value);
    const storage = window.localStorage;
    jsonStorage.set('selectedUserAddressIndex', value, { storage });
  },
  subscribe: setState => {
    const selectedUserAddressIndex = DeliveryStore._lazyInit();
    return selectedUserAddressIndex.subscribe(setState);
  },
  getValue: () => {
    const selectedUserAddressIndex = DeliveryStore._lazyInit();
    return selectedUserAddressIndex?.value;
  },
};
export const TakeAwayStore = {
  update: value => {
    TakeAwaySubject.next(value);
  },
  subscribe: setState => TakeAwaySubject.subscribe(setState),
  getValue: () => TakeAwaySubject.value,
};

export const ServiceAddressSideSheetStore = {
  update: value => {
    isServiceAddressSideSheetStoreOpenSubject.next(value);
  },
  subscribe: setState => isServiceAddressSideSheetStoreOpenSubject.subscribe(setState),
  getValue: () => isServiceAddressSideSheetStoreOpenSubject.value,
  open: () => {
    isServiceAddressSideSheetStoreOpenSubject.next(true);
  },
  close: () => {
    isServiceAddressSideSheetStoreOpenSubject.next(false);
  },
};
export const CountryCodeSideSheetStore = {
  update: value => {
    isCountryCodeSideSheetOpenSubject.next(value);
  },
  subscribe: setState => isCountryCodeSideSheetOpenSubject.subscribe(setState),
  getValue: () => isCountryCodeSideSheetOpenSubject.value,
  open: () => {
    isCountryCodeSideSheetOpenSubject.next(true);
  },
  close: () => {
    isCountryCodeSideSheetOpenSubject.next(false);
  },
};
export const PostalCodeSideSheetStore = {
  update: value => {
    isPostalCodeSideSheetOpenSubject.next(value);
  },
  subscribe: setState => isPostalCodeSideSheetOpenSubject.subscribe(setState),
  getValue: () => isPostalCodeSideSheetOpenSubject.value,
  open: () => {
    isPostalCodeSideSheetOpenSubject.next(true);
  },
  close: () => {
    isPostalCodeSideSheetOpenSubject.next(false);
  },
};

export const TimesSlotsSideSheetStore = {
  update: value => {
    isTimesSlotsSideSheetStore.next(value);
  },
  subscribe: setState => isTimesSlotsSideSheetStore.subscribe(setState),
  getValue: () => isTimesSlotsSideSheetStore.value,
  open: () => {
    isTimesSlotsSideSheetStore.next(true);
  },
  close: () => {
    isTimesSlotsSideSheetStore.next(false);
  },
};

export const PrebookSideSheetStore = {
  update: value => {
    isPrebookSideSheetStore.next(value);
  },
  subscribe: setState => isPrebookSideSheetStore.subscribe(setState),
  getValue: () => isPrebookSideSheetStore.value,
  open: () => {
    isPrebookSideSheetStore.next(true);
  },
  close: () => {
    isPrebookSideSheetStore.next(false);
  },
};

export const AddressViewSideSheetStore = {
  update: value => {
    isAddressViewOpenSubject.next(value);
    AddressMenuSideSheetOpenShown.update(true);
  },
  subscribe: setState => isAddressViewOpenSubject.subscribe(setState),
  getValue: () => isAddressViewOpenSubject.value,
  open: place => {
    place === 'profile' ? AddressMenuSideSheetOpenFromProfile.update(true) : null;
    isAddressViewOpenSubject.next(true);
    AddressMenuSideSheetOpenShown.update(true);
  },
  close: place => {
    place === 'profile' ? AddressMenuSideSheetOpenFromProfile.update(false) : null;

    isAddressViewOpenSubject.next(false);
  },
  isShown: () => AddressMenuSideSheetOpenShown.getValue(),
  isFromProfile: () => isAddressMenuSideSheetOpenFromProfile.value,
  reset: () => AddressMenuSideSheetOpenShown.update(false),
};

export const ListAdditionSheetStore = {
  update: value => {
    isListAdditionSheetOpenSubject.next(value);
  },
  subscribe: setState => isListAdditionSheetOpenSubject.subscribe(setState),
  getValue: () => isListAdditionSheetOpenSubject.value,
  open: () => {
    isListAdditionSheetOpenSubject.next(true);
  },
  close: () => {
    isListAdditionSheetOpenSubject.next(false);
  },
};
export const ReplacementSheetStore = {
  update: value => {
    isReplacementSheetOpenSubject.next(value);
  },
  subscribe: setState => isReplacementSheetOpenSubject.subscribe(setState),
  getValue: () => isReplacementSheetOpenSubject.value,
  open: () => {
    isReplacementSheetOpenSubject.next(true);
  },
  close: () => {
    isReplacementSheetOpenSubject.next(false);
  },
};
export const ReplacementOptionStore = {
  update: value => {
    isReplacementOptionSubject.next(value);
  },
  subscribe: setState => isReplacementOptionSubject.subscribe(setState),
  getValue: () => isReplacementOptionSubject.value,
};
export const PaymentOptionStore = {
  init: () => isPaymentOptionSubject.next(getPaymentMethods()),
  update: value => {
    isPaymentOptionSubject.next(value);
  },
  subscribe: setState => isPaymentOptionSubject.subscribe(setState),
  getValue: () => isPaymentOptionSubject.value,
};

export const CompanyAsideSideSheetStore = {
  update: value => {
    isCompanyAsideSideSheetOpenSubject.next(value);
  },
  subscribe: setState => isCompanyAsideSideSheetOpenSubject.subscribe(setState),
  getValue: () => isCompanyAsideSideSheetOpenSubject.value,
  open: () => {
    isCompanyAsideSideSheetOpenSubject.next(true);
  },
  close: () => {
    isCompanyAsideSideSheetOpenSubject.next(false);
  },
};

export const PointsSideSheetStore = {
  update: value => {
    isPointsSideSheetOpenSubject.next(value);
  },
  subscribe: setState => isPointsSideSheetOpenSubject.subscribe(setState),
  getValue: () => isPointsSideSheetOpenSubject.value,
  open: () => {
    isPointsSideSheetOpenSubject.next(true);
  },
  close: () => {
    isPointsSideSheetOpenSubject.next(false);
  },
};

export const GiftsSideSheetStore = {
  update: value => {
    isGiftsSideSheetOpenSubject.next(value);
  },
  subscribe: setState => isGiftsSideSheetOpenSubject.subscribe(setState),
  getValue: () => isGiftsSideSheetOpenSubject.value,
  open: () => {
    isGiftsSideSheetOpenSubject.next(true);
  },
  close: () => {
    isGiftsSideSheetOpenSubject.next(false);
  },
};

export const BundleOfferSideSheetStore = {
  update: value => {
    isBundleOfferSideSheetStoreOpenSubject.next(value);
  },
  subscribe: setState => isBundleOfferSideSheetStoreOpenSubject.subscribe(setState),
  getValue: () => isBundleOfferSideSheetStoreOpenSubject.value,
  open: () => {
    isBundleOfferSideSheetStoreOpenSubject.next(true);
  },
  close: () => {
    isBundleOfferSideSheetStoreOpenSubject.next(false);
  },
};
export const ProductsSideSheetStore = {
  update: value => {
    isProductsSideSheetOpenSubject.next(value);
  },
  subscribe: setState => isProductsSideSheetOpenSubject.subscribe(setState),
  getValue: () => isProductsSideSheetOpenSubject.value,
  open: () => {
    isProductsSideSheetOpenSubject.next(true);
  },
  close: () => {
    isProductsSideSheetOpenSubject.next(false);
  },
};

export const PassVerificationSideSheetStore = {
  update: value => {
    isPassVerificationSheetOpenSubject.next(value);
  },
  subscribe: setState => isPassVerificationSheetOpenSubject.subscribe(setState),
  getValue: () => isPassVerificationSheetOpenSubject.value,
  open: () => {
    isPassVerificationSheetOpenSubject.next(true);
  },
  close: () => {
    isPassVerificationSheetOpenSubject.next(false);
  },
};

export const OTPVerificationSideSheetStore = {
  update: value => {
    isOTPVerificationSideSheetOpenSubject.next(value);
  },
  subscribe: setState => isOTPVerificationSideSheetOpenSubject.subscribe(setState),
  getValue: () => isOTPVerificationSideSheetOpenSubject.value,
  open: () => {
    isOTPVerificationSideSheetOpenSubject.next(true);
  },
  close: () => {
    isOTPVerificationSideSheetOpenSubject.next(false);
  },
};

export default {
  CompanyAsideSideSheetStore,
  SideSheetStore,
  PickupMethodStore,
  TakeAwayStore,
  DeliveryStore,
  CountryCodeSideSheetStore,
  PostalCodeSideSheetStore,
  AddressMenuSideSheetOpenShown,
  AddressViewSideSheetStore,
  ListAdditionSheetStore,
  InitPickupMethodStore,
  PaymentOptionStore,
  TimesSlotsSideSheetStore,
  PrebookSideSheetStore,
  PointsSideSheetStore,
  GiftsSideSheetStore,
  BundleOfferSideSheetStore,
  ProductsSideSheetStore,
  PassVerificationSideSheetStore,
  OTPVerificationSideSheetStore,
};
