import { setApiConfiguration } from '@desquared/wave-cxm-react';

export function configureApi(headers, authorization, locale) {
  setApiConfiguration({
    baseUrl: process.env.NEXT_PUBLIC_CXM_QUESTIONNAIRE,
    headers: {
      'x-tenant-id': headers[0],
      'x-channel': headers[1],
      authorization: `Bearer ${authorization}`,
    },
    channel: 'Web',
    language: locale,
  });
}
