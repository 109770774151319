import { createContext, useEffect, useState, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { getV3Configuration } from '@/api/configuration';

export const DateFormattingContext = createContext({
  timeFormat: process.env.timeFormat,
  setTimeFormat: val => {},
});

export function DateFormattingProvider({ children }) {
  const { locale } = useRouter();
  const [timeFormat, setTimeFormat] = useState(process.env.timeFormat);

  const { data: dateFormattingData } = useQuery({
    queryKey: ['config', locale],
    queryFn: ({ queryKey }) => getV3Configuration(queryKey[1]),
    select: data => data.format.time,
    staleTime: 1000 * 60 * 60 * 24,
  });

  useEffect(() => {
    if (dateFormattingData) setTimeFormat(dateFormattingData);
  }, [dateFormattingData]);

  return (
    <DateFormattingContext.Provider value={{ timeFormat, setTimeFormat }}>{children}</DateFormattingContext.Provider>
  );
}

export function useDateFormatting() {
  const context = useContext(DateFormattingContext);
  if (!context) {
    throw new Error('useDateFormatting must be used within a DateFormattingProvider');
  }
  return context;
}
