import React, { useEffect, useState } from 'react';
import LoaderStore from '@/store/loader';

import styles from '@/styles/Loader.module.css';

export function Loader() {
  const [isVisible, setIsVisible] = useState(false);
  const [userMessage, setUserMessage] = useState('');

  useEffect(() => {
    const loader$ = LoaderStore.subscribe(state => {
      setIsVisible(state.isVisible);
      setUserMessage(state.userMessage || '');
    });
    return () => {
      loader$.unsubscribe();
    };
  }, []);

  if (isVisible) {
    return (
      <div className={styles.container} data-cy="loader">
        <div className={styles.loader}>Loading...</div>
        <p className={styles.text}>{userMessage}</p>
      </div>
    );
  } else {
    return <></>;
  }
}
