// Given a slugs array returns an array of categories from parent to child [cat1, cat2, cat3]
import { DEFAULT_LOCALE } from '@/constants/locale.js';
import { transformProduct } from './products';
import { getCategoriesHashLocalized, getRootCategoryId } from './categories/categories';
import { isEmpty } from 'lodash';

export function getCategoryMetadata(categoryId, locale = DEFAULT_LOCALE) {
  const categoryHashLocalized = getCategoriesHashLocalized(locale);

  return categoryHashLocalized[categoryId];
}

export function getCategoryPathArrayFromSlug(categories, slugArr) {
  const cat1 = categories.find(i => i.slug === slugArr[0]);
  const cat2 = slugArr?.[1] && cat1?.subCategories ? cat1.subCategories.find(i => i.slug === slugArr[1]) : undefined;
  const cat3 = slugArr?.[2] && cat2?.subCategories ? cat2?.subCategories.find(i => i.slug === slugArr[2]) : undefined;
  return {
    categories: [cat1, cat2, cat3].filter(i => i),
    subCategories: cat3?.subCategories || cat2?.subCategories || [],
  };
}

export function getCategoryDetailsFromSlug(slug, locale) {
  const categories = getCategoriesHashLocalized(locale);
  return (
    Object.entries(categories).find(([key, value]) => {
      return value.fullSlug === `categories/${slug}`;
    })?.[1] || {}
  );
}
export function getCategoryDetailsFromId(id, locale) {
  const categories = getCategoriesHashLocalized(locale);
  return categories[id];
}

// Given an ids array returns an array of categories from parent to child [cat1, cat2, cat3]
export function getCategoryPathArrayFromIds(categories, ids) {
  if (!ids) return;
  const sortedIds = [...ids].reverse();
  const cat1 = categories.find(i => i._id === sortedIds[0]);

  const cat2 = sortedIds?.[1] && cat1?.subCategories ? cat1.subCategories.find(i => i._id === sortedIds[1]) : undefined;

  const cat3 =
    sortedIds?.[2] && cat2?.subCategories ? cat2?.subCategories.find(i => i._id === sortedIds[2]) : undefined;

  return [cat1, cat2, cat3].filter(i => i);
}

export function getSortedSubcategoriesFromTree(categoryTree) {
  return (
    categoryTree.subCategories //sort secondLevel by position
      .sort((a, b) => a.position - b.position)
      //sort thirdLevel by position
      .map(i => {
        return {
          ...i,
          subCategories: i.subCategories ? [...i.subCategories.sort((a, b) => a.position - b.position)] : [],
        };
      })
      .map(i => {
        if (i?.subCategories && i?.subCategories?.length > 0) {
          return i.subCategories;
        } else {
          return i;
        }
      })
      .flat()
  );
}

export function getMainCategoryBasedOnPosition(categoryIds, locale) {
  if (!categoryIds) return null;

  const categoriesArray = categoryIds?.map(i => getCategoryMetadata(i, locale)).filter(i => i);

  if (categoriesArray.length > 0) {
    return categoriesArray.reduce((prev, current) => (prev.position < current.position ? prev : current));
  } else {
    return null;
  }
}

export function getMainCategoryBasedOnSelectedCategoryTree(categoryIds, categoryMenu, locale) {
  if (!categoryIds) return null;

  const categoriesArray = categoryIds?.map(i => getCategoryMetadata(i, locale)).filter(i => i);

  if (!categoryMenu || categoryMenu.length === 1) {
    return getMainCategoryBasedOnPosition(categoryIds, locale);
  }

  const result = categoriesArray
    .map(i => {
      return { ...i, categoryIndexInTree: categoryMenu.findIndex(c => c?._id === i._id) };
    })
    .filter(i => i.categoryIndexInTree !== -1)
    .reduce((prev, current) => (prev && prev.categoryIndexInTree < current.categoryIndexInTree ? prev : current), {});

  if (Object.keys(result).length === 0) {
    return categoriesArray.find(i => categoryMenu.findIndex(c => c?._id === i._id) === -1);
  }

  return result;
}

export function getProductsWithCategory(products, categoryMenu, tags, bundleOffersSummary, locale, options) {
  if (!products) return [];
  return products.map(i => {
    const category = getMainCategoryBasedOnSelectedCategoryTree(i.categoryIds, categoryMenu, locale);
    const categoryToUse = options?.groupByRootCategory ? category?.parents?.[0] || category : category;
    return {
      ...transformProduct(i, undefined, undefined, tags, bundleOffersSummary),
      category:
        options?.showSecondLevelCategory && categoryToUse?.parents && categoryToUse?.parents.length >= 2
          ? `${categoryToUse?.parents[1].name} / ${categoryToUse?.name}`
          : categoryToUse?.name,
    };
  });
}

export function getProductsWithEmptyCategory(products, tags, bundleOffersSummary) {
  if (!products) return [];
  return products.map(i => {
    return {
      ...transformProduct(i, undefined, undefined, tags, bundleOffersSummary),
      category: '',
    };
  });
}

export function getProductsWithFixedCategory(
  products,
  categoriesFromFile,
  tags,
  bundleOffersSummary,
  locale,
  categoryName
) {
  if (!products) return [];
  return products.map(i => {
    return {
      ...transformProduct(i, undefined, undefined, tags, bundleOffersSummary),
      category: categoryName,
    };
  });
}
export function getProductsWithMainCategory(products, categoriesFromFile, tags, bundleOffersSummary) {
  if (!products) return [];
  return products.data.map(i => {
    const categoryTree = getCategoryPathArrayFromIds(categoriesFromFile, i?.categories);

    return {
      ...transformProduct(i, undefined, undefined, tags, bundleOffersSummary),
      category: categoryTree?.[0]?.name,
    };
  });
}

export function getCategoryNameForGroup(categories, currentCategory) {
  const category = categories.find(id => id === currentCategory._id);
  return category ? currentCategory.name : null;
}

export function groupProductsByCategory(products, currentCategory) {
  return products.reduce((prev, curr) => {
    if (currentCategory) {
      const cat = getCategoryNameForGroup(curr.categories, currentCategory);
      curr.category = cat;
    }
    const existingCategory = prev.find(i => i.category === curr.category);
    if (existingCategory) {
      return prev.map(i => {
        if (i.category === curr.category) {
          return { ...i, products: [...i.products, { ...curr }] };
        } else {
          return i;
        }
      });
    } else {
      return [
        ...prev,
        {
          category: curr.category,
          products: [curr],
        },
      ];
    }
  }, []);
}

export function groupProductsByCategoryCart(products) {
  if (!Array.isArray(products) || products?.length === 0) {
    // Handle the case where products is not an array or is empty.
    return [];
  }
  return products.reduce((accumulator, currentProduct) => {
    const existingCategory = accumulator.find(
      category => getRootCategoryId(category?.categoriesIds[0]) === getRootCategoryId(currentProduct?.categories[0])
    );
    if (existingCategory) {
      return accumulator.map(category => {
        if (getRootCategoryId(category.categoriesIds[0]) === getRootCategoryId(currentProduct.categories[0])) {
          return { ...category, products: [...category.products, { ...currentProduct }] };
        } else {
          return category;
        }
      });
    } else {
      return [
        ...accumulator,
        {
          categoriesIds: currentProduct.categories,
          products: [currentProduct],
        },
      ];
    }
  }, []);
}

export function groupOrderProductsByCategory(products, locale) {
  if (!products || products?.length === 0) return [];

  return products.reduce((prev, curr) => {
    const currentCategoryId = curr.categories[0];
    const currentCategory = getCategoryMetadata(currentCategoryId, locale);
    const currentCategoryRoot = currentCategory?.parents?.[0] || currentCategory;
    const existingCategory = prev.find(i => i.title === currentCategoryRoot?.name);
    if (existingCategory) {
      return prev.map(i => {
        if (i.title === currentCategoryRoot.name) {
          return { ...i, items: [...i.items, { ...curr }] };
        } else {
          return i;
        }
      });
    } else {
      return [
        ...prev,
        {
          title: currentCategoryRoot?.name || '',
          items: [curr],
        },
      ];
    }
  }, []);
}

export function createCategoriesForBundle(fullCategories, firstSelectedCategory) {
  const categoriesForBundles = [];
  fullCategories.map(category => {
    let newCategory = {
      text: category.text,
      active: firstSelectedCategory && category._id === firstSelectedCategory,
      id: category._id,
      slug: `${category.slug}`,
    };
    categoriesForBundles.push(newCategory);
  });
  // categoriesForBundles.unshift({ text: 'Όλες', active: true, id: '0' });
  return categoriesForBundles;
}

export function sortByPosition(arr) {
  arr.sort((a, b) => a?.position - b?.position);

  return arr;
}

export function sortByIsFeaturedAndCategoryPosition(arr) {
  const featured = arr.filter(entry => entry.category.isFeatured);
  const notFeatured = arr.filter(entry => !entry.category.isFeatured);

  featured.sort((a, b) => a.category?.position - b.category?.position);
  notFeatured.sort((a, b) => a.category?.position - b.category?.position);

  return featured.concat(notFeatured);
}
