import categoriesHash from '../../../prebuild-data/category-hash-table.json';
import availableLanguages from '../../../prebuild-data/available-languages.json';

export const getCategoriesHashLocalized = locale => categoriesHash[locale || availableLanguages.default || 'el'];

export const getRootCategoryName = (id, locale) => {
  const data = getCategoriesHashLocalized(locale);

  try {
    if (data[id]) {
      if (data[id].parentCategoryIds?.length > 0) {
        return getRootCategoryName(data[id].parentCategoryIds[0], locale);
      } else {
        return data[id].name;
      }
    } else {
      return null; // ID not found in the JSON
    }
  } catch (error) {
    return null; // Error reading or parsing the JSON file
  }
};
export const getRootCategoryId = (id, locale) => {
  const data = getCategoriesHashLocalized(locale);
  try {
    if (data[id]) {
      if (data[id].parentCategoryIds?.length > 0) {
        return getRootCategoryName(data[id].parentCategoryIds[0]);
      } else {
        return data[id].id;
      }
    } else {
      return null; // ID not found in the JSON
    }
  } catch (error) {
    return null; // Error reading or parsing the JSON file
  }
};

export const getCategoryNameFromFile = (id, locale) => {
  const data = getCategoriesHashLocalized(locale);

  try {
    if (data[id]) {
      return data[id].name;
    } else {
      return null; // ID not found in the JSON
    }
  } catch (error) {
    return null; // Error reading or parsing the JSON file
  }
};
