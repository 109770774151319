import { DEFAULT_LOCALE } from '@/constants/locale.js';
import { wave } from './config';

export async function getV3Configuration() {
  try {
    const response = await wave.get('v3/configurations');
    return response.data;
  } catch (error) {
    return { error };
  }
}

export async function getV3DeliveryServicesConfiguration({ enabled }) {
  try {
    const response = await wave.get('v3/configurations/delivery-services', {
      params: { enabled },
    });
    return response.data;
  } catch (error) {
    return [];
  }
}
