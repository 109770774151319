import { BehaviorSubject } from 'rxjs';

const subjectConfigurationFetched = new BehaviorSubject(false);

const ConfigurationFetched = {
  update: fetched => {
    subjectConfigurationFetched.next(fetched);
  },
};

const ConfigurationStore = {
  subject: new BehaviorSubject(),
  update: config => {
    ConfigurationStore.subject.next(config);
    ConfigurationFetched.update(true);
  },
  subscribe: setState => ConfigurationStore.subject.subscribe(setState),
  getValue: () => ConfigurationStore.subject.value,
  isFetched: () => subjectConfigurationFetched.value,
};

export default ConfigurationStore;
