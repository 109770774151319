import React, { createContext, useContext, useState, useCallback } from 'react';
import { SnackBar } from '@desquared/wave-components-library';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState({
    message: '',
    type: '',
    visible: false,
  });

  //   TODO: Add different toast types
  const showToast = useCallback((message, type = 'info') => {
    setToast({ message, type, visible: true });

    setTimeout(() => {
      setToast(prev => ({ ...prev, visible: false }));
    }, 3000);
  }, []);

  const hideToast = useCallback(() => {
    setToast(prev => ({ ...prev, visible: false }));
  }, []);

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      <SnackBar open={toast.visible} setOpen={hideToast} text={toast.message} position={'center'} />
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
