import { BehaviorSubject } from 'rxjs';

import jsonStorage from '../utils/json-storage';

export const LoginProviderStore = {
  subject: null,

  _lazyInit() {
    if (LoginProviderStore.subject) return LoginProviderStore.subject;
    if (window?.localStorage) {
      const storage = window.localStorage;
      const loginProvider = jsonStorage.get('loginProvider', { storage }) || 'none';
      LoginProviderStore.subject = new BehaviorSubject(loginProvider);
      return LoginProviderStore.subject;
    }
  },
  update: value => {
    const loginProvider = LoginProviderStore._lazyInit();
    loginProvider.next(value);
    const storage = window.localStorage;
    jsonStorage.set('loginProvider', value, { storage });
  },
  subscribe: setState => {
    const loginProvider = LoginProviderStore._lazyInit();
    return loginProvider.subscribe(setState);
  },
  getValue: () => {
    const loginProvider = LoginProviderStore._lazyInit();
    return loginProvider.value;
  },
  clear: () => {
    LoginProviderStore.update(null);
    window.localStorage.removeItem('loginProvider');
  },
};

export default { LoginProviderStore };
