const GENERIC_ERROR_MODAL_CONTENT = {
  title: 'Κάτι δε λειτούργησε σωστά',
  text: 'Όταν η ζωή σου φαίνεται σαν να μη λειτουργεί, πάρε μια βαθυά αναπνοή και κάνε refresh! Ο κόσμος είναι σαν τον browser - μερικές φορές χρειάζεται ένα απλό F5 για να βρεις τον πιο φρέσκο εαυτό σου.',
  img: {
    ariaLabel: 'This is a happy orange image',
    size: 'large',
    url: `${process.env.NEXT_PUBLIC_HOSTNAME}/assets/wave-grocery/HappyOrange.png`,
  },
  actions: [
    {
      btnLabel: 'Ανανέωση Σελίδας',
      onClick: () => location.reload(),
      variant: 'filled',
    },
  ],
};

export default GENERIC_ERROR_MODAL_CONTENT;
