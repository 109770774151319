import { LabelEnum } from '@desquared/wave-components-library';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import utc from 'dayjs/plugin/utc';
import 'dayjs/locale/el';
import 'dayjs/locale/en';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(isBetween);
dayjs.extend(isToday);
dayjs.extend(utc);
dayjs.locale('el');
dayjs.extend(timezone);

let DEFAULT_TZ = dayjs.tz.guess();

export const CLOSING_SOON_THRESHOLD_IN_MINUTES = 30;

//returns the number of the current day starting with sunday as zero
export const getCurrentDay = () => {
  return dayjs().tz().day();
};

//times are given in HH:mm format
export const isBetweenTimes = (startTime, endTime) => {
  //first create a current date with the given times
  const startTimeArr = startTime.split(':');
  const endTimeArr = endTime.split(':');
  const startDate = dayjs().tz().hour(startTimeArr[0]).minute(startTimeArr[1]);
  const endDate = dayjs().tz().hour(endTimeArr[0]).minute(endTimeArr[1]);

  return dayjs().tz().isBetween(startDate, endDate, 'minute', '[]');
};

//dates are dayjs dates
export const getTimeDifference = (startDate, endDate) => {
  return endDate.diff(startDate, 'minutes');
};

export const getWorkingHoursInfo = (startTime, endTime) => {
  if (!startTime || !endTime)
    return {
      status: LabelEnum.Close,
      hours: undefined,
    };

  const endTimeArr = endTime.split(':');
  const endDate = dayjs().tz().hour(endTimeArr[0]).minute(endTimeArr[1]);

  const isOpen = isBetweenTimes(startTime, endTime);
  const isClosingSoon =
    0 < getTimeDifference(dayjs().tz(), endDate) &&
    getTimeDifference(dayjs().tz(), endDate) < CLOSING_SOON_THRESHOLD_IN_MINUTES;

  let status = isOpen ? LabelEnum.Open : LabelEnum.Close;

  if (isClosingSoon) status = LabelEnum.Warning;

  return {
    status,
    hours: `${startTime}-${endTime}`,
  };
};

function toMilitaryTime(time) {
  if (/AM|PM/i.test(time)) {
    const [hours, minutes] = time.split(':');
    const period = time.match(/AM|PM/i)[0].toUpperCase();
    let militaryHours = parseInt(hours, 10);

    if (period === 'PM' && militaryHours !== 12) {
      militaryHours += 12;
    } else if (period === 'AM' && militaryHours === 12) {
      militaryHours = 0;
    }

    return `${String(militaryHours).padStart(2, '0')}:${minutes.replace(/AM|PM/i, '').trim()}`;
  }

  return time;
}

export function formatDateTime(dateString, timeString) {
  // Split the date and extract day and month
  if (!dateString || !timeString) return;
  const [day, month] = dateString.split('/').map(Number);

  // Extract start and end times
  const [startTime, endTime] = timeString.split('-').map(time => toMilitaryTime(time.trim()));

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Create date objects for the start and end times
  // const startDate = new Date(currentYear, month - 1, day, ...startTime.split(':'));
  // const endDate = new Date(currentYear, month - 1, day, ...endTime.split(':'));

  // // Format the dates as strings in the expected format
  // const formattedStartDate = dayjs(startDate).tz().toISOString(); // Remove milliseconds and add 'Z'
  // const formattedEndDate = dayjs(endDate).tz().toISOString(); // Remove milliseconds and add 'Z'

  const formattedStartDate = dayjs(new Date())
    .tz()
    .date(day)
    .year(currentYear)
    .month(month - 1)
    .hour(Number(startTime.split(':')[0]))
    .minute(Number(startTime.split(':')[1]))
    .second(0)
    .millisecond(0)
    .toISOString();

  const formattedEndDate = dayjs(new Date())
    .tz()
    .date(day)
    .year(currentYear)
    .month(month - 1)
    .hour(Number(endTime.split(':')[0]))
    .minute(Number(endTime.split(':')[1]))
    .second(0)
    .millisecond(0)
    .toISOString();

  // Return the array of formatted date strings
  return [formattedStartDate, formattedEndDate];
}

function parseDate(date) {
  // Detect format and parse date
  if (date.includes('T')) {
    // ISO date
    return dayjs(date).tz();
  } else {
    // Assume YYYY-MM-DD and treat as the start of the day in the given timezone
    return dayjs.tz(date, 'YYYY-MM-DD', DEFAULT_TZ);
  }
}

export function getDifferenceInMins(start, end) {
  return dayjs(end).tz().diff(dayjs(start).tz(), 'minutes');
}

export function getCurrentDayTitle(date, locale, todayToken) {
  const parsedDate = parseDate(date);

  if (parsedDate.isToday()) {
    return todayToken;
  }

  return parsedDate.locale(locale).format('dddd');
}

export function formatPrebookDate(date, formatType) {
  const defaultFormat = process.env.dateFormat === 'MM/DD/YYYY' ? 'MM/DD' : 'DD/MM';
  const parsedDate = parseDate(date);

  return parsedDate.format(formatType || defaultFormat);
}

export function formatUTCTimeslotsToLocal(timeSlotsWithLabelsInUTC, timeFormat = process.env.timeFormat) {
  if (!timeSlotsWithLabelsInUTC || timeSlotsWithLabelsInUTC.length === 0) return [];

  return timeSlotsWithLabelsInUTC.map(day => {
    // normaly checking the first date in timeslots is a better way of getting the correct day given we have different timezone
    //we are useing the date the BE sends only in case of empty titmeslots
    const date = day.timeslots?.[0]?.startsAtDate || dayjs.tz(day.date, DEFAULT_TZ).toISOString();

    return {
      date: dayjs(date).tz(DEFAULT_TZ).format('YYYY-MM-DD'),
      timeslots: day.timeslots.map(timeslot => {
        return {
          ...timeslot,
          startsAt: dayjs(timeslot.startsAtDate).tz(DEFAULT_TZ).format(timeFormat),
          endsAt: dayjs(timeslot.endsAtDate).tz(DEFAULT_TZ).format(timeFormat),
        };
      }),
    };
  });
}

export function getTimezone() {
  return DEFAULT_TZ;
}

export function setDefaultTz(tz) {
  DEFAULT_TZ = tz;
  dayjs.tz.setDefault(DEFAULT_TZ);
}

export function setDayJsLocale(locale) {
  dayjs.locale(locale);
}
/**
 * IMPORTANT:
 * dayjs is now a single instance so that we can have consistent timezones,
 * So anytime we import dayjs and it has to do with formatting dates according to user or store time
 * we should do it from here.
 *
 * This should be a hook, but we are using dayjs outside of react's context alot
 * so this will have to do.
 */
export { dayjs };
