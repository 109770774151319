import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

export default function useWindowUnderThreshold(threshold) {
  const [isUnderThreshold, setIsUnderThreshold] = useState(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    // Function to update the state immediately
    const checkWindowSize = () => {
      setIsUnderThreshold(window.innerWidth < threshold);
    };

    // Check the window size immediately on mount
    checkWindowSize();

    // Throttled function for handling window resize
    const handleResize = throttle(checkWindowSize, 200);

    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [threshold]);

  return isUnderThreshold;
}
