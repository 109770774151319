import UserListsStore from './../../src/store/userLists';
import Cookies from 'universal-cookie';
import isEmpty from 'lodash/isEmpty';

const cookies = new Cookies();
const authorization = cookies.get('token');

const localCollectionTypes = [process.env.defaultCollectionType];

export function filterObjectsByCollectionType(objects) {
  const filteredObjects = objects.filter(obj => localCollectionTypes.includes(obj.collectionType));
  return filteredObjects;
}

export function filterObjectsByUniqueSKU(objects, collectionTypes) {
  if (!objects) return [];
  const seenSKUs = new Set();
  const uniqueObjects = [];
  for (const obj of objects) {
    if (!seenSKUs.has(obj.sku)) {
      uniqueObjects.push(obj);
      seenSKUs.add(obj.sku);
    } else if (hasCommonCollection(obj.collectionTypes, collectionTypes)) {
      const index = uniqueObjects.findIndex(existingObj => existingObj.sku === obj.sku);
      if (index !== -1) {
        uniqueObjects[index] = obj;
      }
    }
  }

  return uniqueObjects;
}

export function filterHistoryObjectsByUniqueSKU(objects, collectionTypes) {
  if (!objects) return [];

  const uniqueProducts = [];

  for (const obj of objects) {
    const existingIndex = uniqueProducts.findIndex(
      existingObj =>
        existingObj.definition.sku === obj.definition.sku &&
        compareOptions(existingObj.options, obj.options) &&
        hasCommonCollection(existingObj.definition.collectionTypes, collectionTypes)
    );

    if (existingIndex !== -1) {
      // If an existing object with the same SKU and options is found
      uniqueProducts[existingIndex] = obj; // Replace it with the new object
    } else {
      // Otherwise, add the new object to the list
      uniqueProducts.push(obj);
    }
  }

  return uniqueProducts;
}

// Function to compare options arrays
function compareOptions(options1, options2) {
  if (options1 === options2) return true; // If both are the same object reference
  if (!options1 || !options2) return false; // If either one is null or undefined

  if (options1.length !== options2.length) return false; // If they have different lengths

  // Sort options arrays before comparison to ensure order doesn't matter
  const sortedOptions1 = JSON.stringify(options1.map(option => JSON.stringify(option))).split(",").sort().join(",");
  const sortedOptions2 = JSON.stringify(options2.map(option => JSON.stringify(option))).split(",").sort().join(",");

  return sortedOptions1 === sortedOptions2;
}


export function removeDuplicatesByName(objects) {
  const seenIds = new Set(); // Store unique _id values
  const uniqueObjects = [];
  for (const obj of objects) {
    if (!seenIds.has(obj.displayName)) {
      seenIds.add(obj.displayName);
      uniqueObjects.push(obj);
    }
  }

  return uniqueObjects;
}

export function checkListStatus(product) {
  let status = 'noList';

  if (isEmpty(authorization)) {
    status = 'noList';
    return;
  }
  const lists = UserListsStore.getValue();
  if (!lists || lists.length === 0) {
    status = 'noList';
    return;
  }
  let found = false;

  lists.forEach(list => {
    if (found) return;

    list.products.forEach((prod, index) => {
      if (prod.sku === product.sku && !found) {
        found = true;
        if (list.label === 'Αγαπημένα') {
          status = 'favourite';
        } else if (list.label === 'suggested') {
          status = 'smart';
        } else {
          status = 'edit';
        }
      }
    });
  });
  return status;
}
export const checkListForProduct = (listID, lists, product) => {
  const list = lists.find(v => v._id === listID)?.products || [];
  const isInList = list.some(f => f.sku === product.sku);
  return isInList;
};

export function hasCommonCollection(array1, array2) {
  if (!array1 || !array2) return false;
  for (const element of array1) {
    if (array2.includes(element)) {
      return true; // Found a common element
    }
  }
  return false; // No common element found
}
