import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import jsonStorage from '../utils/json-storage';

const subjectProductsRemovedFromCart = new BehaviorSubject([]);
const subjectProductsNotRemovedFromCart = new BehaviorSubject([]);

const subjectCartVolume = new BehaviorSubject(0);
const subjectShowCartFullMsg = new BehaviorSubject(false);
const subjectCartPrice = new BehaviorSubject(0);
const subjectValuesToBeUpdated = new BehaviorSubject({});
const subjectEditMode = new BehaviorSubject('');
const subjectFullEditMode = new BehaviorSubject(false);
const subjectUpdateCartTimer = new BehaviorSubject(false);

const INITIAL_VALUE = Object.freeze({
  isVisible: false,
  isEditable: true,
  shouldOpen: true,
});

const Cart = {
  subject: new BehaviorSubject(INITIAL_VALUE),
  updatedAt: undefined,

  update(value) {
    Cart.subject.next(value);
    Cart.updatedAt = new Date();
  },

  deltaUpdate(updateObject) {
    const currentState = Cart.subject.value;
    Cart.subject.next({
      ...currentState,
      ...updateObject,
    });
  },

  /**
   * Updates cart according to visiting url.
   * @param {String} relativeUrl - pathname & query params
   */
  onRouteChangeComplete(relativeUrl) {
    const isCheckoutPage = ['/order-delivery'].some(u => relativeUrl.startsWith(u));
    const isFullPageCart = ['/cart'].find(u => relativeUrl.startsWith(u));
    if (isFullPageCart) {
      Cart.setIsVisible(false);
      return;
    }
    if (!isCheckoutPage) {
      Cart.setIsEditable(true);
    }
  },

  getIsVisble$() {
    return Cart.subject.pipe(map(cart => cart.isVisible));
  },

  getIsEditable$() {
    return Cart.subject.pipe(map(cart => cart.isEditable));
  },

  getIsModalOpen$() {
    return Cart.subject.pipe(map(cart => cart.isModalOpen));
  },

  setIsVisible(value) {
    Cart.deltaUpdate({ isVisible: value });
  },

  setIsEditable(value) {
    Cart.deltaUpdate({ isEditable: value });
  },

  setIsModalOpen(value) {
    Cart.deltaUpdate({ isModalOpen: value });
  },

  toggleVisibility() {
    const newState = !Cart.subject.value.isVisible;
    Cart.setIsVisible(newState);
  },

  reset() {
    productsForOrder.update([]);
    productsQuantities.update({});
    Cart.subject.next(INITIAL_VALUE);
  },
};

export const productsForOrder = {
  subject: undefined,

  lazyInit() {
    if (productsForOrder.subject) return productsForOrder.subject;
    if (typeof window !== 'undefined') {
      const storage = window.localStorage;
      const products = jsonStorage.get('productForOrder', { storage }) || [];

      productsForOrder.subject = new BehaviorSubject(products);
      return productsForOrder.subject;
    }
  },

  update: value => {
    const products = productsForOrder.lazyInit();
    products.next(value);
    const storage = window.localStorage;
    jsonStorage.set('productForOrder', value, { storage });

    // const tempQuantities = productsForOrder.getValue()?.reduce((cartProducts, cartProduct) => {
    //   const newQuantity = (cartProducts[cartProduct._id] || 0) + 1;
    //   const cartProductsQuantities = {
    //     ...cartProducts,
    //     [cartProduct._id]: newQuantity,
    //   };
    //   return cartProductsQuantities;
    // }, {});

    // productsQuantities.update(tempQuantities);
  },
  getStorageValue: () => {
    const storage = window.localStorage;
    return jsonStorage.get('productForOrder', { storage });
  },
  subscribe: setState => {
    const products = productsForOrder.lazyInit();
    return products.subscribe(setState);
  },
  getValue: () => {
    const products = productsForOrder.lazyInit();
    return products?.value;
  },
};
export const fullCartStore = {
  subject: undefined,

  lazyInit() {
    if (fullCartStore.subject) return fullCartStore.subject;
    if (typeof window !== 'undefined') {
      const storage = window.localStorage;
      const products = jsonStorage.get('cart', { storage }) || [];

      fullCartStore.subject = new BehaviorSubject(products);
      return fullCartStore.subject;
    }
  },

  update: value => {
    const products = fullCartStore.lazyInit();
    products.next(value);
    const storage = window.localStorage;
    jsonStorage.set('cart', value, { storage });
  },
  getStorageValue: () => {
    const storage = window.localStorage;
    return jsonStorage.get('cart', { storage });
  },
  subscribe: setState => {
    const products = fullCartStore.lazyInit();
    return products.subscribe(setState);
  },
  getValue: () => {
    const products = fullCartStore.lazyInit();
    return products?.value ? products.value : [];
  },
};
export const checkoutStore = {
  subject: undefined,

  lazyInit() {
    if (checkoutStore.subject) return checkoutStore.subject;
    if (typeof window !== 'undefined') {
      const storage = window.localStorage;
      const products = jsonStorage.get('checkout', { storage }) || [];

      checkoutStore.subject = new BehaviorSubject(products);
      return checkoutStore.subject;
    }
  },

  update: value => {
    const products = checkoutStore.lazyInit();
    products.next(value);
    const storage = window.localStorage;
    jsonStorage.set('checkout', value, { storage });
  },
  subscribe: setState => {
    const products = checkoutStore.lazyInit();
    return products.subscribe(setState);
  },
  getValue: () => {
    const products = checkoutStore.lazyInit();
    return products?.value ? products.value : [];
  },
};
export const productsToKeep = {
  subject: undefined,

  lazyInit() {
    if (productsToKeep.subject) return productsToKeep.subject;
    if (typeof window !== 'undefined') {
      const storage = window.localStorage;
      const products = jsonStorage.get('productToKeep', { storage }) || [];

      productsToKeep.subject = new BehaviorSubject(products);
      return productsToKeep.subject;
    }
  },

  update: () => {
    const products = productsToKeep.lazyInit();
    const storage = window.localStorage;
    jsonStorage.set('productToKeep', productsForOrder.getValue(), { storage });
    products.next(productsForOrder.getValue());
    productsForOrder.update([]);
  },
  release: () => {
    const products = productsToKeep.lazyInit();
    productsForOrder.update(productsToKeep.getValue());
    products.next([]);

    const totalPrice = productsForOrder.getValue().reduce((total, obj) => {
      if (obj.hasOwnProperty('price')) {
        const price = parseFloat(obj.price);
        if (!isNaN(price)) {
          total += price;
        }
      }
      return total;
    }, 0);
    cartPrice.update(totalPrice.toFixed(2));
  },
  getValue: () => {
    const products = productsToKeep.lazyInit();
    return products?.value ? products.value : [];
  },
};
export const isEditStarted = {
  subject: undefined,

  lazyInit() {
    if (isEditStarted.subject) return isEditStarted.subject;
    if (typeof window !== 'undefined') {
      const storage = window.localStorage;
      const isOnEdit = jsonStorage.get('isOnEdit', { storage }) || false;

      isEditStarted.subject = new BehaviorSubject(isOnEdit);
      return isEditStarted.subject;
    }
  },

  update: isEdit => {
    const isEditSubject = isEditStarted.lazyInit();
    const storage = window.localStorage;
    jsonStorage.set('isOnEdit', isEdit, { storage });
    isEditSubject.next(isEdit);
  },
  getValue: () => {
    const isEdit = isEditStarted.lazyInit();
    return isEdit?.value;
  },
};

export const productsQuantities = {
  subject: undefined,

  lazyInit() {
    if (productsQuantities.subject) return productsQuantities.subject;
    const storage = window.localStorage;
    const quantities = jsonStorage.get('productQuantities', { storage }) || {};
    productsQuantities.subject = new BehaviorSubject(quantities);
    return productsQuantities.subject;
  },

  update: value => {
    const quantities = productsQuantities.lazyInit();
    quantities.next(value);
    const storage = window.localStorage;
    jsonStorage.set('productQuantities', value, { storage });
  },

  subscribe: setState => {
    const quantities = productsQuantities.lazyInit();
    return quantities.subscribe(setState);
  },
  getValue: () => {
    const quantities = productsQuantities.lazyInit();
    return quantities.value;
  },
};

export const ProductsRemovedFromCart = {
  update: value => {
    subjectProductsRemovedFromCart.next(value);
  },
  subscribe: setState => subjectProductsRemovedFromCart.subscribe(setState),
  getValue: () => subjectProductsRemovedFromCart.value,
};
export const EditModeCart = {
  update: value => {
    subjectEditMode.next(value);
  },
  subscribe: setState => subjectEditMode.subscribe(setState),
  getValue: () => subjectEditMode.value,
};
export const FullModeEdit = {
  update: value => {
    subjectFullEditMode.next(value);
  },
  subscribe: setState => subjectFullEditMode.subscribe(setState),
  getValue: () => subjectFullEditMode.value,
};

export const ProductsNotRemovedFromCart = {
  update: value => {
    subjectProductsNotRemovedFromCart.next(value);
  },
  subscribe: setState => subjectProductsNotRemovedFromCart.subscribe(setState),
  getValue: () => subjectProductsNotRemovedFromCart.value,
};

export const cartVolume = {
  update: value => {
    subjectCartVolume.next(value);
  },
  subscribe: setState => subjectCartVolume.subscribe(setState),
  getValue: () => subjectCartVolume.value,
};

export const cartPrice = {
  subject: subjectCartPrice,

  update: value => {
    subjectCartPrice.next(value);
  },
  subscribe: setState => subjectCartPrice.subscribe(setState),
  getValue: () => subjectCartPrice.value,
};

export const showCartFullMsg = {
  update: value => {
    subjectShowCartFullMsg.next(value);
  },
  subscribe: setState => subjectShowCartFullMsg.subscribe(setState),
  getValue: () => subjectShowCartFullMsg.value,
};

export const ValuesToBeUpdated = {
  updateService: value => {
    const values = ValuesToBeUpdated.getValue();
    subjectValuesToBeUpdated.next({ ...values, service: value });
  },
  updateAddress: value => {
    const values = ValuesToBeUpdated.getValue();
    subjectValuesToBeUpdated.next({ ...values, address: value });
  },
  updateShop: value => {
    const values = ValuesToBeUpdated.getValue();
    subjectValuesToBeUpdated.next({ ...values, shop: value });
  },
  updatePostalCode: value => {
    const values = ValuesToBeUpdated.getValue();
    subjectValuesToBeUpdated.next({ ...values, postalCode: value });
  },
  subscribe: setState => subjectValuesToBeUpdated.subscribe(setState),
  getValue: () => subjectValuesToBeUpdated.value,
  clearValues: () => {
    subjectValuesToBeUpdated.next({});
  },
};

export const updateCartTimer = {
  update: value => {
    subjectUpdateCartTimer.next(value);
  },
  subscribe: setState => subjectUpdateCartTimer.subscribe(setState),
  getValue: () => subjectUpdateCartTimer.value,
};

export default Cart;
