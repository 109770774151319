import { useState, useEffect } from 'react';

export function useSessionId() {
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    let session = getSessionIdFromCookie();

    // If no session exists, generate a new one
    if (!session) {
      session = generateSessionId();
      saveSessionIdToCookie(session);
    }

    setSessionId(session);
  }, []);

  function generateSessionId() {
    const array = new Uint8Array(32);
    window.crypto.getRandomValues(array);
    return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
  }

  function saveSessionIdToCookie(sessionId) {
    const expires = new Date(Date.now() + 24 * 60 * 60 * 1000).toUTCString(); // 1 day
    document.cookie = `session_id=${sessionId}; path=/; Secure; SameSite=Strict; expires=${expires}`;
  }

  function getSessionIdFromCookie() {
    const cookies = document.cookie.split('; ');
    const sessionCookie = cookies.find(cookie => cookie.startsWith('session_id='));
    return sessionCookie ? sessionCookie.split('=')[1] : null;
  }

  return sessionId;
}
