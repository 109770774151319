import { BehaviorSubject } from 'rxjs';
import jsonStorage from '../utils/json-storage';

export const SelectedBundleOfferStore = {
  subject: undefined,

  _lazyInit() {
    if (SelectedBundleOfferStore.subject) return SelectedBundleOfferStore.subject;
    if (typeof window !== 'undefined' && window?.localStorage) {
      const storage = window.localStorage;
      const message = jsonStorage.get('selectedBundleOffer', { storage }) || {};
      SelectedBundleOfferStore.subject = new BehaviorSubject(message);
      return SelectedBundleOfferStore.subject;
    }
  },
  update: product => {
    const message = SelectedBundleOfferStore._lazyInit();
    message.next(product);
    const storage = window.localStorage;
    jsonStorage.set('selectedBundleOffer', product, { storage });
  },
  subscribe: setState => {
    const message = SelectedBundleOfferStore._lazyInit();
    const subscription = message.subscribe(setState);

    // Return the subscription object
    return {
      unsubscribe: () => subscription.unsubscribe(),
    };
  },
  getValue: () => {
    const message = SelectedBundleOfferStore._lazyInit();
    return message?.value;
  },
};

export default { SelectedBundleOfferStore };
