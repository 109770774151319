import get from 'lodash/get';

const DEFAULT_ERROR_MESSAGE_FALLBACK = 'Undefined Error';
const DEFAULT_ERROR_MESSAGE = get(process, 'env.DEFAULT_ERROR_MESSAGE', DEFAULT_ERROR_MESSAGE_FALLBACK);

export function parseError(err) {
  const waveError = get(err, 'response.data.error.UserMessage');
  if (waveError) {
    console.error('Wave Error:', JSON.stringify(waveError, null, 2));
    return waveError;
  }

  console.error('Undefined Error:', JSON.stringify(err, null, 2));
  return get(err, 'message', DEFAULT_ERROR_MESSAGE);
}
