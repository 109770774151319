import { useRouter } from 'next/router';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getV3Configuration } from '@/api/configuration';

export const CurrencyContext = createContext({
  currency: 'EUR',
  formatter: new Intl.NumberFormat('el-GR', { currency: 'EUR' }),
  setCurrency: val => {},
});

export function CurrencyProvider({ children }) {
  const { locale } = useRouter();
  const [currency, setCurrency] = useState('EUR');

  const { data: currencyConfig } = useQuery({
    queryKey: ['config', locale],
    queryFn: ({ queryKey }) => getV3Configuration(queryKey[1]),
    select: data => {
      return data.currency;
    },
    staleTime: 1000 * 60 * 60 * 24,
  });

  useEffect(() => {
    if (currencyConfig) setCurrency(currencyConfig.default);
  }, [currencyConfig]);

  const formatter = useMemo(() => {
    return new Intl.NumberFormat(locale, { currency: currency, style: 'currency' });
  }, [currency, locale]);

  return <CurrencyContext.Provider value={{ currency, formatter }}>{children}</CurrencyContext.Provider>;
}
