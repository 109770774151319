import { useEffect, useState, createContext, useCallback } from 'react';
import Modal from 'react-modal';
import isBoolean from 'lodash/isBoolean';

import { ActionModal, ErrorModal } from '@desquared/wave-components-library';
import styles from '@/styles/User.module.css';

import CUSTOM_STYLES from '@/utils/modalStyle';
import GENERIC_ERROR_MODAL_CONTENT from '@/constants/errorModalDefaults';
import ErrorBoundary from './ErrorBoundary';

export const ModalContext = createContext({
  openGenericErrorModal: undefined,
  openErrorModal: undefined,
  openActionModal: undefined,
  shown: undefined,
  close: undefined,
});

export function ModalProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState({ style: CUSTOM_STYLES });
  const [type, setType] = useState('error');

  const openGenericErrorModal = useCallback(() => {
    setType('error');
    setContent(prev => ({
      ...prev,
      ...GENERIC_ERROR_MODAL_CONTENT,
    }));
  }, [setType, setContent]);

  const openErrorModal = useCallback(
    content => {
      setType('error');
      setContent(prev => ({ ...prev, ...content }));
    },
    [setType, setContent]
  );

  const openActionModal = useCallback(
    content => {
      setType('action');
      setContent(prev => ({ ...prev, ...content }));
    },
    [setType, setContent]
  );

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  useEffect(() => {
    if (content && content.text) setOpen(true);
  }, [content]);
  return (
    <ModalContext.Provider
      value={{
        openGenericErrorModal,
        openErrorModal,
        openActionModal,
        shown: open,
        close,
      }}
    >
      <ErrorBoundary onError={openGenericErrorModal}>
        {children}

        <Modal
          isOpen={open}
          onRequestClose={() => setOpen(false)}
          shouldCloseOnEsc={isBoolean(content?.closeOnOuterClick) ? content?.closeOnOuterClick : true}
          shouldCloseOnOverlayClick={isBoolean(content?.closeOnOuterClick) ? content?.closeOnOuterClick : true}
          style={content.style}
          ariaHideApp={false}
          onAfterClose={content.onClose || undefined}
          className={styles.modalContainer}
        >
          {type === 'error' && (
            <ErrorModal title={content.title} text={content.text} actions={content.actions} img={content.img} />
          )}
          {type === 'action' && (
            <ActionModal
              hasCenteredText={content.hasCenteredText}
              title={content.title}
              text={content.text}
              actions={content.actions}
              img={content.img}
            />
          )}
        </Modal>
      </ErrorBoundary>
    </ModalContext.Provider>
  );
}
