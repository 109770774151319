import { createContext, useEffect, useState, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { getV3Configuration } from '@/api/configuration';

export const ConfigurationContext = createContext({
  config: null,
  setConfig: val => {},
});

export function ConfigurationProvider({ children }) {
  const { locale } = useRouter();
  const [config, setConfig] = useState(null);

  const { data: configurationData } = useQuery({
    queryKey: ['config', locale],
    queryFn: ({ queryKey }) => getV3Configuration(queryKey[1]),
    staleTime: 1000 * 60 * 60 * 24, // Cache the data for 24 hours
  });

  useEffect(() => {
    if (configurationData) setConfig(configurationData);
  }, [configurationData]);

  return <ConfigurationContext.Provider value={{ config, setConfig }}>{children}</ConfigurationContext.Provider>;
}

export function useConfiguration() {
  const context = useContext(ConfigurationContext);
  if (!context) {
    throw new Error('useConfiguration must be used within a ConfigurationProvider');
  }
  return context;
}
