import { BehaviorSubject } from 'rxjs';

const subjectPrefferenceModal = new BehaviorSubject(false);
const subjectPrefferenceSnackBar = new BehaviorSubject(false);
const productPrefferences = new BehaviorSubject({});
const snackBarMessage = new BehaviorSubject('');

const IngredientsSubject = new BehaviorSubject({});
const IngredientsModalSubject = new BehaviorSubject(false);
const IngredientsModalConfigurationSubject = new BehaviorSubject('large');
const IngredientsEditNavigationSubject = new BehaviorSubject();

const IngredientsModalStore = {
  update: value => {
    IngredientsModalSubject.next(value);
  },
  subscribe: setState => IngredientsModalSubject.subscribe(setState),
  getValue: () => IngredientsModalSubject.value,
};

const IngredientsStore = {
  update: value => {
    IngredientsSubject.next(value);
  },
  subscribe: setState => IngredientsSubject.subscribe(setState),
  getValue: () => IngredientsSubject.value,
};

const IngredientsModalConfigurationStore = {
  update: value => {
    IngredientsModalConfigurationSubject.next(value);
  },
  subscribe: setState => IngredientsModalConfigurationSubject.subscribe(setState),
  getValue: () => IngredientsModalConfigurationSubject.value,
};

const IngredientsEditNavigationStore = {
  update: value => {
    IngredientsEditNavigationSubject.next(value);
  },
  subscribe: setState => IngredientsEditNavigationSubject.subscribe(setState),
  getValue: () => IngredientsEditNavigationSubject.value,
};

const prefferenceModalStore = {
  update: value => {
    subjectPrefferenceModal.next(value);
  },
  subscribe: setState => subjectPrefferenceModal.subscribe(setState),
  getValue: () => subjectPrefferenceModal.value,
};
const productPrefferencesStore = {
  update: (value, options, limitations) => {
    const opt = { options: options };
    const newValue = { ...value, ...opt };
    const finalValue = { ...newValue, ...limitations };
    productPrefferences.next(finalValue);
  },
  subscribe: setState => productPrefferences.subscribe(setState),
  getValue: () => productPrefferences.value,
};

const prefferenceSnackBarStore = {
  update: value => {
    subjectPrefferenceSnackBar.next(value);
  },
  subscribe: setState => subjectPrefferenceSnackBar.subscribe(setState),
  getValue: () => subjectPrefferenceSnackBar.value,
};
const snackBarMessageStore = {
  update: value => {
    snackBarMessage.next(value);
  },
  subscribe: setState => snackBarMessage.subscribe(setState),
  getValue: () => snackBarMessage.value,
};

export {
  prefferenceModalStore,
  productPrefferencesStore,
  prefferenceSnackBarStore,
  snackBarMessageStore,
  IngredientsModalStore,
  IngredientsStore,
  IngredientsModalConfigurationStore,
  IngredientsEditNavigationStore,
};
