import { DEFAULT_LOCALE } from '@/constants/locale.js';
import { checkListStatus } from '@/utils/lists.js';
import { getLabel, updateCartOrder } from '@/utils/products/products.js';
import { OfferShapeEnum } from '@desquared/wave-components-library';
import get from 'lodash/get';
import Router from 'next/router';
import { ListAdditionSheetStore, BundleOfferSideSheetStore } from '@/store/sideSheet';
import { ListSelectedProductStore } from '@/store/listSelectedProduct';
import { onClickAddToOrder, onClickRemoveFromOrder } from '@/utils/cart';
import { isEditStarted, productsForOrder, updateCartTimer } from '@/store/cart';
import { BundleOfferSnackBarOpen } from '@/store/bundleOfferSnackBar';
import { SelectedBundleOfferStore } from '@/store/selectedBundleOfferId';
import {
  IngredientsModalConfigurationStore,
  IngredientsModalStore,
  IngredientsStore,
  prefferenceModalStore,
  productPrefferencesStore,
} from '@/store/prefferenceModal';
import { ListLoginModalStore } from '@/store/listLoginModal';
import { enhanceProductWithSlug } from './products/productSlugs';
import User from '@/store/user';
import el from 'dayjs/locale/el';
import { debounce, isEmpty } from 'lodash';
import { arraysEqual } from './helpers';
import Cookies from 'universal-cookie';

const defaultFormatter = new Intl.NumberFormat('el-GR', { currency: 'EUR', style: 'currency' });
const cookies = new Cookies();

export function getProductOrderLimit(orderLimitations, product) {
  return product.quantityLimit;
}

export function getDisplayName(product) {
  if (product.displayName) {
    return product.displayName;
  }

  if (product.brand) {
    return [product.brand, product.type].join(' ').trim();
  }
  if (product.categoryKind) {
    return [product.categoryKind, product.type].join(' ').trim();
  }
  return [product.name].join('').trim();
}

export function getDisplayDescription(product) {
  if (product.brand) {
    return [product.categoryKind, product.quantity].join(' ').trim();
  }
  return product.quantity || '';
}

export function getProductTransformed(product) {
  const displayName = getDisplayName(product);
  const displayDescription = getDisplayDescription(product);
  return { ...product, displayName, displayDescription };
}

export function offerLink(offer) {
  const sanitizedName = offer.name.replace(/[/%]/g, '');
  return `/offers/${sanitizedName}-${offer.externalId}`;
}

export function getBadgeText(product, bundlesSummary) {
  if (product?.bundleOfferIds) {
    return getBundleOfferLabel(product, bundlesSummary);
  }

  if (product?.webSticker) {
    return product.webSticker;
  }
  if (product.offerType === 'super') {
    return 'Super τιμή';
  }
  if (product.offerType === 'percentage' && product.offerValue !== 0) {
    return `-${product.offerValue.toFixed(0)}%`;
  }
  if (product.offerType === 'amount') {
    return `-${(product.offerValue / 100).toFixed(2)} €`;
  }
  return '';
}

export function isOffersPage(slug) {
  return slug[0] === 'offers';
}

export function isProductDetailsPage(slug) {
  return slug.length > 2 && !isOffersPage(slug);
}
export function isProductListPage(slug) {
  return !isOffersPage(slug) && !isProductDetailsPage(slug);
}

export function getCategory(slug, categories) {
  return categories?.find(c => c.slugAscii === slug[0]);
}
export function getCategoryName(slug, categories) {
  const _category = getCategory(slug, categories);
  return isOffersPage(slug) ? 'Προσφορές' : _category?.name || '';
}
export function getSubCategoryName(slug, categories) {
  const _category = getCategory(slug, categories);
  if (!_category || !slug[1]) return null;

  const subCategories = get(_category, 'subCategories', []);
  const subCategory = subCategories.find(c => c.slugAscii === slug[1]);
  return subCategory?.name || '';
}
export function getPageTitle(slug, categories) {
  if (isOffersPage(slug)) {
    return 'Προσφορές';
  }
  if (!isProductListPage(slug)) return '';
  if (slug[2]) return slug[2];

  if (slug[1]) {
    return getSubCategoryName(slug, categories);
  }

  return getCategoryName(slug, categories);
}

export function addQuantityLimit(aisles, snackBarFunction, limitations) {
  aisles.map(i => {
    i.products.map(product => {
      product.setMaxSnackBar = snackBarFunction;
      product.quantityLimit = getProductOrderLimit(limitations, product);
    });
  });
}

export function getProductImage(product, variant = 'original') {
  //this is for v2 + v3 -- soon to be deprecated
  if (typeof product?.images === 'object') {
    return product.images.baseUrl + product.images.primary;
  }
  //this is for v3.1
  if (product?.primaryImage) {
    if (variant === 'original') {
      return `${product?.primaryImage?.baseURL}/${product?.primaryImage?.imageVariants?.[variant]}`;
    } else {
      return `${product?.primaryImage?.baseURL}/${product?.primaryImage?.imageVariants?.variants?.[variant]}`;
    }
  }

  return null;
}

export function getProductAlternativeImages(product, variant = 'original') {
  //this is for v2 + v3 -- soon to be deprecated
  if (product?.alternativeImages) {
    const baseUrl = product?.images?.baseUrl;
    if (!baseUrl || !product.images.primary) return [];
    return [`${baseUrl}${product.images.primary}`].concat(product?.alternativeImages?.map?.(i => `${baseUrl}${i}`));
  }

  //this is for v3.1
  if (product?.images && product.images.length > 0) {
    const sortedImageArray = product.images.sort((a, b) => a.index - b.index);
    return sortedImageArray?.map(productImage => {
      return `${productImage?.baseURL}/${productImage?.imageVariants?.[variant]}`;
    });
  }

  //ensure that the returned images array is not undefined
  return [];
}

export function addPrimaryImage(product, imageArray, variant = 'original') {
  //this is for v2 + v3 -- soon to be deprecated
  if (product?.primaryImage) {
    const primaryImage = `${product.primaryImage?.baseURL}/${product?.primaryImage?.imageVariants?.[variant]}`;
    imageArray.unshift(primaryImage);
    const uniqueSet = new Set(imageArray);
    const uniqueArray = Array.from(uniqueSet);
    return uniqueArray;
  }
  return imageArray;
}

export function getProductName(product) {
  //this is for v3.1
  if (product?.name) {
    return product?.name;
  } else {
    if (product?.displayName) {
      //this is for v2 + v3 -- soon to be deprecated
      return product.displayName;
    } else return '';
  }
}

export function getCollectionTypes(product) {
  //this is for v2 + v3 -- soon to be deprecated
  if (product.collectionType) {
    return product.collectionType;
  }

  //this is for v3.1
  return product?.collectionTypes || null;
}

export function getProductTags(product, tags) {
  const productTags = [];
  if (product?.tags?.length > 0 && tags) {
    product?.tags.forEach(tag => {
      if (tags[tag])
        productTags.push({
          labelTag: tags[tag].title,
          labelImageTitle: tags[tag].title,
          labelImage: getProductTagsImage(tags[tag].images),
        });
    });
  }

  return productTags;
}

export function getProductTagsImage(images) {
  if (images && images?.length > 0) {
    let tagsImage = '';
    images.map(image => {
      if (image.type === 'PRIMARY') {
        tagsImage = `${image?.baseURL}/${image.imageVariants?.original}`;
      }
    });
    return tagsImage;
  }
}

export function getBundleOfferLabel(product, bundlesSummary) {
  const productBundleOfferLabels = [];
  if (product.bundleOfferIds?.length > 0 && bundlesSummary) {
    product.bundleOfferIds.forEach(bundle => {
      if (bundlesSummary[bundle]) productBundleOfferLabels.push(bundlesSummary[bundle].webSticker);
    });
  }
  return productBundleOfferLabels[0];
}

export function getBundleOfferDescription(product, bundlesSummary) {
  const productBundleOfferDecriptions = [];
  if (product?.bundleOfferIds?.length > 0 && bundlesSummary) {
    product.bundleOfferIds.forEach(bundle => {
      if (bundlesSummary[bundle])
        productBundleOfferDecriptions.push(bundlesSummary[bundle].description ?? bundlesSummary[bundle].name);
    });
  }
  return productBundleOfferDecriptions[0];
}

export function getBundleOfferTitle(product, bundlesSummary) {
  let productBundleOfferTitle = '';
  if (bundlesSummary) {
    product.bundleOfferIds.forEach(bundle => {
      if (bundlesSummary[bundle]) productBundleOfferTitle = bundlesSummary[bundle].name;
    });
  }
  return productBundleOfferTitle;
}

const debouncedUpdateCartOrder = debounce((cart, noCommentToken) => {
  if (updateCartTimer.getValue()) updateCartOrder(cart, 'none', false, noCommentToken);
}, 1000);

export function addProduct(
  product,
  isInDetails = false,
  analyticsProps,
  massUpdate = false,
  noCommentToken,
  bypassIngredientsModal = false
) {
  if (product.ingredientsGroups?.length && !IngredientsModalStore.getValue() && !bypassIngredientsModal) {
    IngredientsStore.update(product);
    IngredientsModalStore.update(true);
    return;
  }

  updateCartTimer.update(true);
  const isInCart = checkIfInCart(product);
  const authorization = cookies.get('token');
  if (product.productMYO) {
    productPrefferencesStore.update(product, product.optionsGroups, product.limitations);
    prefferenceModalStore.update(true);
  } else {
    if (isInCart) {
      onClickAddToOrder(product, product.quantityLimit, product.defaultOption, 1, product.productMYO, analyticsProps);
      product.isQsSelected = true;
      if (
        !isEmpty(authorization) &&
        process.env.NEXT_PUBLIC_SYNC_CART === 'true' &&
        !massUpdate &&
        !isEditStarted.getValue()
      ) {
        debouncedUpdateCartOrder(productsForOrder.getValue(), noCommentToken);
      }
    } else {
      product.isQsSelected = true;
      onClickAddToOrder(product, product.quantityLimit, product.defaultOption, 1, product.productMYO, analyticsProps);
      if (
        !isEmpty(authorization) &&
        process.env.NEXT_PUBLIC_SYNC_CART === 'true' &&
        !massUpdate &&
        !isEditStarted.getValue()
      ) {
        updateCartOrder(productsForOrder.getValue(), 'none', false, noCommentToken);
        updateCartTimer.update(false);
      }
    }
  }

  if (product?.bundleOfferIds?.length > 0 && !isInCart) {
    if (!isInDetails) {
      SelectedBundleOfferStore.update(product);
      BundleOfferSnackBarOpen.update(true);
    }
  }
}
export function removeProduct(product, analyticsProps, noCommentToken, bypassIngredientsModal = false) {
  if (
    product.ingredientsGroups?.length &&
    !IngredientsModalStore.getValue() &&
    !!productsForOrder.getValue().find(el => el.id === product.id) &&
    !bypassIngredientsModal
  ) {
    IngredientsStore.update(product);
    IngredientsModalConfigurationStore.update('small');
    IngredientsModalStore.update(true);
    return;
  }

  const authorization = cookies.get('token');
  updateCartTimer.update(true);
  if (product.productMYO) {
    productPrefferencesStore.update(product, product.optionsGroups, product.limitations);
    prefferenceModalStore.update(true);
  } else {
    onClickRemoveFromOrder(product, analyticsProps);
    const cart = productsForOrder.getValue();
    const productInCart =
      cart.some(el => el.id === product.id) || cart.some(el => el.myoId === product.myoId && el.myoId !== undefined);
    if (!isEmpty(authorization) && process.env.NEXT_PUBLIC_SYNC_CART === 'true' && !isEditStarted.getValue()) {
      if (productInCart) {
        debouncedUpdateCartOrder(cart, noCommentToken);
      } else {
        updateCartTimer.update(false);
        updateCartOrder(cart, 'none', false, noCommentToken);
      }
    }
  }

  if (
    productsForOrder.getValue()?.length === 0 &&
    !isEmpty(authorization) &&
    process.env.NEXT_PUBLIC_SYNC_CART === 'true' &&
    !isEditStarted.getValue()
  ) {
    updateCartOrder([], 'none', true, noCommentToken);
  }
}
export function updateSharedCartWithComments(products, product, noCommentToken) {
  const authorization = cookies.get('token');
  updateCartTimer.update(true);
  // if (product) {
  //   const isInCart = checkIfInCart(product);
  //   if (!isInCart) return;
  // }
  if (!isEmpty(authorization) && process.env.NEXT_PUBLIC_SYNC_CART === 'true' && !isEditStarted.getValue()) {
    debouncedUpdateCartOrder(products, noCommentToken);
  }
}

export function checkIfInCart(product) {
  if (product?.optionsGroups?.length > 0) {
    return productsForOrder.getValue()?.some(item => item.myoId === product.myoId && item.myoId !== undefined);
  }

  if (product.ingredients && product.ingredientsGroups) {
    return productsForOrder
      .getValue()
      .some(
        p =>
          p.ingredients &&
          arraysEqual(
            typeof product.ingredients[0] === 'string' ? product.ingredients : product.ingredients.map(i => i.id),
            typeof p.ingredients[0] === 'string' ? p.ingredients : p.ingredients.map(i => i.id)
          ) &&
          p.id === product.id
      );
  }

  return productsForOrder.getValue()?.some(item => item.id === product.id);
}

export function transformProduct(
  product,
  setMaxSnackBar,
  limitations,
  tags,
  bundlesSummary,
  finalPrice,
  beginPrice,
  ingredients
) {
  if (!product || !process) return;

  const productMYO = product?.optionsGroups?.length > 0;
  const productsCart = productsForOrder.getValue();
  const selected = productsForOrder.getValue()?.some(item => item.id === product._id);
  let volume =
    Array.isArray(ingredients) && product?.ingredientsGroups?.length
      ? countOccurancesByIdIngredients(productsCart, product._id, ingredients)
      : countOccurrencesById(productsCart, product._id);

  // if (product?.ingredientsGroups?.length && !ingredients?.length) {
  //   volume = countOccurancesByIdIngredients(productsCart, product._id, []);
  // }

  let details;
  if (productMYO) {
    details = findMyoIdAndDetailsById(productsCart, product._id);
  }
  const { myoId, stepToUomMYO, uomMYO, weighedMYO } =
    productMYO && details ? details : { myoId: 0, stepToUomMYO: 0, uomMYO: '', weighedMYO: false };
  const weighed = productMYO ? weighedMYO : product.isWeighed;

  let newProduct = {
    id: product._id,
    brand: product?.brand || null,
    categories: product?.categoryIds || [],
    productMYO: productMYO,
    displayName: getProductName(product),
    image: getProductImage(product, '4x'),
    quantity: product.quantity,
    isQsSelected: volume >= 1,
    barcode: product.barcodes && product.barcodes[0] ? product.barcodes[0] : undefined,
    modifiedUom: calculateVolumeQuality(weighed, product, volume, productMYO, productsCart, stepToUomMYO, false),
    stepToUom: productMYO ? stepToUomMYO : product.stepToUom,
    unitOfMeasurement: weighed
      ? gramsToKilograms(product.stepToUom * volume, true, product.unitOfMeasurement)
      : productMYO
      ? countUniqueMyoIdsForId(productsCart, product._id) > 1
        ? convertToGrams(product?.optionsGroups[0].options, product._id, true, product.unitOfMeasurement)
        : ' ' + productsCart?.find(el => el.id === product._id)?.options[0]?.association?.uom
      : ' ' + product.uom,
    unitOfMeasurementDescription: product.unitOfMeasurement,
    currency: process.env?.currency || 'EUR',
    price: finalPrice
      ? ((finalPrice * product.stepToUom) / 100).toFixed(2)
      : ((product.finalPrice * product.stepToUom) / 100).toFixed(2),
    unitOfMeasurementFinalPrice: finalPrice
      ? (finalPrice / 100).toFixed(2)
      : (product.unitOfMeasurementFinalPrice / 100).toFixed(2),
    unitOfMeasurementBeginPrice: beginPrice
      ? (beginPrice / 100).toFixed(2)
      : (product.unitOfMeasurementBeginPrice / 100).toFixed(2),
    QsShape: process.env.QsShape,
    available: product.available,
    enabled: product.enabled,
    canIncrease: true,
    QsReadOnly: false,
    tileSize: process.env.tileSize,
    isPreview: false,
    offerValue: product?.bundleOfferIds
      ? getBundleOfferLabel(product, bundlesSummary)
      : getBadgeText(product, bundlesSummary),
    // : `${Math.round((product.offerValue * 100) / 100)} %`,
    offerShape: process.env.offerShape,
    offerHasIcon: process.env.offerHasIcon,
    offerColor: process.env.offerColor || 'red',
    offerHasSticker: process.env.offerHasSticker || false,
    offerIconType: process.env.offerIcon || false,
    isOnOffer:
      product?.bundleOfferIds?.length > 0
        ? product.bundleOfferIds.some(id => bundlesSummary.hasOwnProperty(id))
        : product.offerType !== 'none',
    offerType: product.offerType || 'none',
    sku: product.sku,
    optionsGroups: product.optionsGroups,
    quantityLimit: product.quantityLimit ? product.quantityLimit : 10000,
    setMaxSnackBar: setMaxSnackBar,
    collectionTypes: getCollectionTypes(product),
    isWeighed: product.isWeighed,
    limitations: volume,
    tags: getProductTags(product, tags),
    noOptions: productMYO ? countUniqueMyoIdsForId(productsCart, product._id) : undefined,
    comments: !productMYO ? extractCommentNonOption(product, ingredients) : '',
    // status: status,
    onListClick: () => {
      // ListAdditionSheetStore.open();
      // ListSelectedProductStore.update(product);
    },
    slug: product.slug,
    bundleOfferIds:
      product.bundleOfferIds?.length > 0 && product.bundleOfferIds.some(id => bundlesSummary.hasOwnProperty(id))
        ? product.bundleOfferIds
        : [],
    addProduct: () => {
      // if (productMYO) {
      //   productPrefferencesStore.update(newProduct, options, limitations);
      //   prefferenceModalStore.update(true);
      // } else {
      //   onClickAddToOrder(newProduct, newProduct.quantityLimit, defaultOption, 1, productMYO);
      // }
    },
    removeProduct: () => {
      // onClickRemoveFromOrder(newProduct);
      // // clearCart();
    },
    originalValues: product,
    isSideCart: false,
    ...(product.ingredientsGroups &&
      product.ingredientsGroups.length && { ingredientsGroups: product.ingredientsGroups }),
  };

  if (product.ingredientsGroups?.length) {
    newProduct = {
      ...newProduct,
      onProductClick: () => {
        if (isEditStarted.getValue()) return;
        if (productsCart.find(p => p.id === newProduct.id)) {
          IngredientsStore.update(newProduct);
          IngredientsModalConfigurationStore.update('small');
          IngredientsModalStore.update(true);
        } else {
          // TODO: Change this
          Router.push({ pathname: newProduct.slug });
        }
      },
    };
  }

  return newProduct;
}

export function openListSideSheet(product) {
  if (User.getValue()) {
    ListSelectedProductStore.update(product);
    ListAdditionSheetStore.open();
  } else {
    ListLoginModalStore.open();
  }
}

export function getOfferValue(product, bundlesSummary) {
  return product?.bundleOfferIds ? getBundleOfferLabel(product, bundlesSummary) : getBadgeText(product, bundlesSummary);
}

export function formatOrderProduct(product, definition, bundleOffersSummary, formatter = defaultFormatter) {
  if (!product) return undefined;

  const defWithoutQuantity = Object.fromEntries(Object.entries(definition).filter(([key]) => key !== 'quantity'));
  const cartItem = { ...product, ...defWithoutQuantity, unitOfMeasurement: product.uom };
  const productMYO = product?.options?.length > 0;
  const productsCart = productsForOrder.getValue();
  const volume = countOccurrencesById(productsCart, cartItem._id);
  let details;
  if (productMYO) {
    details = findMyoIdAndDetailsById(productsCart, cartItem._id);
  }

  const { myoId, stepToUomMYO, uomMYO, weighedMYO } =
    productMYO && details ? details : { myoId: 0, stepToUomMYO: 0, uomMYO: '', weighedMYO: false };
  const weighed = productMYO ? product.options[0].association.isWeighed : cartItem.isWeighed;

  return {
    ...defWithoutQuantity,
    ...cartItem,
    image: getProductImage(definition),
    imageTitle: getProductName(cartItem),
    hasOffer: cartItem.offerValue,
    offerValue: getOfferValue(product, bundleOffersSummary),
    productTitle: getProductName(cartItem),
    quantityLimit: cartItem.quantityLimit,
    label: cartItem?.bundleOfferIds
      ? getBundleOfferLabel(cartItem, bundleOffersSummary)
      : getBadgeText(cartItem, bundleOffersSummary),
    labelType: getLabel(cartItem),
    shape: OfferShapeEnum.FullPageCart,
    status: checkListStatus(cartItem),
    hasIcon: false,
    productPrice: formatter.format((cartItem.finalPrice * cartItem.stepToUom) / 100),
    productPriceBefore: formatter.format((cartItem.beginPrice * cartItem.stepToUom) / 100),
    productQuantity: weighed
      ? productMYO
        ? convertToGrams(cartItem?.optionsGroups[0].options, cartItem._id, false)
        : gramsToKilograms(cartItem.stepToUom * cartItem.quantity, false)
      : productMYO
      ? countUniqueMyoIdsForId(productsCart, cartItem._id) > 1
        ? convertToGrams(cartItem?.optionsGroups[0].options, cartItem._id, false)
        : stepToUomMYO * volume
      : cartItem.stepToUom * volume,

    hasDeleteButton: true,
    hasAddButton: true,
    showList: true,
    categories: cartItem.categories,
    volume: weighed
      ? productMYO
        ? gramsToKilograms(
            product.options[0].association.stepToUom * volume,
            true,
            product.options[0].association.unitOfMeasurement
          )
        : gramsToKilograms(cartItem.stepToUom * volume, true, definition.unitOfMeasurement)
      : productMYO
      ? ' ' + product.options[0].association.uom
      : ' ' + definition.uom,

    //these should be connected to the Cart
    // onChange: id => onAdd(id, () => {}),
    // onRemove: id => onRemove(id, () => {}),
    // onAddList: item => openListSideSheet(item),
  };
}

export function formatProductToOrderTile(product, bundleOffersSummary, selectedOrder) {
  const productMYO = product?.optionsGroups?.length > 0;
  const productsCart = productsForOrder.getValue();
  const selected = productsForOrder.getValue()?.some(item => item.id === product?._id);
  const volume = countOccurrencesById(productsCart, product?._id);
  let details;
  if (productMYO) {
    details = findMyoIdAndDetailsById(productsCart, product?._id);
  }

  if (selectedOrder?.products?.length > 0) {
    const updatedProduct = selectedOrder?.products.find(el => el.sku === product.sku);
    if (updatedProduct && updatedProduct.finalPrice < product.finalPrice) {
      product.finalPrice = updatedProduct.finalPrice;
      product.beginPrice = updatedProduct.beginPrice;
    }
  }
  const { myoId, stepToUomMYO, uomMYO, weighedMYO } =
    productMYO && details ? details : { myoId: 0, stepToUomMYO: 0, uomMYO: '', weighedMYO: false };
  const weighed = productMYO ? weighedMYO : product.isWeighed;

  return {
    ...product,
    id: product._id,
    productMYO: productMYO,
    isQsSelected: !!selected,
    productTitle: getProductName(product),
    image: getProductImage(product),
    imageTitle: getProductName(product),
    hasOffer: !!product.offerValue || !!product.bundleOfferIds,
    offerValue: getOfferValue(product, bundleOffersSummary),
    hasIcon: false,
    label: product?.bundleOfferIds
      ? getBundleOfferLabel(product, bundleOffersSummary)
      : getBadgeText(product, bundleOffersSummary),
    labelType: !!product.bundleOfferIds ? 'promo-pack' : getLabel(product),
    shape: OfferShapeEnum.FullPageCart,
    productPrice: `${((product.finalPrice * product.stepToUom) / 100).toFixed(2)}€`,
    productPriceBefore: `${((product.beginPrice * product.stepToUom) / 100).toFixed(2)}€`,
    productQuantity: calculateVolumeQuality(weighed, product, volume, productMYO, productsCart, stepToUomMYO, false),
    quantity: calculateVolumeQuality(weighed, product, volume, productMYO, productsCart, stepToUomMYO, false),
    hasDeleteButton: false,
    hasAddButton: true,
    showList: false,
    categories: product.categories,
    volume: weighed
      ? gramsToKilograms(product.stepToUom * volume, true)
      : productMYO
      ? countUniqueMyoIdsForId(productsCart, product._id) > 1
        ? convertToGrams(product?.optionsGroups[0].options, product._id, true)
        : ' ' + uomMYO
      : ' ' + product.uom,
    isSideCart: selected,
    isBundleOffer: !!product.bundleOfferIds,
    imageLabelHidden: true,
    status: checkListStatus(product),
    offerBannerState: 'notStarted',
    offerBannerEmoji: '🙌',
    offerBannerText: '',
    offerBannerInDetails: true,
    offerBannerButtonText: 'Το θέλω',
    offerBannerOnButtonPress: () => {
      SelectedBundleOfferStore.update(el);
      BundleOfferSideSheetStore.update(true);
    },
    noOptions: productMYO ? countUniqueMyoIdsForId(productsCart, product._id) : undefined,
    // hasOfferBanner: hasPotentialBundleOffer,
    // onChange: () => {},
    // onRemove: () => {},
  };
}

export function findIndexById(itemId, data) {
  const groupIndex = data.findIndex(group => {
    return group.items.some(item => (item.myoId ? item.myoId === itemId : item.id === itemId));
  });

  return groupIndex !== -1 ? groupIndex : -1;
}

export function addPreviousProduct(
  id,
  setTempProducts,
  setRemove,
  remove,
  updatedProducts,
  index,
  noUpdate = false,
  ingredients = [],
  bypassIngredientsModal = false
) {
  const productIndex = updatedProducts[index].items.findIndex(el => {
    if (el.ingredients && !el.myoId) return el._id === id && arraysEqual(el.ingredients, ingredients);

    return el.myoId ? el.myoId === id : el._id === id;
  });

  if (updatedProducts[index].items[productIndex]?.ingredients?.length && !bypassIngredientsModal) {
    IngredientsStore.update(updatedProducts[index].items[productIndex]);
    IngredientsModalStore.update(true);
    return;
  }

  const weighed =
    updatedProducts[index].items[productIndex]?.options.length > 0
      ? updatedProducts[index].items[productIndex]?.options[0]?.association?.isWeighed
      : updatedProducts[index].items[productIndex]?.isWeighed;

  // If the product is found, update the isSideCart property
  if (productIndex !== -1) {
    if (updatedProducts[index].items[productIndex].isSideCart) {
      if (weighed) {
        updatedProducts[index].items[productIndex].productQuantity +
          updatedProducts[index].items[productIndex].stepToUom;
      } else {
        updatedProducts[index].items[productIndex].productQuantity++;
      }
    } else {
      updatedProducts[index].items[productIndex].productQuantity = 1;
    }
    updatedProducts[index].items[productIndex].isSideCart = true;
  }
  let product = updatedProducts[index].items[productIndex];

  // Call onClickAddToOrder with the updated product
  let newQuantity;
  let newVolume;

  if (weighed) {
    const productQuantity =
      updatedProducts[index].items[productIndex]?.options.length > 0
        ? countOccurrencesByMyoId(productsForOrder.getValue(), updatedProducts[index].items[productIndex].myoId)
        : updatedProducts[index].items[productIndex]?.ingredients
        ? countOccurancesByIdIngredients(
            productsForOrder.getValue(),
            updatedProducts[index].items[productIndex].id,
            ingredients
          )
        : countOccurrencesById(productsForOrder.getValue(), updatedProducts[index].items[productIndex].id);

    const conversionFactor =
      updatedProducts[index].items[productIndex]?.options.length > 0
        ? updatedProducts[index].items[productIndex].options[0].association.stepToUom
        : product.stepToUom;

    newQuantity = gramsToKilograms((productQuantity + 1) * conversionFactor, false);
    newVolume = gramsToKilograms((productQuantity + 1) * conversionFactor, true);

    updatedProducts[index].items[productIndex].productQuantity = newQuantity;
    updatedProducts[index].items[productIndex].volume = newVolume;
  }
  if (updatedProducts[index].items[productIndex]?.options.length > 0) {
    product = {
      ...updatedProducts[index].items[productIndex],
      options: [updatedProducts[index].items[productIndex].options[0].id],
      productQuantity: newQuantity,
      volume: newVolume,
      isSideCart: true,
      canIncrease: true,
    };
  } else {
    product = {
      ...updatedProducts[index].items[productIndex],
      productQuantity: newQuantity,
      volume: newVolume,
      isSideCart: true,
      canIncrease: true,
    };
  }

  onClickAddToOrder(
    product,
    updatedProducts[index].items[productIndex]?.quantityLimit,
    updatedProducts[index].items[productIndex]?.options,
    1,
    updatedProducts[index].items[productIndex]?.optionsGroups?.length > 0
  );
  if (!noUpdate) {
    updateCartOrder(productsForOrder.getValue(), 'none');
  }

  // Continue with the rest of the function
  if (setTempProducts) setTempProducts(updatedProducts);

  setRemove && setRemove(!remove);
}
export function removePreviousProduct(id, setTempProducts, setRemove, remove, updatedProducts, index, ingredients) {
  const productIndex = updatedProducts[index].items.findIndex(el => {
    if (el.ingredients && ingredients && !el.myoId) return el._id === id && arraysEqual(ingredients, el.ingredients);
    return el.myoId ? el.myoId === id : el._id === id;
  });

  // If the product is found, update the isSideCart property
  if (productIndex !== -1) {
    const stepToUom =
      updatedProducts[index].items[productIndex].options.length > 0
        ? updatedProducts[index].items[productIndex].options[0].association.stepToUom
        : updatedProducts[index].items[productIndex].stepToUom;
    const quantity =
      updatedProducts[index].items[productIndex].options.length > 0
        ? countOccurrencesByMyoId(productsForOrder.getValue(), updatedProducts[index].items[productIndex].myoId)
        : ingredients
        ? countOccurancesByIdIngredients(
            productsForOrder.getValue(),
            updatedProducts[index].items[productIndex].id,
            ingredients
          )
        : countOccurrencesById(productsForOrder.getValue(), updatedProducts[index].items[productIndex].id);
    if (quantity > 1) {
      updatedProducts[index].items[productIndex].productQuantity = quantity * stepToUom - stepToUom;
      updatedProducts[index].items[productIndex].isSideCart = true;
    } else {
      updatedProducts[index].items[productIndex].productQuantity = 0;
      updatedProducts[index].items[productIndex].isSideCart = false;
    }
  }

  onClickRemoveFromOrder(updatedProducts[index].items[productIndex]);
  updateCartOrder(productsForOrder.getValue(), 'none');
  // Continue with the rest of the function
  if (setTempProducts) setTempProducts(updatedProducts);

  setRemove && setRemove(!remove);
}
export function removeAllPreviousProduct(id, setTempProducts, setRemove, remove, updatedProducts, index, ingredients) {
  const products = updatedProducts[index].items.filter(el => {
    if (ingredients && el.ingredients && !el.myoId) return el._id === id && arraysEqual(el.ingredients, ingredients);
    if (!ingredients && el.ingredients && !el.ingredients.length && !el.myoId)
      return el._id === id && arraysEqual(el.ingredients, []);

    return el.myoId ? el.myoId === id : el._id === id;
  });
  let quantity;
  if (products[0].myoId !== undefined) {
    quantity = productsForOrder.getValue().filter(obj => obj.myoId === products[0].myoId).length;
  } else if (ingredients && products[0].ingredients) {
    quantity = productsForOrder
      .getValue()
      .filter(obj => obj.id === products[0].id && arraysEqual(ingredients, products[0].ingredients)).length;
  } else {
    quantity = productsForOrder.getValue().filter(obj => obj.id === products[0].id).length;
  }

  for (let i = 0; i < quantity; i++) {
    removePreviousProduct(id, setTempProducts, setRemove, remove, updatedProducts, index, ingredients);
  }
}

export function countOccurancesByIdIngredients(arr, targetId, targetIngredients) {
  const idIngredientsCount = {}; // Object to store counts based on id + ingredients combination

  // Loop through the array of objects
  if (arr?.length > 0) {
    for (const obj of arr) {
      if (obj?.hasOwnProperty('id') && obj?.hasOwnProperty('ingredients')) {
        if (typeof obj.ingredients[0] !== 'string') obj.ingredients = obj.ingredients.map(i => i.id);
        const id = String(obj.id);
        const ingredientsKey = JSON.stringify(obj.ingredients.sort()); // Ensure ingredients order doesn't matter
        const uniqueKey = `${id}-${ingredientsKey}`; // Combine id and sorted ingredients as unique key

        if (idIngredientsCount.hasOwnProperty(uniqueKey)) {
          idIngredientsCount[uniqueKey]++;
        } else {
          idIngredientsCount[uniqueKey] = 1;
        }
      }
    }
  }

  // Create the lookup key for the target id and ingredients
  const targetKey = `${String(targetId)}-${JSON.stringify(targetIngredients.sort())}`;

  // Return the count if it exists, otherwise return 0
  return idIngredientsCount.hasOwnProperty(targetKey) ? idIngredientsCount[targetKey] : 0;
}

export function countOccurrencesById(arr, targetId) {
  const idCount = {}; // Create an object to store the counts

  // Loop through the array of objects
  if (arr?.length > 0) {
    for (const obj of arr) {
      if (obj.hasOwnProperty('id')) {
        const id = String(obj.id);

        if (idCount.hasOwnProperty(id)) {
          idCount[id]++;
        } else {
          idCount[id] = 1;
        }
      }
    }
  }
  // Check if the targetId exists in the idCount object
  if (idCount.hasOwnProperty(targetId)) {
    return idCount[targetId]; // Return the count for the targetId
  } else {
    return 0; // Return 0 if the targetId doesn't exist in the idCount object
  }
}

export function countOccurrencesByMyoId(arr, targetId) {
  const idCount = {}; // Create an object to store the counts

  // Loop through the array of objects
  if (arr?.length > 0) {
    for (const obj of arr) {
      if (obj.hasOwnProperty('myoId')) {
        const id = String(obj.myoId);

        if (idCount.hasOwnProperty(id)) {
          idCount[id]++;
        } else {
          idCount[id] = 1;
        }
      }
    }
  }

  // Check if the targetId exists in the idCount object
  if (idCount.hasOwnProperty(targetId)) {
    return idCount[targetId]; // Return the count for the targetId
  } else {
    return 0; // Return 0 if the targetId doesn't exist in the idCount object
  }
}
export function checkProductAvailability(product) {
  return product.available && product.enabled;
}

export function countDistinctIds(arrayOfObjects) {
  const ids = arrayOfObjects.map(obj => obj.id);

  const uniqueIds = new Set(ids);

  return uniqueIds.size;
}

function createMyoAproxWording(weightPerItem, unitOfMeasurement) {
  return `${parseFloat(weightPerItem)} ${unitOfMeasurement}`;
}

export const initMyoProducts = (pref, comments) => {
  // Ensure that a product is available
  if (pref) {
    const optionsGroup = pref.optionsGroups?.[0];
    if (optionsGroup) {
      // Create a product for each option
      return optionsGroup.options.map((option, index) => {
        // Clone the original product
        const clonedProduct = { ...pref };

        // Generate a unique id based on the original id and the selected option id
        const uniqueId = `${pref.id}_${option.id}`;

        // Add the unique id to the cloned product
        clonedProduct.myoId = uniqueId;
        clonedProduct.showLabel = option.showLabel;
        clonedProduct.quantityLimit = pref.quantityLimit;
        clonedProduct.comments =
          comments?.length > 0 && comments[index] !== '' && comments[index]
            ? comments[index]
            : extractComment(clonedProduct);

        const volume = countOccurrencesByMyoId(productsForOrder.getValue(), uniqueId);
        clonedProduct.isQsSelected = volume > 0;
        // Add the option id to the options property of the product
        clonedProduct.options = [option];
        clonedProduct.productMYO = false;
        // Update other properties based on the selected option
        const association = option?.association;
        if (association) {
          const baseCoefficient = association?.unitOfMeasurementBaseCoefficient
            ? association.unitOfMeasurementBaseCoefficient
            : process.env.defaultUnitBase;
          clonedProduct.unitOfMeasurement = ' ' + association.uom;
          clonedProduct.isWeighed = association.isWeighed;
          clonedProduct.unitOfMeasurementFinalPrice = (Number(pref.unitOfMeasurementFinalPrice) * 100).toFixed(2);
          clonedProduct.unitOfMeasurementBeginPrice = (Number(pref.unitOfMeasurementBeginPrice) * 100).toFixed(2);
          // clonedProduct.unitOfMeasurementDescription = association.unitOfMeasurement;
          clonedProduct.stepToUom = association.stepToUom;
          clonedProduct.price = (association.finalPricePerStepToUom / 100).toFixed(2);
          clonedProduct.oldPrice =
            association.finalPricePerStepToUom !== association.beginPricePerStepToUom
              ? (association.beginPricePerStepToUom / 100).toFixed(2)
              : undefined;
          if (association.beginPricePerStepToUom) {
            clonedProduct.beginPrice = association.beginPricePerStepToUom;
          } else {
            clonedProduct.beginPrice = association.finalPricePerStepToUom;
          }
          clonedProduct.aproxWeight = createMyoAproxWording(
            association?.weightPerItemDisplay ? association.weightPerItemDisplay : association.weightPerItem,
            association.unitOfMeasurement
          );
          clonedProduct.modifiedUom = clonedProduct.isWeighed
            ? baseCoefficient * clonedProduct.stepToUom * volume
            : clonedProduct.stepToUom * volume;
          clonedProduct.unitOfMeasurementBaseCoefficient = baseCoefficient;
        }

        return clonedProduct;
      });
    }
  }
};

export function countUniqueMyoIdsForId(arr, targetId) {
  const myoIdsSet = new Set();
  if (arr?.length > 0 && arr !== undefined) {
    arr.forEach(obj => {
      const { id, myoId } = obj;

      if (id === targetId && myoId !== undefined) {
        myoIdsSet.add(myoId);
      }
    });
    return myoIdsSet.size;
  }

  return null;
}
export function findMyoIdAndDetailsById(arr, targetId) {
  const foundObj = arr?.find(obj => obj.id === targetId && obj.myoId !== undefined);

  if (foundObj) {
    const { myoId, stepToUom, unitOfMeasurement, isWeighed } = foundObj;

    return { myoId, stepToUomMYO: stepToUom, uomMYO: unitOfMeasurement, weighedMYO: isWeighed };
  }

  // If no matching entry is found, return null or handle accordingly
  return null;
}

export const createStringArray = (options, products, t) => {
  let wordings = [];

  options.forEach((el, index) => {
    if (el.isOpted) {
      let quantity;
      if (products[index].isWeighed) {
        const baseCoefficient = products[index]?.unitOfMeasurementBaseCoefficient
          ? products[index].unitOfMeasurementBaseCoefficient
          : process.env.defaultUnitBase;
        quantity = (
          countOccurrencesByMyoId(productsForOrder.getValue(), products[index].myoId) *
          products[index].stepToUom *
          baseCoefficient
        ).toFixed(0);
      } else {
        quantity = countOccurrencesByMyoId(productsForOrder.getValue(), products[index].myoId);
      }

      if (quantity !== 0) {
        // if (el.text == 'Kιλό' || el.text == 'Κιλά') {
        //   wordings.push(quantity + ' ' + products[index].unitOfMeasurement);
        // } else if (el.text == 'Τεμάχιο' || el.text == 'Τεμάχια') {
        //   wordings.push(quantity + ' ' + products[index].unitOfMeasurement);

        if (
          products[index].unitOfMeasurement.trim() == el.text ||
          el.text.toLowerCase().includes(products[index].unitOfMeasurement.trim())
        ) {
          wordings.push(quantity + ' ' + products[index].unitOfMeasurement);
        } else if (!products[index].showLabel) {
          wordings.push(quantity + ' ' + products[index].unitOfMeasurement);
        } else {
          wordings.push(quantity + ' ' + products[index].unitOfMeasurement + ' ' + el.text);
        }
      }
    }
  });

  const joinedString = wordings.join(` ${t('common.product_customization.banner_and')} `);
  if (joinedString) {
    return t('common.product_customization.banner', { options: joinedString });
  } else {
    return '';
  }
};

export const checkIsOpted = options => {
  const optedCount = options.filter(item => item.isOpted).length;
  return optedCount > 1;
};

const convertToGrams = (options, id, uom, unitOfMeasurement) => {
  const products = productsForOrder.getValue().filter(el => el.id === id);
  let sum = 0;

  products.forEach(el => {
    const item = options.find(item => item.id === el.options[0] || item.id === el.options[0].id);
    sum += item?.association?.weightPerItem;
  });

  return gramsToKilograms(sum, uom, unitOfMeasurement);
};

export const gramsToKilograms = (grams, uom, unitOfMeasurement) => {
  // Convert grams to kilograms by dividing by 1000

  const locale = typeof window !== 'undefined' ? Router?.locale || DEFAULT_LOCALE : DEFAULT_LOCALE;
  // Determine the appropriate unit based on the locale

  // Use Intl.NumberFormat to format the number with one decimal place and append the unit
  if (uom) {
    if (unitOfMeasurement) {
      return ' ' + unitOfMeasurement;
    }
    const unit = locale === 'el' ? 'κιλ' : 'kg';
    return ' ' + unit;
  } else {
    return new Intl.NumberFormat(locale, { maximumFractionDigits: 3 }).format(grams);
  }
};

export const extractComment = product => {
  const comment = productsForOrder.getValue().filter(el => el.myoId === product.myoId);

  if (comment.length > 0) return comment[0].comments;
  return '';
};

export const extractCommentNonOption = (product, ingredients) => {
  const com = productsForOrder.getValue()?.find(el => {
    if (ingredients?.length) return el.id === product._id && arraysEqual(ingredients, el.ingredients);
    return el.id === product._id;
  });
  const comment = com?.comments ? com?.comments : com?.description ? com?.description : undefined;

  if (comment) return comment.replace(/'/g, '');
  return '';
};

export const getProductPropsWithCleanUrl = props => {
  let href = props.href;
  if (href.indexOf('//') === 0) {
    href = href.substring(1, href.length);
  }
  return { ...props, href };
};

export const getProductBasedOnSlug = (products, slug) => {
  if (!products || !slug) return null;
  return products.find(i => i.slug === slug);
};
export const getProductIndexBasedOnSlug = (products, slug) => {
  if (!products || !slug) return null;
  return products.findIndex(i => i.slug === slug) + 1;
};

export const openProductDetails = item => {
  const productsForOrderValue = productsForOrder.getValue(); // Ensure productsForOrder is initialized
  const matchingProduct = productsForOrderValue.find(el => el.id === item.id); // Find the product with matching id
  // Check if a matching product was found and retrieve its categories
  const categories = matchingProduct ? matchingProduct.categories : [];
  const slug = matchingProduct ? matchingProduct.slug : '';
  const newProduct = { ...item, categoryIds: categories, slug: slug, _id: item.id };
  const product = enhanceProductWithSlug(newProduct);

  if (product) {
    Router.push({
      pathname: product?.slug,
      ...(product.ingredients?.length && { search: `?preSelectedIngredients=${JSON.stringify(product.ingredients)}` }),
    });
  } else {
    Router.push({
      pathname: enhanceProductWithSlug(item)?.slug,
      ...(item.ingredients?.length && { search: `?preSelectedIngredients=${JSON.stringify(item.ingredients)}` }),
    });
  }
};

export function calculateVolumeQuality(weighed, product, volume, productMYO, productsCart, stepToUomMYO, quality) {
  if (weighed) {
    if (productMYO) {
      return convertToGrams(
        product?.optionsGroups[0].options,
        product._id,
        quality,
        product?.unitOfMeasurementBaseCoefficient
          ? product.unitOfMeasurementBaseCoefficient
          : process.env.defaultUnitBase
      );
    } else {
      return gramsToKilograms(product.stepToUom * volume, quality);
    }
  } else {
    if (productMYO) {
      if (countUniqueMyoIdsForId(productsCart, product._id) > 1) {
        return convertToGrams(
          product?.optionsGroups[0].options,
          product._id,
          quality,
          product?.unitOfMeasurementBaseCoefficient
            ? product.unitOfMeasurementBaseCoefficient
            : process.env.defaultUnitBase
        );
      } else {
        return stepToUomMYO * volume;
      }
    } else {
      return product.stepToUom * volume;
    }
  }
}
// function containsKilogram(text) {
//   // List of keywords for "kilogram" and "kilo" in various languages
//   const keywords = [
//     'kilogram',
//     'kilo', // English
//     'kilogramme',
//     'kilo', // French
//     'kilogramo',
//     'kilo', // Spanish
//     'kilogramma',
//     'kilo', // Italian
//     'kilogramm',
//     'kilo', // German
//     'килограмм',
//     'кило', // Russian
//     'quilograma',
//     'quilo', // Portuguese
//     'χιλιόγραμο',
//     'κιλό',
//     'κιλό',
//     'κιλά',
//     'χιλιόγραμα',
//     'kιλό',
//     // Add more as needed
//   ];

//   // Normalize the input text
//   const normalizedText = text.toLowerCase();

//   // Check if any keyword is present in the text
//   return keywords.some(keyword => normalizedText.includes(keyword));
// }
