import dayjs from 'dayjs';
import 'dayjs/locale/el';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, take, filter } from 'rxjs/operators';
import Cookies from 'universal-cookie';

// import { ORDER } from '../api/wave/orders';
import jsonStorage from '../utils/json-storage';

import { IsAddressRegistered, PostalCode } from './isAddressRegistered';
import { getUserV3 } from '@/api/users';

const cookies = new Cookies();

const INITIAL_SUBJECT_VALUE = undefined;
dayjs.locale('el');
const meta = {
  cached: false,
  fetched: false,
  updatedAt: new Date(),
};

const User = {
  subject: INITIAL_SUBJECT_VALUE,

  /**
   * @private
   * @returns user subject initialized and synced with localStorage
   */
  // eslint-disable-next-line sort-keys
  _lazyInit() {
    if (User.subject) return User.subject;
    if (typeof window !== 'undefined') {
      if (window?.localStorage) {
        const storage = window.localStorage;
        const user = jsonStorage.get('user', { storage });
        Object.assign(meta, {
          cached: true,
          fetched: false,
          updatedAt: new Date(),
        });
        User.subject = new BehaviorSubject(user);
        this._lazyUserObjectRefresh();
        return User.subject;
      }
    }
  },

  async _lazyUserObjectRefresh() {
    const token = cookies.get('token');
    if (token) {
      try {
        const res = await getUserV3(token);
        this.update(res);
      } catch (e) {
        // TODO: Proper error handling
        console.log(e);
      }
    }
  },

  addAddress(address) {
    const currUser = User.getValue();

    const user = {
      ...currUser,
      addresses: [...currUser.addresses, address],
    };

    User.update(user);
  },

  getUserCards() {
    const _user = User.getValue();
    return _user?.creditCards;
  },

  getLists() {
    return User.getSubject().pipe(
      map(u => u?.lists || []),
      distinctUntilChanged()
    );
  },

  updateMainAddressInstance() {
    User.getSubject()
      .pipe(
        filter(u => u),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      )
      .subscribe(user => {
        const mainAddress = User.getUserMainAddress();
        User.update({
          ...user,
          mainAddress,
        });
        //   PostalCode.update(mainAddress?.postalCode);
        IsAddressRegistered.update(true);
      });
  },

  getUserMainAddress() {
    const _user = User.getValue();
    return _user?.mainAddress || _user?.addresses?.[0];
  },
  getDeliveryAddress(index) {
    const _user = User.getValue();
    const mainAddress = isNumber(index) ? _user?.addresses?.[index] : _user?.mainAddress;
    let address;
    if (mainAddress) {
      address = {
        type: mainAddress.type,
        street: mainAddress.street,
        city: mainAddress.city,
        streetNo: mainAddress.streetNo,
        postalCode: mainAddress.postalCode,
        floor: mainAddress.floor,
        nameAtBell: mainAddress.nameAtBell,
        contactPhone: mainAddress.contactPhone,
      };
    } else {
      address = undefined;
    }

    return address;
  },

  getFormattedAddress(index) {
    const mainAddress = this.getDeliveryAddress(index);

    return (
      mainAddress?.street + ' ' + mainAddress?.streetNo + ', ' + mainAddress?.postalCode + ', ' + mainAddress?.city
    );
  },

  updateMainAddress(mainAddress) {
    const _user = User.getValue();
    User.update({
      ..._user,
      mainAddress,
    });
  },

  getMainAddress() {
    return User.getSubject().pipe(map(u => u?.mainAddress || u?.addresses?.[0]));
  },

  getSubject() {
    return User._lazyInit();
  },

  getValue() {
    const user = User._lazyInit();
    return user?._value;
  },

  reset() {
    cookies.remove('token', { path: '/' });
    // IsAddressRegistered.update(false);
    PostalCode.update(undefined);
    // delete ORDER.defaults.headers.authorization;

    User.getSubject().next(INITIAL_SUBJECT_VALUE);
    User.updateLocalStorage();

    Object.assign(meta, {
      cached: false,
      fetched: false,
      updatedAt: new Date(),
    });
  },

  setLists(lists) {
    const prevState = User.getValue();
    return User.update({
      ...prevState,
      lists,
    });
  },

  subscribe(setState) {
    const user = User._lazyInit();
    return user.subscribe(setState);
  },

  update(newValues) {
    const user$ = User.getSubject();
    const prevState = user$.value;

    if (newValues.address) {
      if (!newValues.addresses) {
        const { address } = newValues;
        newValues['addresses'] = address;
      }
      delete newValues.address;
    }

    const newState = {
      ...prevState,
      ...newValues,
    };
    user$.next(newState);

    Object.assign(meta, {
      fetched: true,
      updatedAt: new Date(),
    });

    User.updateLocalStorage();
  },

  updateLocalStorage(newState = User.getValue()) {
    if (window?.localStorage) {
      const storage = window.localStorage;
      jsonStorage.set('user', newState, { storage });
    }
  },

  isEligibleToRate$() {
    return User._lazyInit().pipe(
      take(1),
      map((user = {}) => {
        const lastRatingAt = user?.lastRatings?.web?.createdAt;

        return !lastRatingAt || Math.abs(dayjs(lastRatingAt).diff(dayjs(), 'months')) >= 3;
      })
    );
  },
};

export default User;
