import cloneDeep from 'lodash/cloneDeep';
import { productsForOrder, cartPrice, fullCartStore } from '@/store/cart';
import { getShopsV3 } from '@/api/Shops';
import User from '../../store/user';
import {
  getProductImage,
  getProductName,
  countOccurrencesById,
  countOccurrencesByMyoId,
  countOccurancesByIdIngredients,
  getBundleOfferLabel,
  openListSideSheet,
  gramsToKilograms,
  transformProduct,
} from '../products';
import { OfferShapeEnum } from '@desquared/wave-components-library';
import { checkOrderCart, checkOrderEditCart, checkOrderEditCartAdd } from '@/api/wave/orders';
import { checkListStatus } from '../lists';
import { clearCart, onClickAddToOrder, onClickRemoveFromOrder } from '../cart';
import { getRootCategoryName } from '../categories/categories';
import { formatDateTime } from '../dates/dates';
import { SelectedShopId } from '@/store/isAddressRegistered';
import BundeOffersStore from '@/store/bundleOffers';
import { SelectedBundleOfferStore } from '@/store/selectedBundleOfferId';
import { BundleOfferSideSheetStore, InitPickupMethodStore, PaymentOptionStore } from '@/store/sideSheet';
import { Shop } from '@/store/shop';
import { filter, forEach, isEmpty, isNumber } from 'lodash';

import Big from 'big.js';
import { updateCart } from '@/api/syncCart';
import order from '@/store/order';
import { arraysEqual } from '../helpers';

export const SHIPPING_COST = 'SHIPPING_COST';
export const ORDER_LIMIT = 'MSG_ORDER00017';
export const SHIPPING_LIMIT = 'MSG_ORDER00016';
export const PRODUCT_PRICE = 'PRODUCT_PRICE';
export const ACCEPTED_STATE = 'ACCEPTED';
export const NO_OFFER_VALUE = '0 %';
export const PAYMENT_METHOD = 'PAYMENT_METHOD';
export const HANDLING_COST = 'HANDLING_COST';
const defaultFormatter = new Intl.NumberFormat('el-GR', { currency: 'EUR', style: 'currency' });

export const getQuantity = (el, products) => {
  if (products) {
    const filteredProducts = products.find(ol => {
      if (ol.ingredients) {
        return (
          ol.sku === el.sku ||
          (ol.productId === el.id &&
            arraysEqual(
              ol.ingredients.map(i => i.id),
              el.ingredients
            ))
        );
      }

      return ol.sku === el.sku || ol.productId === el.id;
    });
    if (!filteredProducts?.quantity) return 0;
    return filteredProducts?.quantity;
  }

  return 0; // Return 0 if there are no matching products
};

export const getMockQuantity = el => {
  let str = el.quantity;
  let match = str.match(/\d+/); // This regular expression matches one or more digits
  let volume = str.replace(/[^a-zA-Z]/g, '');
  if (match) {
    let number = parseInt(match[0], 10); // Convert the matched string to an integer

    number = number * countOccurrencesById(productsForOrder.getValue(), el.id, undefined);
    return number + volume;
  }
};
export const getOnlyQuantity = el => {
  let str = el?.quantity;

  let volume = str?.replace(/[^a-zA-Z\s]/g, '');

  if (volume?.length === 1 || volume === undefined) return el.uom;

  return volume;
};

export const getProductPrice = (flag, el, products, before = false, formatter = defaultFormatter) => {
  if (flag && products?.length > 0) {
    const filteredProducts = products?.find(ol => ol.sku === el.sku);
    const quantity = filteredProducts?.quantity;
    const step = filteredProducts?.stepToUom;

    if (before) {
      return formatter.format(
        Big(el?.beginPrice / 100)
          .times(quantity)
          .times(step)
          .round(2)
          .toNumber()
      );
    }
    if (filteredProducts?.priceBreakdown[0]) {
      return formatter.format(
        Big(filteredProducts?.priceBreakdown[0]?.price / 100)
          .times(quantity ?? 0)
          .times(step ?? 0)
          .round(2)
          .toNumber()
      );
    }

    return formatter.format(
      Big(el?.finalPrice / 100)
        .times(quantity ?? 0)
        .times(step ?? 0)
        .round(2)
        .toNumber()
    );
  } else {
    return formatter.format(0);
  }
};
export function getShopId() {
  // const shop = Shop.getStorageValue();

  // if (InitPickupMethodStore.getValue() !== 'takeAway') {
  //   const postal = User.getDeliveryAddress().postalCode;
  //   const id = setShopId(postal);
  //   return id || SelectedShopId.getValue();
  // } else {
  //   return SelectedShopId.getValue();
  // }
  if (SelectedShopId.getValue() !== '') return SelectedShopId.getValue();
}

export async function setShopId(postalCode, selectedServiceId) {
  if (postalCode.length > 0 && selectedServiceId) {
    return getShopsV3(selectedServiceId, postalCode).then(result => {
      if (result?.shops?.[0]?._id) {
        SelectedShopId.update(result.shops[0]._id);
        Shop.setSelectedShop(result.shops[0]);
        return result.shops[0]._id;
      } else {
        SelectedShopId.update('');
        Shop.setSelectedShop('');
        return null;
      }
    });
  } else {
    SelectedShopId.update('');
    Shop.setSelectedShop('');
    return null;
  }
}
export function getDelivery() {
  if (InitPickupMethodStore.getValue()) {
    return InitPickupMethodStore.getValue();
  } else {
    return 'takeAway';
  }
}

export const priceCompared = el => {
  const filteredProducts = productsForOrder.getValue().find(ol => ol.sku === el?.sku);

  if (!filteredProducts?.price) return false;

  if (filteredProducts?.optionsGroups?.length > 0) {
    return false;
  }
  if (!filteredProducts.isWeighed) {
    if (Big(filteredProducts?.price).times(100).round(2).toNumber() === el.finalPrice) return false;
  } else {
    if (
      Big(filteredProducts?.price)
        .times(100)
        .times(1 / el.stepToUom)
        .round(1)
        .toNumber() ===
      Math.round(el.finalPrice / 10) * 10
    )
      return false;
    else if (
      Math.abs(
        Big(filteredProducts?.price)
          .times(100)
          .times(1 / el.stepToUom)
          .round(1)
          .toNumber() - el.finalPrice
      ) < 5
    )
      return false;
  }

  return true;
};

export const priceBefore = (el, products) => {
  if (el?.beginPrice != el?.finalPrice || el?.beginPrice * el?.quantity * el?.stepToUom !== el?.totalPrice)
    return getProductPrice(true, el, products, true);

  return undefined;
};

export const priceBeforeBundle = (el, products, formatter = defaultFormatter) => {
  const filteredProducts = products?.find(ol => ol.sku === el.sku);
  if (filteredProducts) {
    const totalPrice = filteredProducts?.totalPrice;
    const quantity = filteredProducts?.quantity;
    const step = filteredProducts?.stepToUom;
    // const step = filteredProducts?.stepToUom;
    if (
      !el.isWeighed &&
      (el.beginPrice != el.finalPrice || filteredProducts.beginPrice * filteredProducts.quantity - totalPrice > 5)
    ) {
      const priceBefore = formatter.format(
        Big(filteredProducts.beginPrice / 100)
          .times(quantity ?? 0)
          .round(2)
          .toNumber()
      );
      const finalPrice = formatter.format(
        Big(totalPrice / 100)
          .round(2)
          .toNumber()
      );
      return { finalPrice, priceBefore };
    } else if (
      el.isWeighed &&
      (el.beginPrice != el.finalPrice || filteredProducts.beginPrice * filteredProducts.quantity - totalPrice > 5)
    ) {
      const priceBefore = formatter.format(
        Big(filteredProducts.beginPrice / 100)
          .times(quantity ?? 0)
          .times(filteredProducts?.stepToUom ?? 1)
          .round(2)
          .toNumber()
      );
      const finalPrice = formatter.format(
        Big(totalPrice / 100)
          .round(2)
          .toNumber()
      );
      return {
        finalPrice,
        priceBefore: priceBefore !== finalPrice ? priceBefore : undefined,
      };
    }
  }
  return { finalPrice: undefined, priceBefore: undefined };
};

export const getLabel = (el, fullCart) => {
  if (priceCompared(el)) {
    if (fullCart) {
      return undefined;
    }
    return 'priceChanged';
  }
  if (el?.available) {
    if (el?.webSticker) {
      return el?.offerType;
    } else {
      return el?.offerType;
    }
  }

  return 'unavailable';
};

export const getLabelTitle = (el, fullCart = false, isGift = false, t = undefined) => {
  // if (el.bundleOfferIds.length > 0) el.offerType = 'Bundle Offer';
  if (el?.webSticker) return el.webSticker;
  if (isGift) return t ? t('side_cart.gift') : 'Free Product';
  switch (getLabel(el, fullCart)) {
    case 'unavailable':
      return t ? t('side_cart.unavailable_product') : 'Unavailable product';
    case 'priceChanged':
      return t ? t('side_cart.price_renewed') : 'The price has been renewed';
    case 'percentage':
      return '- ' + el.offerValue + '%';
    case 'amount':
      return '- ' + (el.offerValue / 100).toFixed(2) + ' €';
    case 'promo-pack':
      return 'Bundle Offer';
    case 'Bundle Offer':
      return 'Bundle Offer';
    default:
      return undefined;
  }
};

export function onRemove(id, setTempProducts, setRemove, remove, tempProducts, setSelectedProduct, ingredients = []) {
  const productMYO = tempProducts
    ? tempProducts.filter(el => el.myoId === id)
    : productsForOrder.getValue().filter(el => el.myoId === id);
  const pr = productsForOrder.getValue().filter(el => el._id === id || el.id === id);

  if (productMYO.length === 0 && !pr[0]) {
  }
  let product;
  if (productMYO.length > 0) {
    product = productMYO;
    setSelectedProduct && setSelectedProduct(product);
  } else {
    product = productsForOrder.getValue().filter(el => el.id === id);

    if (ingredients && ingredients?.length) {
      product = [
        product.find(el => {
          return (
            el.id === id &&
            arraysEqual(
              typeof el.ingredients[0] === 'string' ? el.ingredients : el.ingredients?.map(i => i.id),
              ingredients
            )
          );
        }),
      ];
    } else {
      if (product.some(v => !!v.ingredients && !v.ingredients.length)) {
        product = [product.find(el => el.id === id && el.ingredients && !el.ingredients.length)];
      }
    }

    setSelectedProduct && setSelectedProduct(product[0]);
  }

  onClickRemoveFromOrder(product[0], {
    item_list_id: 'side_cart',
    item_list_name: 'Side cart',
  });

  if (setTempProducts) {
    const uniqueProducts = setCartProducts(tempProducts ? tempProducts : productsForOrder.getValue());
    setTempProducts(uniqueProducts);
  }

  setRemove && setRemove(!remove);
}
export function onRemoveAll(
  id,
  setTempProducts,
  setRemove,
  remove,
  tempProducts,
  setSelectedProduct,
  ingredients = []
) {
  // Filter out all products with the given id in the temporary list (MYO or other products)
  const productMYO = tempProducts
    ? tempProducts.filter(el => el.myoId === id) // Filter out all with myoId === id
    : productsForOrder.getValue().filter(el => el.myoId === id);

  let pr = productsForOrder.getValue().filter(el => el._id === id || el.id === id); // Filter out both _id and id
  if (ingredients?.length) pr = pr.filter(p => arraysEqual(p.ingredients, ingredients));
  if (pr.some(v => !!v.ingredients && !v.ingredients.length) && !ingredients.length)
    pr = pr.filter(p => p.ingredients && !p.ingredients.length);

  if (productMYO.length === 0 && pr.length === 0) {
    // If both lists have been fully filtered, there's nothing left to remove
    return;
  }

  // If there are still some items in productMYO, continue to set them in state
  if (productMYO.length > 0) {
    productMYO.forEach(element => onRemove(id, setTempProducts, setRemove, remove, tempProducts, setSelectedProduct));
  } else {
    pr.forEach(element =>
      onRemove(id, setTempProducts, setRemove, remove, tempProducts, setSelectedProduct, ingredients)
    );
  }
}

export function onAdd(id, setTempProducts, setRemove, remove, data, setSelectedProduct, ingredients = []) {
  const productMYO = productsForOrder.getValue().filter(el => el.myoId === id);
  let quantity = 1;
  let product;
  if (productMYO.length > 0) {
    product = productMYO;
    setSelectedProduct && setSelectedProduct(product);
  } else {
    product = productsForOrder.getValue().filter(el => el.id === id);

    if (ingredients && ingredients.length) {
      product = [
        product.find(el => {
          if (el.ingredients?.length) {
            return (
              el.id === id &&
              arraysEqual(
                typeof el.ingredients[0] === 'string' ? el.ingredients : el.ingredients.map(i => i.id),
                ingredients
              )
            );
          }

          return false;
        }),
      ];
    } else {
      if (product.some(v => !!v.ingredients && !v.ingredients.length)) {
        product = [product.find(el => el.id === id && el.ingredients && !el.ingredients.length)];
      }
    }

    if (setSelectedProduct) setSelectedProduct(product[0]);
    if (product.length === 0 && data.data?.order?.freeProducts?.length > 0) {
      product = data.products.filter(el => el.id === id);
      const product2 = data.data.order.freeProducts.filter(el => el.productId === product[0].id);
      const newProduct = { ...product[0], ...product2[0] };
      product[0] = newProduct;
    }
  }
  onClickAddToOrder(
    product[0],
    product[0]?.quantityLimit,
    product[0]?.defaultOption,
    quantity,
    product[0]?.productMYO,
    {
      item_list_id: 'side_cart',
      item_list_name: 'Side cart',
    }
  );

  if (setTempProducts) {
    const uniqueProducts = setCartProducts(productsForOrder.getValue());

    setTempProducts(uniqueProducts);
  }

  setRemove && setRemove(!remove);
}

export function checkSticker(products, orderProducts) {
  const filteredProduct = orderProducts?.find(ol => ol.sku === products.sku);

  if (filteredProduct?.sticker) {
    return filteredProduct.sticker;
  } else if (products?.webSticker) {
    return products.webSticker;
  }
  return null;
}

export const transformProducts = (
  orderCheckResponse,
  bundleOffersSummary,
  isGift,
  selectedProduct,
  noCommentToken = 'Χωρίς σχόλιο',
  currencyFormatter = defaultFormatter,
  unavailableToken = 'Μη διαθέσιμο προϊόν'
) => {
  const products = orderCheckResponse?.products.map(el => {
    const productPrice = getProductPrice(true, el, orderCheckResponse?.order?.products, undefined, currencyFormatter);
    const validationProduct = orderCheckResponse?.order?.products?.find(ol => ol.productId === el._id)?.isValid;
    const { finalPrice, priceBefore } = priceBeforeBundle(el, orderCheckResponse?.order?.products, currencyFormatter);
    const productMYO = el.optionsGroups?.length > 0;
    const productHasIngredients = el.ingredientsGroups?.length > 0;
    const { description, hasPotentialBundleOffer } = checkPotentialBundle(el, orderCheckResponse?.potentialDiscounts);
    const summary = BundeOffersStore.getValue() ? BundeOffersStore.getValue() : bundleOffersSummary;
    const sticker = checkSticker(el, orderCheckResponse?.order?.products);
    if (validationProduct) {
      if (!productMYO) {
        const com = orderCheckResponse?.order?.products.find(ol => ol.productId === el._id)?.comments;
        const comment = com.replace(/'/g, '');
        const transformedProduct = {
          available: el.available,
          canDecrease: isGift && getQuantity(el, orderCheckResponse?.order?.products) === 1 ? false : true,
          isGift: isGift,
          id: el?._id ? el._id : el.id,
          stepToUom: el?.stepToUom,
          image: getProductImage(el, '1.5x'),
          imageTitle: getProductName(el),
          hasOffer: el.offerValue,
          productTitle: getProductName(el),
          description: comment !== '' ? `'${comment}'` : noCommentToken,
          productPriceBefore: priceBefore,
          productPrice: !finalPrice
            ? productPrice === currencyFormatter.format(0)
              ? currencyFormatter.format((el.finalPrice * el.stepToUom) / 100)
              : productPrice
            : finalPrice,
          productQuantity: el.isWeighed
            ? gramsToKilograms(getQuantity(el, orderCheckResponse?.order?.products) * el.stepToUom, false)
            : getQuantity(el, orderCheckResponse?.order?.products),
          hasDeleteButton: true,
          categories: el?.categories ? el.categories : el?.categoryIds ? el?.categoryIds : [],
          volume: el.isWeighed
            ? gramsToKilograms(
                getQuantity(el, orderCheckResponse?.order?.products) * el.stepToUom,
                true,
                el.unitOfMeasurement
              )
            : el.uom,
          quantityLimit: el.quantityLimit,
          label: sticker
            ? sticker
            : el.bundleOfferIds?.length > 0 && summary
            ? getBundleOfferLabel(el, summary)
            : getLabelTitle(el),
          labelType: priceBefore || (el.bundleOfferIds?.length > 0 && summary) ? 'percentage' : getLabel(el),
          shape: OfferShapeEnum.FullPageCart,
          status: checkListStatus(el),
          sku: el.sku,
          // offerValue: product?.bundleOfferIds
          //   ? getBundleOfferLabel(product, bundlesSummary)
          //   : `${Math.round((product.offerValue * 100) / 100)} %`,
          onRemove: () => {
            // onRemove(el._id, setTempProducts, setRemove, remove);
          },
          onAddList: () => {
            // openListSideSheet(el);
          },
          onChange: () => {
            // onAdd(el._id, setTempProducts, setRemove, remove);
          },
          offerBannerState: 'notStarted',
          offerBannerEmoji: '🙌',
          offerBannerText: description,
          offerBannerInDetails: true,
          offerBannerButtonText: 'Το θέλω',
          offerBannerOnButtonPress: () => {
            SelectedBundleOfferStore.update(el);
            BundleOfferSideSheetStore.update(true);
          },
          hasOfferBanner: hasPotentialBundleOffer,
          isLoading: selectedProduct && !productHasIngredients && selectedProduct?.id === el._id,
          slug: el?.slug,
          ...(el.ingredientsGroups && { ingredientsGroups: el.ingredientsGroups }),
          ...(!el.ingredients?.length && el.ingredientsGroups?.length && { ingredients: [] }),
        };

        if (productHasIngredients) {
          return orderCheckResponse.order.products
            .filter(p => p.ingredients && p.productId === el._id)
            .map(p => {
              return {
                ...transformedProduct,
                ingredients: p.ingredients.map(i => i.id),
                description: p.comments,
                productQuantity: p.quantity,
                productPrice: currencyFormatter.format((p.finalPrice * p.quantity) / 100),
                ...(selectedProduct &&
                  !isEmpty(selectedProduct) && {
                    isLoading:
                      selectedProduct &&
                      selectedProduct?.id === el.id &&
                      arraysEqual(p.ingredients, selectedProduct?.ingredients || []),
                  }),
              };
            });
        }

        return transformedProduct;
      } else {
        const optionsProducts = orderCheckResponse?.order?.products
          .filter(op => op.sku === el.sku)
          .map(option => {
            return {
              isGift: isGift,
              myoId: el?._id ? `${el?._id}_${option.options[0]?.id}` : `${el?.id}_${option.options[0]?.id}`,
              id: el?._id ? el._id : el.id, // Assuming option has an array of options, adjust accordingly
              image: getProductImage(el),
              imageTitle: getProductName(el),
              hasOffer: el.offerValue,
              productTitle: getProductName(el),
              description: option?.comments
                ? option.options[0]?.label + ' ' + `'${option.comments}'`
                : option.options[0]?.label,
              comments: option?.comments ? option.comments : '',
              productPriceBefore: myoPriceBefore(option),
              optionsGroups: el.optionsGroups,
              options: option.options,
              stepToUom: option.options[0]?.association?.stepToUom,
              stepToUomMYO: option.options[0]?.association?.stepToUom,
              uom: option.options[0]?.association?.stepToUom,
              productPrice: `${currencyFormatter.format(
                (option.options[0]?.association?.finalPricePerStepToUom * option.quantity) / 100
              )}`,
              isWeighed: option.options[0]?.association?.isWeighed,
              productQuantity: option.options[0]?.association?.isWeighed
                ? gramsToKilograms(option.quantity * option.stepToUom, false)
                : option.quantity,
              hasDeleteButton: true,
              categories: el?.categories ? el.categories : el?.categoryIds ? el?.categoryIds : [],
              volume: option.options[0]?.association?.isWeighed
                ? gramsToKilograms(
                    option.quantity * option.stepToUom,
                    true,
                    option.options[0]?.association?.unitOfMeasurement
                  )
                : option.options[0]?.association?.uom,
              quantityLimit: el.quantityLimit,
              label: sticker
                ? sticker
                : el.bundleOfferIds?.length > 0 && summary
                ? getBundleOfferLabel(el, summary)
                : getLabelTitle(el),
              labelType: priceBefore || (el.bundleOfferIds?.length > 0 && summary) ? 'percentage' : getLabel(el),
              shape: OfferShapeEnum.FullPageCart,
              status: checkListStatus(el),
              sku: el.sku,
              slug: el?.slug,
              onRemove: () => {
                // onRemove(el._id, setTempProducts, setRemove, remove);
              },
              onAddList: () => {
                // openListSideSheet(el);
              },
              onChange: () => {
                // onAdd(el._id, setTempProducts, setRemove, remove);
              },
            };
          });
        return optionsProducts;
      }
    } else {
      return null;
    }
  });
  const inValidProducts = orderCheckResponse?.order?.products
    ?.filter(product => !product.isValid)
    ?.map(el => {
      const product = productsForOrder.getValue().find(il => il.id === el.productId);
      const baseCoefficient = product?.unitOfMeasurementBaseCoefficient
        ? product.unitOfMeasurementBaseCoefficient
        : process.env.defaultUnitBase;
      return {
        id: product?.id,
        image: getProductImage(product, '1.5x'),
        imageTitle: getProductName(product),
        hasOffer: product?.offerValue,
        productTitle: getProductName(product),
        description: product?.comments ? product.comments : noCommentToken,
        productPriceBefore: undefined,
        productPrice: product?.price,
        productQuantity: el.quantity,
        hasDproducteteButton: true,
        categories: product?.categories,
        volume: product?.isWeighed
          ? getQuantity(product, orderCheckResponse?.order?.products) * baseCoefficient * product?.stepToUom +
            product?.uom
          : product?.uom,
        quantityLimit: product?.quantityLimit,
        label: unavailableToken,
        labelType: 'unavailable',
        shape: OfferShapeEnum.FullPageCart,
        status: checkListStatus(product),
        sku: product?.sku,
      };
    });
  const newArray = products.flatMap(element => {
    if (Array.isArray(element)) {
      return element;
    } else {
      return [element];
    }
  });

  if (inValidProducts?.length > 0) {
    return newArray.concat(inValidProducts).filter(product => product !== null && product !== undefined);
  } else {
    return newArray.filter(product => product !== null && product !== undefined);
  }
};

export function checkPotentialBundle(product, potentialDiscounts) {
  let description = '';
  let hasPotentialBundleOffer = false;
  if (potentialDiscounts && potentialDiscounts.length > 0) {
    const discountedProduct = potentialDiscounts.find(
      discount => discount.orderProduct.productId === product?._id || discount.orderProduct.productId === product?.id
    );
    if (discountedProduct) {
      if (BundeOffersStore.getValue()) {
        const summary = BundeOffersStore.getValue();
        description = summary[discountedProduct.providerId]?.description;
        hasPotentialBundleOffer = true;
      }
    }
  }
  return { description, hasPotentialBundleOffer };
}

export async function checkOrder(
  tempProducts,
  replacementOption = 'none',
  isCart = false,
  collectionTypes,
  deliveryIndex = 0,
  bundleOffersSummary,
  bags = false,
  paymentOptions = [],
  selectedProduct,
  setSelectedProduct,
  noCommentToken,
  currencyFormatter = defaultFormatter,
  unavailableToken
) {
  const productsChecked = tempProducts
    .filter(el => countOccurrencesById(productsForOrder.getValue(), !isCart ? el.id : el._id) > 0)
    .map(el => {
      const myoId = el.myoId;
      const hasIngredients = el.ingredients?.length;

      let comment = el?.comments ? el?.comments : el?.description ? el?.description : '';
      if (el?.comments === noCommentToken || el?.description === noCommentToken) {
        comment = '';
      }
      comment = comment.startsWith("'") && comment.endsWith("'") ? comment.slice(1, -1) : comment;
      if (myoId || el.options?.length > 0) {
        // Handle products with myoId differently
        if (myoId) {
          const [productId, optionId] = myoId?.split('_');
          if (el?.comments === el?.options[0]?.label || comment === el?.options[0]?.label) {
            comment = '';
          }
          return {
            productId: myoId ? productId : el._id,
            quantity: countOccurrencesByMyoId(productsForOrder.getValue(), myoId),
            options: el.options[0]?.id ? [el.options[0]?.id] : el.options,
            categories: !isCart ? el?.categories : el.categoryIds,
            changesAllowed: replacementOption,
            comments: comment,
            sku: el?.sku,
          };
        } else {
          return {
            productId: el._id,
            quantity: el.quantity,
            options: el.options[0]?.id ? [el.options[0]?.id] : el.options,
            categories: !isCart ? el?.categories : el.categoryIds,
            changesAllowed: replacementOption,
            comments: el?.comments ? el?.comments : el?.description ? el?.description : '',
            sku: el?.sku,
          };
        }
      } else if (hasIngredients) {
        return {
          productId: !isCart ? el.id : el._id,
          quantity: countOccurancesByIdIngredients(
            productsForOrder.getValue(),
            !isCart ? el.id : el._id,
            el.ingredients
          ),
          options: el.options,
          categories: !isCart ? el?.categories : el.categoryIds,
          changesAllowed: replacementOption,
          ...(el.ingredients && { ingredients: el.ingredients }),
          comments: comment,
          sku: el?.sku,
        };
      } else {
        // Handle products without myoId
        return {
          productId: !isCart ? el.id : el._id,
          quantity:
            el.ingredients && el.ingredients.length === 0
              ? countOccurancesByIdIngredients(productsForOrder.getValue(), !isCart ? el.id : el._id, [])
              : countOccurrencesById(productsForOrder.getValue(), !isCart ? el.id : el._id),
          options: el.options,
          categories: !isCart ? el?.categories : el.categoryIds,
          changesAllowed: replacementOption,
          ...(!el.ingredients?.length && el.ingredientsGroups?.length && { ingredients: [] }),
          comments: comment,
          sku: el?.sku,
        };
      }
    });

  const mainAddress = User.getDeliveryAddress(deliveryIndex);
  if (productsChecked.length > 0) {
    const orderCheckResponse = await checkOrderCart(
      productsChecked,
      getShopId(),
      getDelivery(),
      replacementOption,
      getShopId() !== '' ? mainAddress : {},
      collectionTypes,
      PaymentOptionStore.getValue()?.type,
      bags,
      paymentOptions
    );

    if (!orderCheckResponse?.error && orderCheckResponse?.products?.length > 0) {
      let allProducts = [];
      const products = transformProducts(
        orderCheckResponse,
        bundleOffersSummary,
        false,
        selectedProduct,
        noCommentToken,
        currencyFormatter,
        unavailableToken
      );

      allProducts = [...products];

      if (orderCheckResponse?.order?.freeProducts && orderCheckResponse?.order?.freeProducts?.length > 0) {
        const { filteredGifts, giftQuantities, inCatalogueGifts, inCatalogueGiftsQuantities } = findGiftProducts(
          orderCheckResponse.products,
          orderCheckResponse.order.freeProducts
        );
        const freeproducts = transformToFullPageCartItems(
          filteredGifts,
          orderCheckResponse.order,
          true,
          true,
          giftQuantities,
          1,
          noCommentToken,
          undefined,
          currencyFormatter
        );
        let mockOrderCheckResponse = cloneDeep(orderCheckResponse);
        mockOrderCheckResponse.order.products = inCatalogueGifts;
        const inCatGifts = transformProducts(
          mockOrderCheckResponse,
          bundleOffersSummary,
          true,
          undefined,
          noCommentToken,
          undefined,
          currencyFormatter,
          unavailableToken
        );
        allProducts = [...inCatGifts, ...products];
        allProducts = mergeEntriesWithProductQuantity(allProducts);
        allProducts = [...freeproducts, ...allProducts];
      }
      const requiredProgress =
        orderCheckResponse?.messages?.find(item => item.code === ORDER_LIMIT) ??
        orderCheckResponse?.messages?.find(item => item.code === SHIPPING_LIMIT);
      cartPrice.update((orderCheckResponse?.order?.orderItemsPrice / 100).toFixed(2));
      fullCartStore.update({ data: orderCheckResponse, products: products });
      return {
        data: orderCheckResponse ?? {},
        products: allProducts,
        shipping: orderCheckResponse?.order?.fees?.filter(el => el.type === SHIPPING_COST)[0],
        progress: requiredProgress,
      };
    }
  }
  return {
    data: {},
    products: [],
  };
}
export function mergeEntriesWithProductQuantity(productsArray, isFullPageCart = false) {
  const mergedProductsMap = new Map();

  for (const product of productsArray) {
    const productKey = product.myoId ? `${product.id}-${product.myoId}` : product.id;

    if (mergedProductsMap.has(productKey)) {
      const existingProduct = mergedProductsMap.get(productKey);

      existingProduct.productQuantity += product.productQuantity;
      existingProduct.canDecrease = true;
      const existingProductPrice = parseFloat(existingProduct.productPrice.replace('€', '').replace(',', '.'));
      const existingProductPriceBefore = parseFloat(
        existingProduct.productPriceBefore?.replace('€', '').replace(',', '.')
      );

      const productPrice = parseFloat(product.productPrice?.replace('€', '').replace(',', '.'));
      if (!product.productPriceBefore) {
        product.productPriceBefore = product.productPrice;
      }
      const productPriceBefore = parseFloat(product.productPriceBefore?.replace('€', '').replace(',', '.'));

      isFullPageCart
        ? (existingProduct.productPrice = existingProductPriceBefore.toFixed(2) + '€')
        : (existingProduct.productPrice = (existingProductPrice + productPrice).toFixed(2) + '€');
      isFullPageCart
        ? (existingProduct.productPriceBefore = existingProductPriceBefore.toFixed(2) + '€')
        : (existingProduct.productPriceBefore = (existingProductPriceBefore + productPriceBefore).toFixed(2) + '€');

      existingProduct.productPriceBefore = (existingProductPriceBefore + productPriceBefore).toFixed(2) + '€';
      if (!existingProduct?.categories) {
        existingProduct.categories = [];
      }
      existingProduct.isGift = true;
    } else {
      const newProduct = { ...product, productQuantity: product.productQuantity, isGift: false };
      mergedProductsMap.set(productKey, newProduct);
    }
  }

  const mergedProductsArray = Array.from(mergedProductsMap.values());

  return mergedProductsArray;
}

export const findGiftProducts = (products, orderGifts) => {
  const filteredGifts = [];
  const giftQuantities = [];
  const inCatalogueGifts = [];
  const inCatalogueGiftsQuantities = [];

  for (const product1 of products) {
    const matchingProduct = orderGifts.find(product2 => product1._id === product2.productId);
    if (matchingProduct && product1.isExcludedFromCatalog) {
      matchingProduct?.sticker ? (product1.sticker = matchingProduct.sticker) : null;
      filteredGifts.push(product1);
      giftQuantities.push(matchingProduct.quantity);
    } else if (matchingProduct && !product1.isExcludedFromCatalog) {
      inCatalogueGifts.push(matchingProduct);
      inCatalogueGiftsQuantities.push(matchingProduct.quantity);
    }
  }
  return { filteredGifts, giftQuantities, inCatalogueGifts, inCatalogueGiftsQuantities };
};

export const checkProducts = () => {
  const products = fullCartStore.getValue()?.products;
  const checked = products.some(
    el =>
      (el?.labelType === 'unavailable' && !el?.isGift && !el?.isOutOfCatalogueGift) || el?.labelType === 'priceChanged'
  );
  return checked;
};

export const updatePrices = () => {
  const products = fullCartStore.getValue()?.products.filter(el => el.labelType === 'priceChanged');

  const array1Map = new Map(products.map(item => [item._id, item.productPrice.slice(0, -1)]));

  // Update prices in the second array based on the first array
  productsForOrder.getValue().forEach(item => {
    const finalPrice = array1Map.get(item.id);

    if (finalPrice !== undefined) {
      // Update the price property of the object in array2
      const product = productsForOrder.getValue().filter(el => el.id === id);

      onClickRemoveFromOrder(product[0], {
        item_list_id: 'side_cart',
        item_list_name: 'Side cart',
      });
      item.price = finalPrice.times(100).round(2).toNumber().toFixed(2); // Convert to string if needed
      onClickAddToOrder(item, item?.quantityLimit, item?.defaultOption, 1, item?.productMYO, {
        item_list_id: 'side_cart',
        item_list_name: 'Side cart',
      });
    }
  });
};
export const getFullCart = (
  groupedProducts,
  order,
  products,
  setTempProducts,
  setRemove,
  remove,
  potentialDiscounts,
  bundleOffersSummary,
  giftQuantitiess,
  locale,
  noCommentToken = 'Χωρίς σχόλιο',
  formatter = defaultFormatter
) => {
  const tempCat = groupedProducts.map(ol => ({
    title: getRootCategoryName(ol.categoriesIds[0], locale),
    items: ol.products.map(il => {
      const { description, hasPotentialBundleOffer } = checkPotentialBundle(il, potentialDiscounts);
      const summary = BundeOffersStore.getValue() ? BundeOffersStore.getValue() : bundleOffersSummary;
      if (il.options?.length > 0) {
        const product = products?.find(el => el._id === il.id);
        const option = order?.products.find(el =>
          il.options[0]?.id ? el?.options[0]?.id === il.options[0]?.id : el?.options[0]?.id === il.options[0]
        );
        const optionMyo = il.optionsGroups[0].options.find(el =>
          il.options[0]?.id ? el?.id === il.options[0]?.id : el?.id === il.options[0]
        );
        return {
          myoId: il.myoId,
          sku: il.sku,
          id: product?._id,
          image: getProductImage(product),
          imageTitle: getProductName(product),
          hasOffer: product?.offerValue,
          productTitle: getProductName(product),
          description: option?.comments ? optionMyo?.label + ', ' + `'${option?.comments}'` : optionMyo?.label,
          productPrice: formatter.format(option?.totalPrice / 100),
          productPriceBefore: myoPriceBeforeFull(optionMyo, option.quantity, formatter),
          productQuantity: optionMyo?.association?.isWeighed
            ? gramsToKilograms(option?.quantity * optionMyo?.association?.stepToUom, false)
            : getQuantity(product, order?.products),
          hasDeleteButton: true,
          categories: product?.categories,
          volume: optionMyo?.association?.isWeighed
            ? gramsToKilograms(
                option?.quantity * optionMyo?.association?.stepToUom,
                true,
                optionMyo?.association?.unitOfMeasurement
              )
            : optionMyo?.association?.uom,
          quantityLimit: product?.quantityLimit,
          label:
            product.bundleOfferIds?.length > 0 && summary
              ? getBundleOfferLabel(product, summary)
              : getLabelTitle(product, true),
          labelType: product.bundleOfferIds?.length > 0 && summary ? 'percentage' : getLabel(product),
          shape: OfferShapeEnum.FullPageCart,
          status: checkListStatus(product),
          onRemove: () => {
            onRemove(product._id, setTempProducts, setRemove, remove);
          },
          onAddList: () => {
            openListSideSheet(product);
          },
          onChange: () => {
            onAdd(product._id, setTempProducts, setRemove, remove);
          },
          offerBannerState: 'notStarted',
          offerBannerEmoji: '🙌',
          offerBannerText: description,
          offerBannerInDetails: true,
          offerBannerButtonText: 'Το θέλω',
          offerBannerOnButtonPress: () => {
            SelectedBundleOfferStore.update(il);
            BundleOfferSideSheetStore.update(true);
          },
          hasOfferBanner: hasPotentialBundleOffer,
        };
      } else {
        if (il.isOutOfCatalogueGift) {
          return il;
        }
        let giftIndex = 0;
        let price = 0;
        const isGift = order?.freeProducts?.filter(el => el.productId === il.id);
        if (isGift && isGift.length > 0) {
          if (isGift.length > 0) {
            price = isGift[0].beginPrice;
          }
          il.productQuantity = getQuantity(il, order?.products) + giftQuantitiess[giftIndex];
          if (getQuantity(il, order?.products) === 0) {
            il.canDecrease = false;
          }
          il.productPrice = formatter.format((price * (il.productQuantity - giftQuantitiess[giftIndex])) / 100);
          il.productPriceBefore = formatter.format((price * il.productQuantity) / 100);
          giftIndex++;
          return il;
        } else {
          const product = products?.find(el => el._id === il.id);
          const { finalPrice, priceBefore } = priceBeforeBundle(product, order?.products, formatter);
          const comment = order?.products.find(el => el.productId === product._id)?.comments;
          const productPrice = getProductPrice(true, product, order?.products, undefined, formatter);
          let newProduct = {
            id: product?._id,
            sku: product?.sku,
            image: getProductImage(product),
            imageTitle: getProductName(product),
            hasOffer: product?.offerValue,
            productTitle: getProductName(product),
            description: comment !== '' ? `'${comment}'` : noCommentToken,
            productPriceBefore: priceBefore,
            productPrice: !finalPrice
              ? productPrice === formatter.format(0)
                ? formatter.format((product?.finalPrice * product?.stepToUom) / 100)
                : productPrice
              : finalPrice,
            // productPrice: productPrice,
            // productPriceBefore: priceBefore(product, order?.products),
            productQuantity: product?.isWeighed
              ? gramsToKilograms(getQuantity(product, order?.products) * product?.stepToUom, false)
              : getQuantity(product, order?.products),
            hasDeleteButton: true,
            categories: isGift ? il.categories : product?.categories,
            volume: product?.isWeighed
              ? gramsToKilograms(
                  getQuantity(product, order?.products) * product?.stepToUom,
                  true,
                  product?.unitOfMeasurement
                )
              : product?.uom,
            quantityLimit: product?.quantityLimit,
            label:
              product?.bundleOfferIds?.length > 0 && summary
                ? getBundleOfferLabel(product, summary)
                : getLabelTitle(product, true),
            labelType: product?.bundleOfferIds?.length > 0 && summary ? 'percentage' : getLabel(product),
            shape: OfferShapeEnum.FullPageCart,
            status: checkListStatus(product),
            onRemove: () => {
              onRemove(product?._id, setTempProducts, setRemove, remove);
            },
            onAddList: () => {
              openListSideSheet(product);
            },
            onChange: () => {
              onAdd(product._id, setTempProducts, setRemove, remove);
            },
            offerBannerState: 'notStarted',
            offerBannerEmoji: '🙌',
            offerBannerText: description,
            offerBannerInDetails: true,
            offerBannerButtonText: 'Το θέλω',
            offerBannerOnButtonPress: () => {
              SelectedBundleOfferStore.update(il);
              BundleOfferSideSheetStore.update(true);
            },
            hasOfferBanner: hasPotentialBundleOffer,
          };

          if (il.ingredients) {
            const orderProduct = order?.products?.find(
              p =>
                p.productId === il.id &&
                arraysEqual(
                  il.ingredients,
                  p.ingredients?.map(i => i.id)
                )
            );

            newProduct = {
              ...newProduct,
              ingredients: il.ingredients,
              ingredientsGroups: il.ingredientsGroups,
              productQuantity: orderProduct?.quantity,
              productPrice: formatter.format((orderProduct?.finalPrice * orderProduct?.quantity) / 100),
            };
          }

          return newProduct;
        }
      }
    }),
  }));
  return tempCat;
};

export const submitProducts = (isAddOnly = false) => {
  if (!isAddOnly) {
    return fullCartStore.getValue()?.data?.order?.products.map(el => ({
      productId: el.productId,
      quantity: el.quantity,
      options: el?.options.length > 0 ? [el.options[0].id] : [],
      ...(el.ingredients && { ingredients: el.ingredients.map(i => i.id) }),
      categories: el.categories,
      changesAllowed: el.changesAllowed,
      comments: el?.comments,
    }));
  } else {
    const allProducts = productsForOrder.getValue()?.filter(el => !el.hasOwnProperty('productId'));
    const products = setCartProducts(allProducts);
    return products.map(el => ({
      productId: el.id,
      quantity: el.myoId ? countOccurrencesByMyoId(allProducts, el.myoId) : countOccurrencesById(allProducts, el.id),
      options: el?.options?.length > 0 ? el.options : [],
      ...(el.ingredients && { ingredients: el.ingredients.map(i => i.id) }),
      categories: el.categories,
      changesAllowed: el?.changesAllowed,
      comments: el?.comments,
    }));
  }
};

function addOfferValueToProduct(mainProduct, productList) {
  const matchingProduct = productList.find(product => product.id === mainProduct.productId);

  if (matchingProduct && matchingProduct.offerValue !== NO_OFFER_VALUE && !isNumber(matchingProduct.offerValue)) {
    mainProduct.sticker = matchingProduct.offerValue;
  }
  return mainProduct;
}

export const addStickers = products => {
  products.map(product => addOfferValueToProduct(product, productsForOrder.getValue()));

  return products;
};

export const formatTimeslots = (slots, indexes) => {
  if (indexes.selectedDayIndex !== -1 && indexes.selectedIndex !== -1) {
    return formatDateTime(
      slots[indexes.selectedDayIndex]?.date,
      slots[indexes.selectedDayIndex]?.timeslots[indexes.selectedIndex].name
    );
  }
};

export const checkAvailability = slots => {
  return slots.unaivalble;
};

export const getPaymentCard = () => {
  if (PaymentOptionStore.getValue().type === 'card') {
    const card = PaymentOptionStore.getValue().name;
    const cardNumber = card.slice(-4);
    const cards = User.getUserCards();
    const paymentCard = cards?.find(el => el.maskedNumber === cardNumber);
    if (paymentCard?._id) return paymentCard._id;
  } else {
    return undefined;
  }
};

export const transformToFullPageCartItems = (
  products,
  order,
  isGift = false,
  isGiftActive,
  giftQuantities,
  timesCovered,
  noCommentToken = 'Χωρίς σχόλιο',
  t = undefined,
  currencyFormatter = defaultFormatter
) => {
  // const tempCat = groupedProducts.map(el => ({
  //   title: getRootCategoryName(el.categoriesIds[el.categoriesIds.length - 1]),
  const items = products.map((el, index) => {
    const productPrice = getProductPrice(true, el, order?.products ? order?.products : order);
    const volume = countOccurrencesById(order?.products ? productsForOrder.getValue() : order, el._id);
    const quantity = getQuantity(el, order?.products ? order?.products : order);
    const { finalPrice, priceBefore } = priceBeforeBundle(
      el,
      order?.products ? order?.products : order,
      currencyFormatter
    );
    const productFinalPrice = findBundleProductFinalPrice(
      el,
      order?.products ? order?.products : order,
      finalPrice,
      productPrice,
      currencyFormatter
    );
    const baseCoefficient = el.unitOfMeasurementBaseCoefficient
      ? el.unitOfMeasurementBaseCoefficient
      : process.env.defaultUnitBase;
    return {
      isActive: !isGift || isGiftActive,
      // labelType: 'unavailable'
      // showList: false,
      // volume: 0,
      // units: 0,
      // isQSSelected: false,
      // hasAddButton: true,
      isOutOfCatalogueGift: isGift,
      id: el._id,
      image: getProductImage(el, '1.5x'),
      imageTitle: getProductName(el),
      hasOffer: el.offerValue,
      productTitle: getProductName(el),
      description: el?.comments ? el?.comments : noCommentToken,
      finalPrice: el.finalPrice,
      productPriceBefore: priceBefore && priceBefore !== currencyFormatter.format(0) ? priceBefore : null,
      productQuantity: isGift
        ? !el.isWeighed
          ? getGiftQuantity(giftQuantities, timesCovered, index) + el.uom
          : getGiftQuantity(giftQuantities, timesCovered, index) * baseCoefficient * el.stepToUom + el.uom
        : el.isWeighed
        ? ''
        : getQuantity(el, order?.products ? order?.products : order),
      productPrice: isGift ? '0.00€' : productFinalPrice,
      hasDeleteButton: false,
      categories: el.categoryIds,
      volume: el.isWeighed
        ? getQuantity(el, order?.products ? order?.products : order) * baseCoefficient * el.stepToUom + el.uom
        : getQuantity(el, order?.products ? order?.products : order) + el.uom,
      quantityLimit: el.quantityLimit,
      label: isGift && el?.sticker ? el.sticker : getLabelTitle(el, true, isGift, t),
      labelType: isGift ? 'gift' : priceBefore ? 'percentage' : getLabel(el),
      shape: OfferShapeEnum.FullPageCart,
      status: checkListStatus(el),
      // onRemove: () => {
      //   onRemove(el._id, setTempProducts, setRemove, remove);
      // },
      isQSSelected: !isGift,
      isSelected: false,
      isAvailable: true,
      canIncrease: true,
      readOnly: false,
      isSideCart: quantity ? true : false,
      isBundleOffer: true,
      defaultOption: el.defaultOption,
      productMYO: el.productMYO,
      stepToUom: el.stepToUom,
      modifiedUom: el.isWeighed ? 1000 * el.stepToUom * volume : el.stepToUom * volume,
      sku: el.sku,
      onAddList: () => {
        openListSideSheet(el);
      },
      onChange: () => {
        el.isQSSelected = true;
      },
    };
  });
  return items;
};

export const setCartProducts = productsOrder => {
  const seen = new Set();
  const objectsWithIngredients = productsOrder.filter(
    obj =>
      obj?.id !== undefined &&
      obj?.ingredients &&
      !obj.myoId &&
      !seen.has(`${obj.id}-${JSON.stringify(obj.ingredients.sort())}`) &&
      seen.add(`${obj.id}-${JSON.stringify(obj.ingredients.sort())}`)
  );

  const tempProductsFiltered = productsOrder.filter(
    (value, index, self) => self.map(p => p.id).indexOf(value.id) === index
  );

  const tempProductsFiltered2 = productsOrder.filter(
    (value, index, self) => value?.myoId !== undefined && self.map(p => p?.myoId).indexOf(value?.myoId) === index
  );
  const objectsWithoutMyoIdOrIngredients = tempProductsFiltered.filter(item => !item.myoId && !item.ingredients);
  const combinedProducts = [...objectsWithoutMyoIdOrIngredients, ...tempProductsFiltered2, ...objectsWithIngredients];
  return combinedProducts;
};

export const getGiftQuantity = (quantities, timesCovered, index) => {
  if (timesCovered === 0) {
    return quantities[index];
  } else {
    return quantities[index] * timesCovered;
  }
};

export const getCartOptions = products => {
  const productsmyo = products.map(el => {
    if (el.optionsGroups) {
    }
  });
};
export const enhanceProductWithSlug = product => {
  if (!product || !product?.categoryIds?.length) return;

  return { ...product };
};

export const findBundleProductFinalPrice = (product, order, finalPrice, productPrice, formatter = defaultFormatter) => {
  const filteredProducts = order?.find(ol => ol.sku === product.sku);
  if (!filteredProducts) return formatter.format((product.finalPrice * product.stepToUom) / 100);
  if (filteredProducts && filteredProducts?.quantity === 0)
    return formatter.format((product.finalPrice * product.stepToUom) / 100);
  else {
    if (finalPrice) return finalPrice;
    else return productPrice;
  }
};

const myoPriceBefore = option => {
  if (
    option.options[0]?.association?.beginPricePerStepToUom === option.options[0]?.association?.finalPricePerStepToUom ||
    option.options[0]?.association?.beginPricePerStepToUom === undefined
  )
    return undefined;
  return `${((option.options[0]?.association?.beginPricePerStepToUom * option.quantity) / 100).toFixed(2)}€`;
};

const myoPriceBeforeFull = (option, quantity, formatter = defaultFormatter) => {
  if (
    option?.association?.beginPricePerStepToUom === option?.association?.finalPricePerStepToUom ||
    option?.association?.beginPricePerStepToUom === undefined
  )
    return undefined;

  return `${formatter.format((option?.association?.beginPricePerStepToUom * quantity) / 100)}`;
};

export const setDiscounts = (discounts, type) => {
  let sum = 0;
  if (discounts?.length === 0 || discounts === undefined) return 0;
  sum = discounts.reduce((accumulator, discount) => {
    // Check if the target is "PRODUCTS" or "WHOLE_CART"
    if (type === 'PRODUCTS') {
      if (discount?.type === 'PRODUCT_PRICE' || discount?.type === 'BUNDLE_OFFER') {
        // Add the amount to the accumulator
        accumulator += discount?.amount;
      }
    } else {
      if (discount?.type === 'DISCOUNT_CAMPAIGN' && discount?.target !== 'SHIPPING_FEES') {
        // Add the amount to the accumulator
        accumulator += discount?.amount;
      }
    }

    // Return the accumulator for the next iteration
    return accumulator;
  }, sum);
  return sum;
};

export const removeAll = () => {
  productsForOrder.getValue()?.forEach(el => {
    onClickRemoveFromOrder(el);
  });
};

export async function checkOrderEdit(
  orderId,
  tempProducts,
  order,
  bundleOffersSummary,
  isAddOnly = false,
  isCart = false,
  currencyFormatter = defaultFormatter,
  noCommentToken = 'Χωρίς σχόλιο',
  unavailableToken
) {
  if (!order) return;

  const productsChecked = tempProducts
    .filter(el => countOccurrencesById(productsForOrder.getValue(), !isCart ? el.id : el._id) > 0)
    .map(el => {
      const myoId = el.myoId;
      if (el.options?.length > 0 || myoId) {
        // Handle products with myoId differently
        const optionId = el.options[0].id ?? el.options[0];

        return {
          productId: el.id,
          quantity: myoId ? countOccurrencesByMyoId(productsForOrder.getValue(), myoId) : el.quantity,
          options: [optionId],
          categories: !isCart ? el?.categories : el.categoryIds,
          changesAllowed: order.changesAllowed,
          comments: el?.comments ? el?.comments : '',
        };
      } else if (el.ingredients?.length) {
        return {
          productId: el.id,
          quantity: countOccurancesByIdIngredients(
            productsForOrder.getStorageValue().map(el => {
              if (el.ingredients?.length && !Array.isArray(el.ingredients[0])) {
                return {
                  ...el,
                  ingredients: typeof el.ingredients[0] === 'string' ? el.ingredients : el.ingredients.map(i => i.id),
                };
              }
            }),
            !isCart ? el.id : el._id,
            typeof el.ingredients[0] === 'string' ? el.ingredients : el.ingredients.map(i => i.id)
          ),
          categories: !isCart ? el?.categories : el.categoryIds,
          changesAllowed: order.changesAllowed,
          comments: el?.comments ? el?.comments : '',
          ingredients: typeof el.ingredients[0] === 'string' ? el.ingredients : el.ingredients.map(i => i.id),
        };
      } else {
        // Handle products without myoId
        return {
          productId: !isCart ? el.id : el._id,
          quantity:
            el.ingredients && el.ingredients.length === 0
              ? countOccurancesByIdIngredients(productsForOrder.getValue(), !isCart ? el.id : el._id, [])
              : countOccurrencesById(productsForOrder.getValue(), !isCart ? el.id : el._id),
          options: [],
          categories: !isCart ? el?.categories : el.categoryIds,
          changesAllowed: order.changesAllowed,
          comments: el?.comments ? el?.comments : '',
        };
      }
    });

  if (productsChecked.length > 0) {
    let orderCheckResponse;
    if (isAddOnly) {
      orderCheckResponse = await checkOrderEditCartAdd(
        productsChecked,
        order.shopId,
        order.deliveryService,
        order.address,
        order.wantPlasticBags,
        [order.timeSlotEnd, order.timeSlotStart],
        order.collectionTypes,
        order?.paymentOptions ? order.paymentOptions : [],
        order.paymentType,
        order.bottlesReturnInfo,
        order?.invoice ?? undefined,
        order?.cardId,
        order?.changesAllowed,
        orderId
      );
    } else {
      orderCheckResponse = await checkOrderEditCart(
        productsChecked,
        order.shopId,
        order.deliveryService,
        order.address,
        order.wantPlasticBags,
        [order.timeSlotEnd, order.timeSlotStart],
        order.collectionTypes,
        order?.paymentOptions ? order.paymentOptions : [],
        order.paymentType,
        order.bottlesReturnInfo,
        order?.invoice ?? undefined,
        order?.cardId,
        order?.changesAllowed,
        orderId
      );
    }

    if (!orderCheckResponse?.error && (orderCheckResponse?.products?.length > 0 || isAddOnly)) {
      let allProducts = [];
      const products = transformProducts(
        orderCheckResponse,
        bundleOffersSummary,
        undefined,
        undefined,
        noCommentToken,
        currencyFormatter,
        unavailableToken
      );
      allProducts = [...products];

      if (orderCheckResponse?.order?.freeProducts && orderCheckResponse?.order?.freeProducts?.length > 0) {
        const { filteredGifts, giftQuantities, inCatalogueGifts, inCatalogueGiftsQuantities } = findGiftProducts(
          orderCheckResponse.products,
          orderCheckResponse.order.freeProducts
        );
        const freeproducts = transformToFullPageCartItems(
          filteredGifts,
          orderCheckResponse.order,
          true,
          true,
          giftQuantities,
          1,
          noCommentToken,
          undefined,
          currencyFormatter
        );
        let mockOrderCheckResponse = cloneDeep(orderCheckResponse);
        mockOrderCheckResponse.order.products = inCatalogueGifts;
        const inCatGifts = transformProducts(
          mockOrderCheckResponse,
          bundleOffersSummary,
          true,
          undefined,
          noCommentToken,
          currencyFormatter
        );
        allProducts = [...inCatGifts, ...products];
        allProducts = mergeEntriesWithProductQuantity(allProducts);
        allProducts = [...freeproducts, ...allProducts];
      }
      const requiredProgress =
        orderCheckResponse?.messages?.find(item => item.code === ORDER_LIMIT) ??
        orderCheckResponse?.messages?.find(item => item.code === SHIPPING_LIMIT);

      // cartPrice.update((orderCheckResponse?.order?.orderItemsPrice / 100).toFixed(2));
      fullCartStore.update({ data: orderCheckResponse, products: products });

      return {
        data: orderCheckResponse ?? {},
        products: allProducts,
        shipping: orderCheckResponse?.order?.fees?.filter(el => el.type === SHIPPING_COST)[0],
        progress: requiredProgress,
      };
    }
  }
  return {
    data: {},
    products: [],
  };
}

export async function updateCartOrder(
  tempProducts,
  replacementOption,
  lastProduct = false,
  noCommentToken = 'No comment',
  onlyFilter = false
) {
  const productsChecked = tempProducts
    .filter(el => countOccurrencesById(productsForOrder.getValue(), el.id) > 0)
    .map(el => {
      const myoId = el.myoId;

      let comment = el?.comments || el?.comments === '' ? el?.comments : el?.description ? el?.description : '';

      if (
        el?.comments === noCommentToken ||
        el?.description === noCommentToken ||
        el?.comments === 'Χωρίς σχόλιο' ||
        el?.description === 'Χωρίς σχόλιο'
      ) {
        comment = '';
      }
      comment = comment.startsWith("'") && comment.endsWith("'") ? comment.slice(1, -1) : comment;
      if (myoId || el.options?.length > 0) {
        // Handle products with myoId differently
        if (myoId) {
          const [productId, optionId] = myoId?.split('_');

          if (el?.comments === el?.options[0]?.label || comment === el?.options[0]?.label) {
            comment = '';
          }
          return {
            productId: myoId ? productId : el._id,
            quantity: countOccurrencesByMyoId(productsForOrder.getValue(), myoId),
            options: el.options[0]?.id ? [el.options[0]?.id] : el.options,
            categories: el?.categories,
            changesAllowed: replacementOption,
            comments: comment,
            sku: el?.sku,
          };
        } else {
          return {
            productId: el._id,
            quantity: el.quantity,
            options: el.options[0]?.id ? [el.options[0]?.id] : el.options,
            categories: el?.categories,
            changesAllowed: replacementOption,
            comments: comment,
            sku: el.sku,
          };
        }
      } else if (el.ingredients?.length) {
        return {
          productId: el.id,
          quantity: countOccurancesByIdIngredients(productsForOrder.getValue(), el.id, el.ingredients),
          options: [],
          categories: el?.categories,
          changesAllowed: replacementOption,
          comments: comment,
          sku: el.sku,
          ...(el.ingredients && { ingredients: el.ingredients }),
        };
      } else {
        // Handle products without myoId
        return {
          productId: el.id,
          quantity:
            el.ingredients && el.ingredients.length === 0
              ? countOccurancesByIdIngredients(productsForOrder.getValue(), el.id, [])
              : countOccurrencesById(productsForOrder.getValue(), el.id),
          options: [],
          ...(!el.ingredients?.length && el.ingredientsGroups?.length && { ingredients: [] }),
          categories: el?.categories,
          changesAllowed: replacementOption,
          comments: comment,
          sku: el.sku,
        };
      }
    });
  if (onlyFilter) {
    return removeDuplicateProducts(productsChecked);
  }
  if (tempProducts?.length !== 0 || lastProduct) {
    await updateCart(removeDuplicateProducts(productsChecked));
  }
}

export function removeDuplicateProducts(items) {
  const seen = new Set();
  return items.filter(item => {
    if (item?.options?.length > 0) {
      if (seen.has(item?.productId + '_' + item?.options[0])) {
        return false;
      }
      seen.add(item?.productId + '_' + item?.options[0]);
    } else if (item?.ingredients?.length > 0) {
      const uniqueIdentifier = JSON.stringify(item.ingredients.slice().sort());
      if (seen.has(item.productId + '_' + uniqueIdentifier)) {
        return false;
      }

      seen.add(item.productId + '_' + uniqueIdentifier);
    } else {
      if (seen.has(item.productId)) {
        return false;
      }
      seen.add(item.productId);
    }

    return true;
  });
}
export async function transformCart(
  tempProducts,
  replacementOption = 'none',
  collectionTypes,
  deliveryIndex = 0,
  bundleOffersSummary,
  noCommentToken,
  bags = false,
  paymentOptions = [],
  selectedProduct,
  currencyFormatter = defaultFormatter,
  unavailableToken
) {
  const mainAddress = User.getDeliveryAddress(deliveryIndex);
  const orderCheckResponse = await checkOrderCart(
    tempProducts,
    getShopId(),
    getDelivery(),
    replacementOption,
    getShopId() !== '' ? mainAddress : {},
    collectionTypes,
    PaymentOptionStore.getValue()?.type,
    bags,
    paymentOptions
  );

  if (!orderCheckResponse?.error && orderCheckResponse?.products?.length > 0) {
    clearCart();
    const products = transformProducts(
      orderCheckResponse,
      bundleOffersSummary,
      false,
      selectedProduct,
      noCommentToken,
      currencyFormatter,
      unavailableToken
    );
    const updatedProducts = products.map(product => {
      const quantity =
        typeof product.productQuantity === 'string'
          ? parseFloat(product.productQuantity.replace(',', '.'))
          : product.productQuantity;

      const stepToUom = typeof product.stepToUom === 'string' ? parseFloat(product.stepToUom) : product.stepToUom;

      const validQuantity = isNaN(quantity) ? 0 : quantity;
      const validStepToUom = isNaN(stepToUom) || stepToUom === 0 ? 1 : stepToUom; // Avoid division by 0

      return {
        ...product,
        productQuantity: validQuantity / validStepToUom,
      };
    });
    const expandedArray = updatedProducts.flatMap(item => {
      const quantity = Math.max(0, Math.floor(item.productQuantity)); // Ensure non-negative integer
      return Array(quantity).fill(item);
    });
    cartPrice.update((orderCheckResponse?.order?.orderItemsPrice / 100).toFixed(2));
    fullCartStore.update({ data: orderCheckResponse, products: products });
    productsForOrder.update(expandedArray);
    return expandedArray;
  }
  if (tempProducts?.length === 0) {
    clearCart();
    fullCartStore.update({ data: {}, products: [] });
    productsForOrder.update([]);
  }
}
