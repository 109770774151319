import { useRouter } from 'next/router';
import { createContext, useEffect, useMemo, useState } from 'react';
// import { useQuery } from '@tanstack/react-query';
// import { getV3Configuration } from '@/api/configuration';
import { useTranslations } from 'use-intl';

export const DistanceContext = createContext({
  unit: process.env.distanceUom,
  unitShorthand: 'χλμ.',
});

export function DistanceProvider({ children }) {
  const { locale } = useRouter();
  const t = useTranslations();
  const [unit, setUnit] = useState(process.env.distanceUom);

  const unitShorthand = useMemo(() => {
    const unitMap = {
      M: 'miles',
      K: 'km',
    };

    return t('common.distance_uom.' + unitMap[unit] + '.shorthand');
  }, [unit, t]);

  // TODO: Add this back in when this is fetched from backend configuration
  // const { data: config } = useQuery({
  //   queryKey: ['config', locale],
  //   queryFn: ({ queryKey }) => getV3Configuration(queryKey[1]),
  //   select: data => {
  //     // TODO: Fill this in to get config for distance unit of measurement
  //     return data;
  //   },
  //   staleTime: 1000 * 60 * 60 * 24,
  // });

  // useEffect(() => {
  //   if (config) {
  //     setUnit(config.distanUom);
  //   }
  // }, [config]);

  return <DistanceContext.Provider value={{ unit, unitShorthand }}>{children}</DistanceContext.Provider>;
}
