import { BehaviorSubject } from 'rxjs';

import jsonStorage from '../utils/json-storage';

const subjectTakeAwayShop = new BehaviorSubject({
  selectedShop: undefined,
  selectedDeliveryShop: undefined,
});

export const TakeAwayShops = {
  update: takeAwayshops => {
    subjectTakeAwayShop.next(takeAwayshops);
  },
  subscribe: setState => subjectTakeAwayShop.subscribe(setState),
  getValue: () => subjectTakeAwayShop.value,
};

export const Shop = {
  subject: undefined,

  lazyInit() {
    if (Shop.subject) return Shop.subject;
    if (typeof window === 'undefined') return {};

    const storage = window.localStorage;
    const shop = jsonStorage.get('Shop', { storage });

    Shop.subject = new BehaviorSubject(shop);

    return Shop.subject;
  },

  update(_shop) {
    const shop = Shop.lazyInit();
    shop.next(_shop);
    const storage = window.localStorage;
    jsonStorage.set('Shop', _shop, { storage });
  },

  subscribe(setState) {
    const shop = Shop.lazyInit();
    return shop.subscribe(setState);
  },

  getValue() {
    const shop = Shop.lazyInit();
    return shop.value;
  },

  setShopByDeliveryId(deliveryId, shops) {
    const shop = Shop.getValue();
    Shop.update({
      ...shop,
      [deliveryId]: shops,
    });
  },

  setSelectedShop(selectedShop) {
    const shop = Shop.getValue();
    Shop.update({
      ...shop,
      selectedShop: selectedShop,
    });
  },

  setSelectedDeliveryShop(selectedShop) {
    const shop = Shop.getValue();
    Shop.update({
      ...shop,
      selectedDeliveryShop: selectedShop,
    });
  },

  setTakeAwayShop(takeAwayShop) {
    const shop = Shop.getValue();
    Shop.update({
      ...shop,
      takeAwayShop,
    });
  },

  getStorageValue: () => {
    const storage = window.localStorage;
    return jsonStorage.get('Shop', { storage });
  },

  reset() {
    const storage = window.localStorage;
    jsonStorage.remove('Shop', { storage });
    this.subject.next(undefined);
  },
};

export default { Shop, TakeAwayShops };
