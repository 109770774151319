import { BehaviorSubject } from 'rxjs';

const subjectListsFetched = new BehaviorSubject(false);

const UserListsFetched = {
  subject: subjectListsFetched,
  update: fetched => {
    subjectListsFetched.next(fetched);
  },
};

const UserListsStore = {
  subject: new BehaviorSubject([]),
  update: lists => {
    UserListsStore.subject.next(lists);
    UserListsFetched.update(true);
  },
  subscribe: setState => UserListsStore.subject.subscribe(setState),
  getValue: () => UserListsStore.subject.value,
  isFetched: () => subjectListsFetched.value,
  clear: () => {
    UserListsFetched.update(false);
  },
};

export default UserListsStore;
