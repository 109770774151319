import { getCategoriesHashLocalized } from '@/utils/categories/categories.js';
import * as Router from 'next/router';

const getProductCategorySlugFromParents = parents => {
  const rootCategoryParent = parents?.[0];
  const subCategoryParent = parents?.[1];

  let slug = '';

  if (!rootCategoryParent) return slug;

  slug += `/${rootCategoryParent.slug}`;

  if (subCategoryParent) {
    slug += `/${subCategoryParent.slug}`;
  }

  return slug;
};

const getProductCategorySlugFromCategory = category => {
  // const rootCategoryParent = parents?.[0];
  // const subCategoryParent = parents?.[1];

  let slug = '';

  if (!category.slug || category.slug.length === 0) return slug;

  slug = `/${category.slug}`;

  // if (subCategoryParent) {
  //   slug += `/${subCategoryParent.slug}`;
  // }

  return slug;
};

export const enhanceProductWithSlug = product => {
  const categories = getCategoriesHashLocalized(Router?.state?.locale);
  if (!product || !product?.categoryIds?.length) return null;
  let selectedCategory = categories?.[product?.categoryIds?.[0]];
  if (!selectedCategory && product?.categoryIds?.length > 1) selectedCategory = categories?.[product?.categoryIds?.[1]];

  if (!selectedCategory)
    return {
      ...product,
      slug: `/products/${product.slug}-${product._id}`,
    };
  if (!selectedCategory.parents)
    return {
      ...product,
      slug: `/products${getProductCategorySlugFromCategory(selectedCategory)}/${product.slug}-${product._id}`,
    };
  return {
    ...product,
    slug: `/products${getProductCategorySlugFromParents(selectedCategory.parents)}/${product.slug}-${product._id}`,
  };
};
