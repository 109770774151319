// import { captureMessage } from '@sentry/node';
import axios from 'axios';
import get from 'lodash/get';
import pick from 'lodash/pick';
import Cookies from 'universal-cookie';
import Router from 'next/router';
import { wave, waveV3 } from '../config';
import { PaymentOptionStore, ReplacementOptionStore } from '@/store/sideSheet';

const cookies = new Cookies();
const auth = cookies.get('token');

const baseURL = `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api/orders`;
const baseURLProductsV31 = `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api/v3.1/products`;

export const ORDER = axios.create({
  baseURL,
  headers: {
    appId: process.env.appId,
    authorization: `Bearer ${auth}`,
  },
  responseType: 'json',
});

export async function getOrderHistory(authorization) {
  try {
    const response = await axios.get(`${baseURL}/get/userlist`, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
    });
    return get(response, 'data.payload.orders');
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getOrderLimitations() {
  try {
    const response = await axios.get(`${baseURL}/get/limitations`, {
      headers: {
        appId: process.env.appId,
      },
    });
    return response?.data?.payload?.orders;
  } catch (error) {
    return {};
  }
}

export async function createOrder(order) {
  const products =
    order.products && order.products.map(p => pick(p, ['_id', 'name', 'price', 'quantity', 'comments', 'options']));
  const body = {
    ...order,
    appVersion: 'sm',
    products,
  };

  try {
    const response = await ORDER.post('/create', body);
    const orderResponse = get(response, 'data.payload.order');
    return { order: orderResponse };
  } catch (error) {
    return { error };
  }
}

export async function getMerchantReference(merchantReference) {
  try {
    const response = await ORDER.get('/get/merchantreference', {
      params: {
        merchantReference,
      },
    });
    return get(response, 'data.payload.order');
  } catch (error) {
    const orderError = error.response ? error.response.data : 'error';
    return Promise.reject(orderError);
  }
}

export async function checkOrder(order = {}) {
  const authorization = cookies.get('token');
  const headers = {
    appId: process.env.appId,
    authorization: `Bearer ${authorization}`,
  };

  // const products = order?.products?.map(p => pick(p, ['_id', 'name', 'finalPrice', 'quantity'])) || [];
  const products =
    order?.products?.map(p => {
      return {
        _id: p._id,
        name: p.name,
        finalPrice: p.finalPrice,
        quantity: p.quantity,
        comments: p.comments,
      };
    }) || [];
  const body = {
    ...order,
    appVersion: 'sm',
    products,
  };
  try {
    const response = await wave.post('/v2/orders/check', body, { headers });
    const orderFetched = response?.data?.payload?.order;
    // if (!orderFetched) {
    //   const errorMessage = 'Unprocessable entity received from /v2/orders/check';
    //   captureMessage(errorMessage, { requestBody: body, response });
    //   throw Error(errorMessage);
    // }

    return orderFetched;
  } catch (error) {
    return { error };
  }
}
export async function checkOrderCart(
  products = {},
  shopId,
  deliveryService,
  changesAllowed,
  address,
  collectionTypes,
  paymentType = PaymentOptionStore.getValue()?.type ?? 'card',
  wantPlasticBags,
  paymentOptions = [],
  timeSlots,
  timeslotRequestId
) {
  const authorization = cookies.get('token');
  const headers = {
    appId: process.env.appId,
  };
  if (authorization) {
    headers.authorization = `Bearer ${authorization}`;
  }

  const body = {
    address,
    shopId,
    deliveryService,
    changesAllowed,
    products,
    collectionTypes,
    paymentType: paymentType,
    paymentOptions,
    wantPlasticBags,
    timeSlots,
    timeslotRequestId,
    // getPotentialDiscounts: true,
  };

  try {
    const response = await waveV3.post('/orders/check', body, {
      headers,
      params: {
        populateDefinition: true,
        getPotentialDiscounts: true,
      },
    });
    const orderFetched = response?.data;

    return orderFetched;
  } catch (error) {
    return { error };
  }
}

export async function submitOrderCart(
  products = {},
  shopId,
  deliveryService,
  address,
  wantPlasticBags,
  timeSlots,
  collectionTypes,
  paymentOptions,
  timeslotRequestId,
  cardId,
  savePaymentCard,
  bottlesReturnInfo,
  invoice,
  userTimezone,
  comments,
  hasPredictiveTimeslot
) {
  const authorization = cookies.get('token');
  const headers = {
    appId: process.env.appId,
    authorization: `Bearer ${authorization}`,
  };
  let locale = '';
  if (Router.locale !== 'el') {
    locale = Router.locale;
  }
  // headers.authorization = `Bearer ${authorization}`;
  const body = {
    address,
    shopId,
    deliveryService,
    products,
    paymentType: PaymentOptionStore.getValue()?.type ?? 'card',
    collectionTypes,
    changesAllowed: ReplacementOptionStore.getValue().option,
    wantPlasticBags,
    timeSlotStart: hasPredictiveTimeslot ? undefined : timeslotRequestId ? undefined : timeSlots[0],
    timeSlotEnd: hasPredictiveTimeslot ? undefined : timeslotRequestId ? undefined : timeSlots[1],
    paymentOptions,
    paymentRedirectionUrl: {
      onSuccess: `${process.env.NEXT_PUBLIC_HOSTNAME}/${locale}/thank-you`,
      onError: `${process.env.NEXT_PUBLIC_HOSTNAME}/${locale}/checkout/error`,
      onCancel: `${process.env.NEXT_PUBLIC_HOSTNAME}/${locale}/checkout/cancel`,
    },
    timeslotRequestId,
    cardId,
    savePaymentCard,
    bottlesReturnInfo,
    invoice: invoice ?? undefined,
    userTimezone: userTimezone,
    comments: comments,
  };

  try {
    const response = await waveV3.post('/orders', body, { headers });
    const orderFetched = response?.data;

    return orderFetched;
  } catch (error) {
    return { error };
  }
}
export async function checkOrderEditCart(
  products = {},
  shopId,
  deliveryService,
  address,
  wantPlasticBags,
  timeSlots,
  collectionTypes,
  paymentOptions,
  // timeslotRequestId,
  paymentType,
  bottlesReturnInfo,
  invoice,
  changesAllowed,
  cardId,
  orderId
) {
  const authorization = cookies.get('token');
  const headers = {
    appId: process.env.appId,
    authorization: `Bearer ${authorization}`,
  };

  // headers.authorization = `Bearer ${authorization}`;
  const body = {
    address,
    shopId,
    deliveryService,
    products,
    paymentType: paymentType,
    collectionTypes,
    changesAllowed: changesAllowed,
    wantPlasticBags,
    timeSlotStart: timeSlots[1],
    timeSlotEnd: timeSlots[0],
    paymentOptions,
    cardId,
    paymentRedirectionUrl: {
      onSuccess: `${process.env.NEXT_PUBLIC_HOSTNAME}/thank-you`,
      onError: `${process.env.NEXT_PUBLIC_HOSTNAME}/checkout/error`,
      onCancel: `${process.env.NEXT_PUBLIC_HOSTNAME}/checkout/cancel`,
    },
    // timeslotRequestId,
    bottlesReturnInfo,
    invoice: invoice ?? undefined,
  };

  try {
    const response = await waveV3.put(`orders/${orderId}/check`, body, {
      headers,
      params: {
        populateDefinition: true,
        getPotentialDiscounts: false,
      },
    });
    const orderFetched = response?.data;

    return orderFetched;
  } catch (error) {
    return { error };
  }
}
export async function submitOrderEditCart(
  products = {},
  shopId,
  deliveryService,
  address,
  wantPlasticBags,
  timeSlots,
  collectionTypes,
  paymentOptions,
  // timeslotRequestId,
  paymentType,
  bottlesReturnInfo,
  invoice,
  userTimezone,
  comments,
  cardId,
  savePaymentCard,
  changesAllowed,
  orderId,
  isAddOnly
) {
  const authorization = cookies.get('token');
  const headers = {
    appId: process.env.appId,
    authorization: `Bearer ${authorization}`,
  };

  let locale = '';
  if (Router.locale !== 'el') {
    locale = Router.locale;
  }

  const body = {
    address,
    shopId,
    deliveryService,
    products,
    paymentType: paymentType,
    collectionTypes,
    changesAllowed: changesAllowed,
    wantPlasticBags,
    timeSlotStart: timeSlots[1],
    timeSlotEnd: timeSlots[0],
    paymentOptions,
    cardId,
    savePaymentCard,
    paymentRedirectionUrl: {
      onSuccess: `${process.env.NEXT_PUBLIC_HOSTNAME}/${locale}/thank-you`,
      onError: `${process.env.NEXT_PUBLIC_HOSTNAME}/${locale}/checkout/error`,
      onCancel: `${process.env.NEXT_PUBLIC_HOSTNAME}/${locale}/checkout/cancel`,
    },
    // timeslotRequestId,
    bottlesReturnInfo,
    comments: comments ?? '',
    userTimezone: userTimezone,
    invoice: invoice ?? undefined,
  };

  try {
    let response;
    if (!isAddOnly) {
      response = await waveV3.put(`orders/${orderId}`, body, {
        headers,
      });
    } else {
      response = await waveV3.put(`orders/${orderId}/add-products`, body, {
        headers,
      });
    }

    const orderFetched = response?.data;

    return orderFetched;
  } catch (error) {
    return { error };
  }
}
export async function checkOrderEditCartAdd(
  products = {},
  shopId,
  deliveryService,
  address,
  wantPlasticBags,
  timeSlots,
  collectionTypes,
  paymentOptions,
  paymentType,
  bottlesReturnInfo,
  invoice,
  cardId,
  changesAllowed,
  orderId
) {
  const authorization = cookies.get('token');
  const headers = {
    appId: process.env.appId,
    authorization: `Bearer ${authorization}`,
  };

  const body = {
    address,
    shopId,
    deliveryService,
    products,
    paymentType: paymentType,
    collectionTypes,
    changesAllowed: changesAllowed,
    wantPlasticBags,
    timeSlotStart: timeSlots[1],
    timeSlotEnd: timeSlots[0],
    paymentOptions,
    cardId,
    paymentRedirectionUrl: {
      onSuccess: `${process.env.NEXT_PUBLIC_HOSTNAME}/thank-you`,
      onError: `${process.env.NEXT_PUBLIC_HOSTNAME}/checkout/error`,
      onCancel: `${process.env.NEXT_PUBLIC_HOSTNAME}/checkout/cancel`,
    },
    // timeslotRequestId,
    bottlesReturnInfo,
    invoice: invoice ?? undefined,
  };

  try {
    const response = await waveV3.put(`orders/${orderId}/add-products/check`, body, {
      headers,
    });
    const orderFetched = response?.data;

    return orderFetched;
  } catch (error) {
    return { error };
  }
}
export async function cancelOrderEditCart(orderId) {
  const authorization = cookies.get('token');
  const headers = {
    appId: process.env.appId,
    authorization: `Bearer ${authorization}`,
  };

  try {
    const response = await waveV3.post(`orders/${orderId}/cancel`, {
      headers,
    });
    const orderFetched = response?.data;

    return orderFetched;
  } catch (error) {
    return { error };
  }
}
export async function startOrderEdit(orderId, mode) {
  const authorization = cookies.get('token');
  const headers = {
    appId: process.env.appId,
    authorization: `Bearer ${authorization}`,
  };

  try {
    const response = await waveV3.put(`orders/${orderId}/edit-session?editMode=${mode}`, {
      headers,
    });

    return response;
  } catch (error) {
    return { error };
  }
}
export async function cancelOrderEdit(orderId, mode) {
  const authorization = cookies.get('token');
  const headers = {
    appId: process.env.appId,
    authorization: `Bearer ${authorization}`,
  };

  try {
    const response = await waveV3.delete(`orders/${orderId}/edit-session?editMode=${mode}`, {
      headers,
    });

    return response;
  } catch (error) {
    return { error };
  }
}
export async function getProductFromGroups({ groupId, pageParam }) {
  const authorization = cookies.get('token');

  try {
    const response = await wave.get(`${baseURLProductsV31}`, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${authorization}`,
      },
      params: {
        available: true,
        pageNumber: pageParam,
        pageSize: 25,
        catalogGroups: groupId,
      },
    });

    return response?.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default { getOrderHistory, createOrder, getMerchantReference, ORDER };
