import axios from 'axios';
import User from '@/store/user.js';
import { LoginProviderStore } from '@/store/loginProvider';
import UserListsStore from '@/store/userLists';
import { PostalCode } from '@/store/isAddressRegistered';
import { DeliveryStore as DeliveryAddressIndexStore } from '@/store/sideSheet';
import Cart from '@/store/cart';
import Router from 'next/router';
import Cookies from 'universal-cookie';
import { Shop } from '@/store/shop';

const API_URL = `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api`;
const APP_ID = process.env.NEXT_PUBLIC_APPID;

const wave = axios.create({
  baseURL: API_URL,
  headers: {
    appId: APP_ID,
    // authorization: `Bearer ${}`,
  },
});
const API_URL2 = `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api/v3`;

//Add a request interceptor
wave.interceptors.request.use(
  config => {
    //pass the auh token as a header
    const token = User?.getValue?.()?.token || User?.getValue?.()?.authToken || undefined;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    //pass the locale as a header if it exists
    if (typeof window !== 'undefined') {
      if (!config.headers['Accept-Language']) {
        config.headers['Accept-Language'] = Router?.router?.locale; // Example: setting Accept-Language header
      }
    }

    return config;
  },
  error => {
    console.error('Error in request setup:', error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
wave.interceptors.response.use(
  function (response) {
    return response;
  },
  error => {
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        const cookies = new Cookies();
        console.error(`Unauthorized [${status}]:`, error.response.data);

        // This is not needed, but just in case of race conditions
        cookies.remove('token');
        User.reset();
        LoginProviderStore.clear();
        UserListsStore.clear();
        PostalCode.delete();
        DeliveryAddressIndexStore.update(undefined);
        Cart.setIsVisible(false);
        Shop.reset();

        Router.push('/?tokenExpired=true');
      } else if (status >= 400 && status < 500) {
        console.error(`Client Error [${status}]:`, error.response.data);
      } else if (status >= 500 && status < 600) {
        console.error(`Server Error [${status}]:`, error.response.data);
      }
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }

    return Promise.reject(error);
  }
);

const waveV3 = axios.create({
  baseURL: API_URL2,
  headers: {
    appId: process.env.appId,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

waveV3.interceptors.request.use(
  config => {
    //pass the auh token as a header
    const token = User?.getValue?.()?.token || User?.getValue?.()?.authToken || undefined;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    //pass the locale as a header if it exists
    if (typeof window !== 'undefined') {
      if (!config.headers['Accept-Language']) {
        config.headers['Accept-Language'] = Router?.router?.locale; // Example: setting Accept-Language header
      }
    }

    return config;
  },
  error => {
    console.error('Error in request setup:', error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
waveV3.interceptors.response.use(
  function (response) {
    return response;
  },
  error => {
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        const cookies = new Cookies();
        console.error(`Unauthorized [${status}]:`, error.response.data);

        // This is not needed, but just in case of race conditions
        cookies.remove('token');
        User.reset();
        LoginProviderStore.clear();
        UserListsStore.clear();
        PostalCode.delete();
        DeliveryAddressIndexStore.update(undefined);
        Cart.setIsVisible(false);
        Shop.reset();

        Router.push('/?tokenExpired=true');
      } else if (status >= 400 && status < 500) {
        console.error(`Client Error [${status}]:`, error.response.data);
      } else if (status >= 500 && status < 600) {
        console.error(`Server Error [${status}]:`, error.response.data);
      }
    } else if (error.request) {
      console.error('No response received:', error.request);
    } else {
      console.error('Error setting up request:', error.message);
    }

    return Promise.reject(error);
  }
);

export { wave, waveV3 };
