import { wave } from '@/api/config/index.js';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const token = cookies.get('token');

const baseURL = `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/api/v3/shared-cart`;

export async function fetchCart() {
  try {
    const response = await wave.get(`${baseURL}`, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    return {};
  }
}
export async function updateCart(tempProducts) {
  const body = {
    products: tempProducts,
  };
  try {
    const response = await wave.post(`${baseURL}`, body, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    console.log(error);
    return {};
  }
}
export async function deleteCart() {
  try {
    const response = await wave.delete(`${baseURL}`, {
      headers: {
        appId: process.env.appId,
        authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    console.log(error);
    return {};
  }
}
