import get from 'lodash/get';

import { parseError } from '../utils/api';

import { wave } from './config';

const UNDEFINED_ERROR_MESSAGE = 'Κάτι πήγε στραβά. Παρακαλώ προσπαθήστε ξανά.';

export async function getTimeslots(postalCode) {
  try {
    const response = await wave.get('/v2/timeslots/available', {
      params: {
        postalCode,
        service: 'express',
      },
    });

    const timeslots = get(response, 'data.payload.timeslots');
    const extraInfo = get(response, 'data.extraInfo');
    return {
      timeslots,
      extraInfo,
    };
  } catch (error) {
    return error?.data?.error || error?.error || UNDEFINED_ERROR_MESSAGE;
  }
}

export async function getTimeslotsForTakeAwayShop(shopId) {
  try {
    const response = await wave.get(`/v2/timeslots/available`, {
      params: {
        shopId,
        service: 'takeAway',
      },
    });
    const timeslots = get(response, 'data.payload.timeslots');
    const extraInfo = get(response, 'data.payload.extraInfo');

    return {
      timeslots,
      extraInfo,
    };
  } catch (err) {
    const error = parseError(err);
    return { error };
  }
}

export async function getShopsV3(service, postalCode = null) {
  try {
    const response = await wave.get('/v3/shops/for-delivery', {
      headers: {
        appId: process.env.appId,
      },
      params: {
        postalCode: postalCode,
        deliveryService: service,
      },
    });
    const shops = response?.data || [];
    return { shops };
  } catch (err) {
    const error = parseError(err);
    return { shops: [], error };
  }
}

export async function getTimeslotsForShopV3(shopId, service, postalCode) {
  try {
    const response = await wave.get(`/v3.1/shops/${shopId}/timeslots`, {
      params: {
        deliveryService: service,
        postalCode: postalCode ? postalCode.toString() : undefined,
      },
    });
    const timeslots = get(response, 'data');
    return {
      timeslots,
    };
  } catch (err) {
    const error = parseError(err);
    return { error };
  }
}

export async function getShopsForDeliveryV3(deliveryService = 'takeAway', postalCode) {
  const response = await wave.get('/v3/shops/for-delivery', {
    params: {
      deliveryService,
      postalCode,
    },
  });

  return response?.data;
}

export async function getDistanceFromShop(shopId, params) {
  return wave.get(`/v3/shops/${shopId}/distance`, { params });
}
